import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useRouteMatch } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { IssuePriorityIcon2 } from 'components';
import { Grid } from '@material-ui/core';
import {
  IssueLink,
  Issue,
  KanbanItemTitle,
  Bottom,
  // Assignees,
  // AssigneeAvatar,
  Left,
  Right,
} from './Styles';
import { UserProptypes } from 'shared/propTypes/userType';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import CaseStatsChips from './caseStatsChips';

const propTypes = {
  projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  index: PropTypes.number.isRequired,
  dummyCount: PropTypes.number,
};

const ProjectBoardListIssue = ({ projectUsers, issue, index, dummyCount }) => {
  const match = useRouteMatch();
  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided, snapshot) => (
        <IssueLink
          to={{
            pathname: `${match.url}/issues/${issue.id}`,
            state: { trigger: 'byClick' },
          }}
          ref={provided.innerRef}
          data-testid="list-issue"
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Issue
            isBeingDragged={snapshot.isDragging && !snapshot.isDropAnimating}>
            <Left>
              <KanbanItemTitle>
                <Grid container>
                  <Grid columns={{ xs: 12 }} container alignItems="flex-start">
                    <p>{issue.title}</p>
                  </Grid>
                  <Grid xs={12} item container justifyContent="flex-start">
                    <p>{issue.srcOrgName}</p>
                  </Grid>
                </Grid>
              </KanbanItemTitle>
              <Bottom>
                <div>
                  <IssuePriorityIcon2 priority={issue.priority} />
                </div>
                {issue.progress !== 'DONE' && <CaseStatsChips issue={issue} />}
              </Bottom>
            </Left>
          </Issue>
        </IssueLink>
      )}
    </Draggable>
  );
};

ProjectBoardListIssue.propTypes = propTypes;

export default ProjectBoardListIssue;
