import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';

import Card from '@mui/material/Card';

import { CustomDatePicker as DatePicker } from 'pages/parts/DatePicker';
import SummaryTable from './SummaryTable';
import DetailedTable from './DetailedTable';
import DetailedTableKenshin from './DetailedTableKenshin';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { PageLoader, PageError } from 'components';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { TABLE_TYPE } from '../shared/constants';
import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import { getFirstDateOfTheSameMonth } from 'shared/utils/dateTime';

const DashboardBillingForSentTasks = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const [loaded, setLoaded] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDstOrgId, setSelectedDstOrgId] = useState(-1);
  // const [tableType, setTableType] = useState(TABLE_TYPE.SUMMARY);
  // const [issuesData, setIssuesData] = useState([]);
  // const [pricesDict, setPricesDict] = useState({});
  // const [dstOrgNamesDict, setDstOrgNamesDict] = useState({});

  // const srcOrgId = localData.get(USER_DATA.CURRENT_ORGANIZATION_ID);

  const [loaded, setLoaded] = useState(false);
  const [loadedKenshin, setLoadedKenshin] = useState(false);
  const [ifError, setIfError] = useState(false);
  const [srcOrg, setSrcOrg] = useState({});

  const currentDate = new Date();
  const firstDate = getFirstDateOfTheSameMonth(currentDate);
  const [selectedStartDate, setSelectedStartDate] = useState(firstDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const [tableType, setTableType] = useState(TABLE_TYPE.SUMMARY);
  const [selectedDstOrgId, setSelectedDstOrgId] = useState(-1);
  const [selectedDstOrgName, setSelectedDstOrgName] = useState('');
  const [billData, setBillData] = useState([]);
  const [billDataKenshin, setBillDataKenshin] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const preferredLanguage = localData.get('userPreferredLanguage');
  const srcOrgId = localData.get(USER_DATA.CURRENT_ORGANIZATION_ID);

  useEffect(() => {
    setLoaded(false);

    const apiVariables = {
      params: {
        organizationId: parseInt(srcOrgId),
        // year: selectedDate.getFullYear(),
        // month: selectedDate.getMonth(),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    const kenshinApiVariables = {
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    // api.get(DOLPHIN_API.BILLING_SENT_TASKS, apiVariables).then(
    //   async (data) => {
    //     // get raw data of issues, prices and orgs
    //     const { issues, prices, orgs } = data;

    //     const _issues = issues;
    //     setIssuesData(_issues);

    //     const _pricesDict = createPricesDict(prices);
    //     setPricesDict(_pricesDict);

    //     const _dstOrgNamesDict = createDstOrgNamesDict(issues, orgs);
    //     setDstOrgNamesDict(_dstOrgNamesDict);

    //     setLoaded(true);
    //   },
    //   (error) => {
    //     console.log(`get /api/billing_sent_tasks error: ${error}`);
    //   }
    // );

    api
      .get(DOLPHIN_API.BILLS_SENT_TASKS, apiVariables)
      .then(async (data) => {
        const { bills } = data;
        const newBills = bills.sort((bill1, bill2) =>
          bill1.id < bill2.id ? 1 : -1
        );
        setBillData(newBills);
        setLoaded(true);
      })
      .catch((error) => {
        setIfError(true);
      });

    api
      .get(KENSHIN_API.BILLS_SENT_ISSSUES, kenshinApiVariables)
      .then(async (data) => {
        const { bills } = data;
        const newBills = bills.sort((bill1, bill2) =>
          bill1.id < bill2.id ? 1 : -1
        );
        setBillDataKenshin(newBills);
        setLoadedKenshin(true);
      })
      .catch((error) => {
        setIfError(true);
      });
  }, [selectedStartDate, selectedEndDate, srcOrgId]);

  if (!(loaded && loadedKenshin)) {
    return <PageLoader />;
  }
  if (ifError) {
    return <PageError />;
  }

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item>
          <MDBox ml={4}>
            <MDTypography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginRight: '18px',
                textAlign: 'right',
                alignSelf: 'stretch',
              }}>
              {`${intl.get('billing_text_select_billing_period')}: `}
            </MDTypography>
          </MDBox>
        </Grid>
        {/* <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MaterialUIPickers
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </ThemeProvider>
        </Grid> */}
        <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedStartDate}
              setSelectedDate={setSelectedStartDate}
              labelText={intl.get('billing_text_select_billing_period_from')}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedEndDate}
              setSelectedDate={setSelectedEndDate}
              labelText={intl.get('billing_text_select_billing_period_to')}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            {tableType === TABLE_TYPE.SUMMARY && (
              <SummaryTable
                billData={billData}
                billDataKenshin={billDataKenshin}
                setSelectedDstOrgId={setSelectedDstOrgId}
                setSelectedDstOrgName={setSelectedDstOrgName}
                setTableType={setTableType}
              />
            )}

            {tableType === TABLE_TYPE.DETAILED && (
              <DetailedTable
                billData={billData}
                setBillData={setBillData}
                organizationName={selectedDstOrgName}
                selectedDstOrgId={selectedDstOrgId}
                setTableType={setTableType}
              />
            )}

            {tableType === TABLE_TYPE.DETAILED_KENSHIN && (
              <DetailedTableKenshin
                billData={billDataKenshin}
                setBillData={setBillDataKenshin}
                organizationName={selectedDstOrgName}
                selectedDstOrgId={selectedDstOrgId}
                setTableType={setTableType}
              />
            )}
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardBillingForSentTasks);
