import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon } from 'components';
import { SectionTitle } from '../Styles';
import { User, Username, Quota } from './Styles';

import { UserContextMenu } from 'components/Menu';

import { IssueState, IssueStatus } from 'shared/constants/issues';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { LANGUAGE, UserType } from 'shared/constants/users';
import { UserProptypes } from 'shared/propTypes/userType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  // user_quotas: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     used: PropTypes.number,
  //     quota: PropTypes.number,
  //     ...UserProptypes,
  //   })
  // ).isRequired,
  doctors: PropTypes.arrayOf(Object),
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const ProjectBoardIssueDetailsAssignees = ({
  issue,
  // user_quotas,
  doctors,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  // TODO: doctors could be replaced by projectUsers
  const getUserById = (userId) =>
    // user_quotas.find((user) => user.id === userId); // todo: need further work
    doctors.find((user) => user.id === userId);

  const handleAssignDoctor = (issue, userIds) => {
    const users = userIds.map(getUserById);
    const updatedFields = {
      userIds,
      users: users,
      status: issue.status,
      state: issue.state,
    };
    // TODO: [Privilege-Check-Again] change to filter doctors by privilege: has pic_as_doctor
    // const doctorUsers = issue.users.filter(
    //   (user) => user.userType === UserType.DOCTOR
    // );
    const doctorUsers = issue.users;
    if (
      doctorUsers.length === 0 &&
      issue.state === IssueState.NORMAL &&
      issue.status === IssueStatus.CHECKREADY
    ) {
      updatedFields.status = IssueStatus.DOCTORASSIGNED;
    }
    if (
      users.length === 0 &&
      [
        IssueStatus.DOCTORASSIGNED,
        IssueStatus.CHECKREADY,
        IssueStatus.REPORTREADY,
        IssueStatus.SENDBACK,
        IssueStatus.DONE,
      ].includes(issue.status)
    ) {
      updatedFields.state = IssueState.WARNING;
    }
    setConfirmModalInfo({
      destination: updatedFields.status,
      issueState: updatedFields.state,
      AssignType: doctorUsers.length < users.length ? 'assign' : 'unassign',
    });
    setConfirmModalUpdateData(updatedFields);
    setConfirmModalOpen(true);
  };

  // const userOptions = user_quotas.map((user) => ({
  const userOptions = doctors.map((user) => ({
    value: user.id,
    label: user.username,
    // used: user.used,
    // quota: user.quota,
  }));

  return (
    <Fragment>
      <SectionTitle>
        {intl.get('issue_details_text_doctor_in_charge')}
      </SectionTitle>
      <Select
        isMulti
        variant="empty"
        dropdownWidth={343}
        placeholder={intl.get('issue_details_unassigned')}
        name="assignees"
        value={issue.userIds}
        options={userOptions}
        onChange={(userIds) => {
          handleAssignDoctor(issue, userIds);
        }}
        renderValue={({ value: userId, removeOptionValue }) =>
          renderUser(getUserById(userId), true, removeOptionValue)
        }
        renderOption={({ value: userId }) =>
          // renderUserWithQuota(getUserById(userId), false)
          renderUserWithoutQuota(getUserById(userId), false)
        }
      />
    </Fragment>
  );
};

const renderUser = (user, isSelectValue, removeOptionValue) => {
  const preferredLanguage = localData.get('userPreferredLanguage');

  return (
    <UserContextMenu
      username={user.username}
      userShowName={getUserFullName(user.firstName, user.lastName)}>
      <User
        key={user.id}
        isSelectValue={isSelectValue}
        withBottomMargin={!!removeOptionValue}
        onClick={() => removeOptionValue && removeOptionValue()}>
        <Avatar name={user.firstName} size={24} />
        <Username>
          {preferredLanguage === LANGUAGE.ENGLISH
            ? user.firstName + ' ' + user.lastName
            : user.lastName + ' ' + user.firstName}
        </Username>
        {removeOptionValue && <Icon type="close" top={1} />}
      </User>
    </UserContextMenu>
  );
};

const renderUserWithQuota = (user, isSelectValue) => {
  const isFull = user.used === user.quota;
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={false}
      onClick={() => {}}>
      <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} />
      <Username style={isFull ? { color: 'lightgrey' } : {}}>
        {' '}
        {getUserFullName(user.firstName, user.lastName)}{' '}
      </Username>
      <Quota style={isFull ? { color: 'lightgrey' } : {}}>
        {' '}
        {`${user.used}/${user.quota}`}{' '}
      </Quota>
    </User>
  );
};

const renderUserWithoutQuota = (user, isSelectValue) => {
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={false}
      onClick={() => {}}>
      <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} />
      <Username style={{}}>
        {' '}
        {getUserFullName(user.firstName, user.lastName)}{' '}
      </Username>
      {/* <Quota style={isFull ? { color: 'lightgrey' } : {}}>
        {' '}
        {`${user.used}/${user.quota}`}{' '}
      </Quota> */}
    </User>
  );
};

ProjectBoardIssueDetailsAssignees.propTypes = propTypes;

export default ProjectBoardIssueDetailsAssignees;
