export const diagnosticResultTranslation = {
  TO_BE_DIAGNOSED: '未診断',
  DIAGNOSED_HEALTHY: '異常なし',
  DIAGNOSED_SICK: '異常あり',
  DIAGNOSED_UNKNOWN: '不明',
};

export const KenshinCaseDiagnosis = {
  TO_BE_DIAGNOSED: 'TO_BE_DIAGNOSED',
  DIAGNOSED_HEALTHY: 'DIAGNOSED_HEALTHY',
  DIAGNOSED_SICK: 'DIAGNOSED_SICK',
  DIAGNOSED_UNKNOWN: 'DIAGNOSED_UNKNOWN',
};
