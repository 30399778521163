import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Tooltip, IconButton } from '@material-ui/core';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { updateIssue } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

// TODO Change object to shape
const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardBillingForSentCasesDetailedTableCustomToolbarSelectQuestion = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  const setQuestionState = (state) => {
    const dataIndice = selectedRows.data.map((row) => {
      return row.dataIndex;
    });

    const newData = data;
    dataIndice.forEach((dataIndex) => {
      newData[dataIndex][8].question = state;
      const issueId = newData[dataIndex][7];
      updateIssue(issueId, { question: state });
    });
    setData([...newData]);
  };

  return (
    <Fragment>
      <Tooltip
        className={classes.tooltip}
        title={intl.get(
          'billing_for_sent_cases_toolbar_select_tooltip_question'
        )}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setQuestionState(true)}>
          <HelpIcon className={classes.starIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip
        className={classes.tooltip}
        title={intl.get(
          'billing_for_sent_cases_toolbar_select_tooltip_unquestion'
        )}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setQuestionState(false)}>
          <HelpOutlineIcon className={classes.starBorderIcon} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

DashboardBillingForSentCasesDetailedTableCustomToolbarSelectQuestion.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForSentCasesDetailedTableCustomToolbarSelectQuestion
);
