import React, { forwardRef, Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  getIssueStatus, // get display name;
  IssueSelectableStatusReceiver,
} from 'shared/constants/kenshin/issues';
import { Select, Icon } from 'components';
import { Status } from './styles';
// import { IssuePropTypes } from 'shared/propTypes/issueType';

const findCommonNextStatus = (arrayOfStatus) => {
  if (arrayOfStatus.length === 0) return [];

  // Initialize the set of common statuses to the selectable statuses of the first status in the array
  let commonStatuses = new Set(IssueSelectableStatusReceiver[arrayOfStatus[0]]);

  // Intersect the sets of next possible statuses for each status in the array
  arrayOfStatus.forEach((status) => {
    const nextPossibleStatuses = new Set(IssueSelectableStatusReceiver[status]);
    commonStatuses = new Set(
      [...commonStatuses].filter((x) => nextPossibleStatuses.has(x))
    );
  });

  // Return the first common status if any, else return an empty array
  return commonStatuses.size > 0 ? [...commonStatuses] : [];
};

const propTypes = {
  issues: PropTypes.array.isRequired,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
  // setSelectDoctorModalOpen: PropTypes.func,
};
const BatchEditModalIssueStatus = forwardRef(
  ({
    issues,
    setConfirmModalInfo,
    setConfirmModalOpen,
    setConfirmModalUpdateData,
  }) => {
    // make sure that when issues changes in parent, this component re-renders;
    const [firstIssue, setFirstIssue] = useState(issues[0]);
    useEffect(() => {
      setFirstIssue(issues[0]);
    }, [issues]);

    const handleStatusChange = (status) => {
      setConfirmModalUpdateData({ progress: status });
      setConfirmModalInfo({ destination: status });
      setConfirmModalOpen(true);
    };

    const arrayOfStatus = issues.map((item) => item.progress);
    const common = findCommonNextStatus(arrayOfStatus);
    const options = common.map((status) => ({
      value: status,
      label: getIssueStatus(status),
    }));

    return (
      <Fragment>
        <Select
          variant="empty"
          dropdownWidth={180}
          withClearValue={false}
          name="status"
          value={firstIssue.progress}
          // options={IssueSelectableStatusReceiver[firstIssue.progress].map(
          //   (status) => ({
          //     value: status,
          //     label: getIssueStatus(status),
          //   })
          // )}
          options={options}
          onChange={(status) => handleStatusChange(status)}
          renderValue={({ value: status }) => (
            <Status isValue color={status}>
              <div>{getIssueStatus(status)}</div>
              <Icon type="chevron-down" size={18} />
            </Status>
          )}
          renderOption={({ value: status }) => (
            <Status color={status}>{getIssueStatus(status)}</Status>
          )}
        />
      </Fragment>
    );
  }
);

BatchEditModalIssueStatus.propTypes = propTypes;
BatchEditModalIssueStatus.displayName =
  'ReceivedHistoryKenshinBatchEditProgress';

export default BatchEditModalIssueStatus;
