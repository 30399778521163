import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
  TextField,
} from '@material-ui/core';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { OrganizationProptypes } from 'shared/propTypes/organizationType';

import MUIDataTable from 'mui-datatables';
import { useMaterialUIController } from 'context';
import { ThemeProvider } from '@mui/material/styles';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import { Modal } from 'components';
import CustomSearchRender from 'components/CustomSearchRender/admin.js';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import api from 'shared/utils/api';
import useApi from 'shared/hooks/api';
import { DOLPHIN_API } from 'shared/constants/apis';
import { PageLoader, PageError } from 'components';

// TODO: BAI 后天连通后这两个文件可以去掉
import template1 from './test.json';
import template2 from './test2.json';

import NewTemplate from './NewTemplate';

const propTypes = {
  organizationInfo: PropTypes.shape(OrganizationProptypes),
  fetchOrganizationInfo: PropTypes.func,
};

const OPERATION_TYPE = {
  ADD: 'add',
  REMOVE: 'remove',
};

const USER_TYPE_TABS = {
  STAFF: 0,
  DOCTOR: 1,
};

const DashboardOrganizationSettingsTemplateManagement = ({
  organizationInfo,
  fetchOrganizationInfo,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [orgDetailsModalOpen, setOrgDetailsModalOpen] = useState(false);
  const [orgIdSelected, setOrgIdSelected] = useState(-1);
  const [orgDataSelected, setOrgDataSelected] = useState({});
  const [orgNewModalOpen, setOrgNewModalOpen] = useState(false);
  const [orgDeleteModalOpen, setOrgDeleteModalOpen] = useState(false);
  const [templatePreviewModalOpen, setTemplatePreviewModalOpen] =
    useState(false);
  const [templateNewModalOpen, setTemplateNewModalOpen] = useState(false);
  const [tagJson, setTagJson] = useState({});

  const [{ data, error }] = useApi.get(DOLPHIN_API.TEMPLATE_MANAGEMENT, {
    params: { organizationId: organizationInfo.id },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  // const getChangableOrgData = (org) => {
  //   return {
  //     name: org.name,
  //     organizationCode: org.organizationCode,
  //     paymentMethod: org.paymentMethod,
  //     contact: org.contact,
  //   };
  // };

  const handleOpenPreview = (template) => {
    // setOrgIdSelected(org.id);
    // setOrgDataSelected((prevState) => ({
    //   ...getChangableOrgData(org),
    // }));
    setTagJson(template);
    setTemplatePreviewModalOpen(true);
  };

  const handleOpenNewTemplate = () => {
    setTemplateNewModalOpen(true);
  };

  // const handleUpdateOrg = async (orgId, updatedOrgJSON) => {
  //   const updatedOrgData = JSON.parse(updatedOrgJSON);
  //   await api.put(DOLPHIN_API.ADMIN_ORGS, {
  //     data: {
  //       ids: [orgId],
  //       updatedData: [updatedOrgData],
  //     },
  //   });
  //   await fetchOrgs();
  // };

  // const handleOpenDeleteOrg = (org) => {
  //   setOrgIdSelected(org.id);
  //   setOrgDataSelected((prevState) => ({
  //     ...getChangableOrgData(org),
  //   }));
  //   setOrgDeleteModalOpen(true);
  // };

  // const handleDeleteOrg = async (orgId) => {
  //   await api.delete(DOLPHIN_API.ADMIN_ORGS, {
  //     data: {
  //       ids: [orgId],
  //     },
  //   });

  //   await fetchOrgs();
  // };

  const handleOpenDeleteTemplate = async (templateId) => {
    // TODO: BAI 实现Template的删除
    await api
      .delete(DOLPHIN_API.TEMPLATE_MANAGEMENT, {
        params: {
          organizationId: organizationInfo.id,
        },
        data: {
          templateId: templateId,
        },
      })
      .then((respond) => {
        // console.log(respond);
      });
  };

  // data.sort((org1, org2) => {
  //   if (org1.id > org2.id) return 1;
  //   if (org1.id < org2.id) return -1;
  //   return 0;
  // });

  // const tableData = data.map((org) => {
  //   return [org.id, org.name, org.organizationCode, org, org];
  // });
  const renderRoot = (data) => {
    if (!data || !data.children || data.children.length === 0) {
      // console.log(data);
    } else {
      return (
        <MDBox py={3} px={3} mt={3} mx={3}>
          {renderNestedTags(data.children, data.children_type, data.note, 0, [
            0,
          ])}
        </MDBox>
      );
    }
  };

  const renderNestedTags = (tags, type, note, level, ancestors) => {
    if (tags && tags.length > 0) {
      if (level < 2) {
        switch (type) {
          case 'text':
            return tags.map((tag) => {
              return (
                <Grid item key={tag.id}>
                  {tag.type === 'text' && (
                    <MDTypography varient="h0">{tag.label}</MDTypography>
                  )}
                  {tag.type === 'input' && (
                    <MDTypography varient="h1">
                      {tag.label}
                      <TextField
                        id={tag.id}
                        variant="standard"
                        value={tag.value}
                      />
                    </MDTypography>
                  )}
                  {tag.children &&
                    renderNestedTags(
                      tag.children,
                      tag.children_type,
                      tag.note,
                      level + 1,
                      [...ancestors, tags.indexOf(tag)]
                    )}
                </Grid>
              );
            });
          case 'input':
            return tags.map((tag) => {
              return (
                <Grid key={tag.id}>
                  <MDTypography>
                    <TextField
                      id={tag.id}
                      variant="standard"
                      value={tag.value}
                    />
                  </MDTypography>
                  {tag.children &&
                    renderNestedTags(
                      tag.children,
                      tag.children_type,
                      tag.note,
                      level + 1,
                      [...ancestors, tags.indexOf(tag)]
                    )}
                </Grid>
              );
            });
          case 'single':
            return (
              <FormControl component="fieldset">
                <FormLabel id="test"></FormLabel>
                <RadioGroup
                  aria-labelledby="test"
                  // defaultValue="female"
                  name="radio-buttons-group">
                  {tags.map((tag) => {
                    return (
                      <Fragment key={tag.id}>
                        <FormControlLabel
                          value={tag.label}
                          control={<Radio />}
                          label={tag.label}
                        />
                        {tag.type === 'input' && (
                          <TextField
                            id={tag.id}
                            variant="standard"
                            value={tag.value}
                          />
                        )}
                        {tag.children &&
                          renderNestedTags(
                            tag.children,
                            tag.children_type,
                            tag.note,
                            level + 1,
                            [...ancestors, tags.indexOf(tag)]
                          )}
                      </Fragment>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            );
          case 'multi':
            return (
              <FormControl component="fieldset">
                <FormGroup>
                  {tags.map((tag) => {
                    return (
                      <Grid item key={tag.id}>
                        <FormControlLabel
                          style={{ color: darkMode ? 'white' : 'black' }}
                          control={
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fill: '#66ccff' }}
                                />
                              }
                              // name="PhyExam3"
                            />
                          }
                          label={tag.label}
                        />
                        {tag.type === 'input' && (
                          <TextField
                            id={tag.id}
                            variant="standard"
                            value={tag.value}
                          />
                        )}
                        {tag.children &&
                          renderNestedTags(
                            tag.children,
                            tag.children_type,
                            tag.note,
                            level + 1,
                            [...ancestors, tags.indexOf(tag)]
                          )}
                      </Grid>
                    );
                  })}
                </FormGroup>
                {note && <MDTypography variant="body2">{note}</MDTypography>}
              </FormControl>
            );
          default:
            console.log('invalid type!'); // Keep this
        }
      } else {
        switch (type) {
          case 'text':
            return tags.map((tag) => {
              return (
                <Grid item key={tag.id}>
                  <MDTypography> ( </MDTypography>
                  <MDTypography>{tag.label}</MDTypography>
                  {tag.children &&
                    renderNestedTags(
                      tag.children,
                      tag.children_type,
                      tag.note,
                      level + 1,
                      [...ancestors, tags.indexOf(tag)]
                    )}
                  <MDTypography> ) </MDTypography>
                </Grid>
              );
            });
          case 'input':
            return tags.map((tag) => {
              return (
                <Grid key={tag.id}>
                  <MDTypography> ( </MDTypography>
                  <MDTypography>
                    <TextField
                      id={tag.id}
                      variant="standard"
                      value={tag.value}
                    />
                  </MDTypography>
                  {tag.children &&
                    renderNestedTags(
                      tag.children,
                      tag.children_type,
                      level + 1,
                      [...ancestors, tags.indexOf(tag)]
                    )}
                  <MDTypography> ) </MDTypography>
                </Grid>
              );
            });
          case 'single':
            return (
              <FormControl component="fieldset">
                <FormLabel id="test"></FormLabel>
                <RadioGroup row aria-labelledby="test">
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  {tags.map((tag) => {
                    return (
                      <Grid key={tag.id}>
                        <FormControlLabel
                          value={tag.label}
                          control={<Radio />}
                          label={tag.label}
                        />
                        {tag.type === 'input' && (
                          <TextField
                            id={tag.id}
                            variant="standard"
                            value={tag.value}
                          />
                        )}
                        {tag.children &&
                          renderNestedTags(
                            tag.children,
                            tag.children_type,
                            tag.note,
                            level + 1,
                            [...ancestors, tags.indexOf(tag)]
                          )}
                      </Grid>
                    );
                  })}
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                </RadioGroup>
              </FormControl>
            );
          case 'multi':
            return (
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  {tags.map((tag) => {
                    return (
                      <Grid item key={tag.id}>
                        <FormControlLabel
                          style={{ color: darkMode ? 'white' : 'black' }}
                          control={
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fill: '#66ccff' }}
                                />
                              }
                              // name="PhyExam3"
                            />
                          }
                          label={tag.label}
                        />
                        {tag.type === 'input' && (
                          <TextField
                            id={tag.label}
                            variant="standard"
                            value={tag.value}
                          />
                        )}
                        {tag.children &&
                          renderNestedTags(
                            tag.children,
                            tag.children_type,
                            tag.note,
                            level + 1,
                            [...ancestors, tags.indexOf(tag)]
                          )}
                      </Grid>
                    );
                  })}
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                </FormGroup>
              </FormControl>
            );
          default:
            console.log('invalid type!'); // Keep this
        }
      }
    }
  };

  //TODO: BAI tableData改由后台取得后，此处删除
  const tableData = [
    [0, 'hospital c', 'hc', 0, 'template a', template1, template1, template1],
    [1, 'hospital d', 'hd', 1, 'template b', template2, template2, template2],
  ];

  const columns = [
    {
      name: 'id',
      label: 'Organization Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'orgName',
      label: 'Organization Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'orgCode',
      label: 'Organization Code',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'temId',
      label: 'Template Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'temName',
      label: 'Template Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preview',
      label: 'Preview',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const org = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenPreview(org);
                }}>
                Preview
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'update',
      label: 'Update',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const org = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  // setTemplatePreviewModalOpen(value);
                }}>
                Update
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const template = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteTemplate(template.temId);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    rowsPerPageOptions: [10, 20, 50, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            <Grid container item xs={4} columnSpacing={1}>
              <Grid item>
                <h3>テンプレート</h3>
              </Grid>
              <Grid item xs={2}>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setTemplateNewModalOpen(true);
                  }}>
                  New
                </MDButton>
              </Grid>
            </Grid>
          }
        />
      );
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h3>テンプレート</h3>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenNewTemplate();
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>
      {templatePreviewModalOpen && (
        <Modal
          isOpen
          testId="modal:template-preview"
          width={1040}
          withcloseIcon={true}
          onClose={() => setTemplatePreviewModalOpen(false)}
          renderContent={() => renderRoot(tagJson)}
          overflow="scroll"
        />
      )}
      {templateNewModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-org-details"
          width={1040}
          withCloseIcon={true}
          onClose={() => setTemplateNewModalOpen(false)}
          renderContent={() => (
            <NewTemplate
              organizationInfo={organizationInfo}
              templateNewModalOpen={templateNewModalOpen}
              setTemplateNewModalOpen={setTemplateNewModalOpen}
            />
          )}
        />
      )}
      {/* {orgDeleteModalOpen && (
      <Modal
        isOpen
        testId="modal:admin-org-delete"
        width={1040}
        withCloseIcon={true}
        onClose={() => setOrgDeleteModalOpen(false)}
        renderContent={() => (
          <Delete
            dataType="Organization"
            dataId={orgIdSelected}
            data={orgDataSelected}
            deleteData={handleDeleteOrg}
            close={setOrgDeleteModalOpen}
          />
        )}
      />
    )} */}
    </Fragment>
  );
};

DashboardOrganizationSettingsTemplateManagement.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsTemplateManagement);
