import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { formatDeadline } from 'shared/utils/formatTime';
import { SectionTitle } from '../Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  updateIssue: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetailsEstimateTracking = ({ issue, updateIssue }) => {
  return (
    <Fragment>
      <SectionTitle>{intl.get('issue_details_text_deadline')}</SectionTitle>
      <h4>
        {issue.priority === '2'
          ? formatDeadline(issue.deadline)
          : issue.deadline}
      </h4>
    </Fragment>
  );
};

ProjectBoardIssueDetailsEstimateTracking.propTypes = propTypes;

export default ProjectBoardIssueDetailsEstimateTracking;
