import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CornerstoneViewerport from 'react-cornerstone-viewport';
import { withRouter } from 'react-router';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

// TODO
const propTypes = {
  dicomFilePaths: PropTypes.object.isRequired,
};

const ViewerViewport = ({ dicomFilePaths }) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  // TODO Might need translate or make it into a constant
  const tools = [
    {
      name: 'Wwwc',
      mode: 'active',
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: 'Zoom',
      mode: 'active',
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: 'Pan',
      mode: 'active',
      modeOptions: { mouseButtonMask: 4 },
    },
    {
      name: 'StackScrollMouseWheel',
      mode: 'active',
    },
    {
      name: 'PanMultiTouch',
      mode: 'active',
    },
    {
      name: 'ZoomTouchPinch',
      mode: 'active',
    },
    {
      name: 'StackScrollMultiTouch',
      mode: 'active',
    },
  ];

  const imageIds = dicomFilePaths.map((filePath) => {
    return `dicomweb${filePath.substring(5)}`;
  });

  return (
    <Fragment>
      <CornerstoneViewerport imageIds={imageIds} />
    </Fragment>
  );
};

ViewerViewport.propTypes = propTypes;

export default withRouter(ViewerViewport);
