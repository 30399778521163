import Icon from '@mui/material/Icon';
import intl from 'react-intl-universal';

// import CreateIcon from '@mui/icons-material/Create';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScienceIcon from '@mui/icons-material/Science';
import PaidIcon from '@mui/icons-material/Paid';

// page components
import New from './New';
import ReceivedHistory from './ReceivedHistory';
import SentHistory from './SentHistory';
import BillingForReceivedTasks from './Billing/BillingForReceivedTasks';
import BillingForSentCases from './Billing/BillingForSentTasks';
import Kanban from './Kanban';
import OrganizationSettings from './OrganizationSettings';
import OrganizationLogs from './OrganizationLogs';
import Manual from './Manual';
import ServiceTerms from './ServiceTerms';
import Friends from './Contacts/Friends';
import ResearchProjects from './Research/Projects';
import ResearchCases from './Research/Cases';
import ResearchAttachments from './Research/Attachments';
// Kenshin page components
import NewKenshin from 'pages/Dashboard/New_Kenshin';
import KanbanKenshin from 'pages/Dashboard/Kanban_Kenshin';
import ReceivedHistoryKenshin from 'pages/Dashboard/ReceivedHistory_Kenshin';
import SentHistoryKenshin from 'pages/Dashboard/SentHistory_Kenshin';
import ReceivedAssignmentKenshin from 'pages/Dashboard/Assignment/ReceivedAssignmentKenshin';

import {
  MenuOptionsAll,
  // MenuOptionsForDoctor,
  // MenuOptionsForManager,
  // MenuOptionsForStaff,
  // MenuOptionsDefault,
} from 'shared/constants/menuOptions';
import {
  ROUTES,
  // ROUTE_TITLE
} from 'shared/constants/routes';
import {
  USER_DATA,
  // UserType
} from 'shared/constants/users';
import localData from 'shared/utils/localData';
import { Privilege, PrivilegeStatus } from 'shared/constants/privilege';
import { ISSUE_LIST_TYPE } from 'shared/constants/issueList';
import PaidWage from './PaidWage';
import MyWage from './MyWage';

// export const analyzeRouteForBreadcrumbs = (route) => { // todo: never used; remove;
//   let translatedRoute = [];

//   if (route[0] === 'home')
//     translatedRoute.push(intl.get('dashboard_button_home'));

//   if (route[0] === 'personal-settings')
//     translatedRoute.push(intl.get('dashboard_button_personal_settings'));

//   if (route[0] === 'new') {
//     translatedRoute.push(intl.get('dashboard_button_new'));
//     if (route[1] === 'new-regular')
//       translatedRoute.push(intl.get('dashboard_button_new'));
//     if (route[1] === 'new-kenshin')
//       translatedRoute.push(intl.get('dashboard_button_new_kenshin'));
//   }

//   // if (route[0] === 'task-management')
//   //   translatedRoute.push(intl.get('dashboard_button_task_management'));

//   if (route[0] === 'task-management') {
//     translatedRoute.push(intl.get('dashboard_button_task_management'));
//     if (route[1] === 'task-management-regular')
//       translatedRoute.push(intl.get('dashboard_button_task_management'));
//     if (route[1] === 'task-management-kenshin')
//       translatedRoute.push(
//         intl.get('dashboard_button_task_management_kenshin')
//       );
//   }

//   if (route[0] === 'history') {
//     translatedRoute.push(intl.get('dashboard_button_received'));
//     if (route[1] === 'received')
//       translatedRoute.push(intl.get('dashboard_button_received_history'));
//     if (route[1] === 'sent')
//       translatedRoute.push(intl.get('dashboard_button_sent'));
//     if (route[1] === 'received-kenshin')
//       translatedRoute.push(
//         intl.get('dashboard_button_history_received_kenshin')
//       );
//     if (route[1] === 'sent-kenshin')
//       translatedRoute.push(intl.get('dashboard_button_history_sent_kenshin'));
//   }

//   if (route[0] === 'assignment') {
//     translatedRoute.push(intl.get('dashboard_button_assignment'));
//     if (route[1] === 'received')
//       translatedRoute.push(intl.get('dashboard_button_assignment_received'));
//     if (route[1] === 'sent')
//       translatedRoute.push(intl.get('dashboard_button_assignment_sent'));
//   }

//   if (route[0] === 'research') {
//     translatedRoute.push(intl.get('dashboard_button_research'));
//     if (route[1] === 'new')
//       translatedRoute.push(intl.get('dashboard_button_research_new'));
//     if (route[1] === 'projects')
//       translatedRoute.push(intl.get('dashboard_button_research_projects'));
//     if (route[1] === 'cases')
//       translatedRoute.push(intl.get('dashboard_button_research_cases'));
//     if (route[1] === 'attachments')
//       translatedRoute.push(intl.get('dashboard_button_research_attachments'));
//   }

//   if (route[0] === 'contacts') {
//     translatedRoute.push(intl.get('dashboard_button_contacts'));
//     if (route[1] === 'friends')
//       translatedRoute.push(intl.get('dashboard_button_friends'));
//     if (route[1] === 'groups')
//       translatedRoute.push(intl.get('dashboard_button_groups'));
//   }

//   if (route[0] === 'billing') {
//     translatedRoute.push(intl.get('dashboard_button_billing'));
//     if (route[1] === 'received')
//       translatedRoute.push(intl.get('dashboard_button_billing_received'));
//     if (route[1] === 'sent')
//       translatedRoute.push(intl.get('dashboard_button_billing_sent'));
//   }

//   if (route[0] === 'paid-wage') {
//     translatedRoute.push(intl.get('dashboard_button_paid_wage'));
//   }

//   if (route[0] === 'my-wage') {
//     translatedRoute.push(intl.get('dashboard_button_my_wage'));
//   }

//   if (route[0] === 'settings')
//     translatedRoute.push(intl.get('dashboard_button_organization_settings'));

//   if (route[0] === 'logs')
//     translatedRoute.push(intl.get('dashboard_button_organization_logs'));

//   if (route[0] === 'manual')
//     translatedRoute.push(intl.get('dashboard_button_manual'));

//   if (route[0] === 'service-terms')
//     translatedRoute.push(intl.get('dashboard_button_service_terms'));

//   return translatedRoute;
// };

// export const analyzeRouteForBreadcrumbs = (route) => { //todo: never used; remove;
//   const translatedRoute = [];
//   route.forEach((route) => {
//     if (route in ROUTE_TITLE) {
//       translatedRoute.push(intl.get(ROUTE_TITLE[route]));
//     }
//   });
// return translatedRoute;
// };

export const getStaticMenuContents = (userInfo) => {
  // const userType = userInfo.userType; // TODO: [Privilege-Clean] Clean
  const hasAffiliation = !!userInfo.organizations;

  if (!hasAffiliation) {
    return [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: ROUTES.SERVICE_TERMS,
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];
  }

  // const menuContentForNew = {
  //   type: 'collapse',
  //   name: intl.get('dashboard_button_new'),
  //   key: 'new',
  //   route: '/new',
  //   icon: <Icon fontSize="medium">create</Icon>,
  //   component: <New />,
  //   noCollapse: true,
  // };

  // const menuContentForNewKenshin = {
  //   type: 'collapse',
  //   name: intl.get('dashboard_button_new_kenshin'),
  //   key: 'new-kenshin',
  //   route: '/new-kenshin',
  //   icon: <Icon fontSize="medium">create</Icon>,
  //   // component: <New_Kenshin />,
  //   component: <New />,
  //   noCollapse: true,
  // };

  const menuContentForNew_both = {
    type: 'collapse',
    name: intl.get('dashboard_button_new'),
    key: 'new',
    icon: <Icon fontSize="medium">create</Icon>,
    collapse: [
      {
        name: intl.get('dashboard_button_new'),
        key: 'new-regular',
        route: '/new/new-regular',
        component: <New />,
      },
      {
        name: intl.get('dashboard_button_new_kenshin'),
        key: 'new-kenshin',
        route: '/new/new-kenshin',
        component: <NewKenshin />,
      },
    ],
  };

  // const menuContentForTaskMangement = {
  //   type: 'collapse',
  //   name: intl.get('dashboard_button_task_management'),
  //   key: 'task-management',
  //   route: '/task-management',
  //   icon: <ViewKanbanIcon />,
  //   component: <Kanban />,
  //   noCollapse: true,
  // };

  // const menuContentForTaskMangementKenshin = {
  //   type: 'collapse',
  //   name: intl.get('dashboard_button_task_management_kenshin'),
  //   key: 'task-management-kenshin',
  //   route: '/task-management-kenshin',
  //   icon: <ViewKanbanIcon />,
  //   component: <KanbanKenshin />,
  //   noCollapse: true,
  // };

  const menuContentForTaskMangement_both = {
    type: 'collapse',
    name: intl.get('dashboard_button_task_management'),
    key: 'task-management',
    icon: <ViewKanbanIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_task_management'),
        key: 'task-management-regular',
        route: '/task-management/task-management-regular',
        component: <Kanban />,
      },
      {
        name: intl.get('dashboard_button_task_management_kenshin'),
        key: 'task-management-kenshin',
        route: '/task-management/task-management-kenshin',
        component: <KanbanKenshin />,
      },
    ],
  };

  const menuContentForHistory = {
    type: 'collapse',
    name: intl.get('dashboard_button_history'),
    key: 'history',
    icon: <AssignmentIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_history_received'),
        key: 'received',
        route: '/history/received',
        component: <ReceivedHistory type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_sent'),
        key: 'sent',
        route: '/history/sent',
        component: <SentHistory type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_received_kenshin'),
        key: 'received-kenshin',
        route: '/history/received-kenshin',
        component: <ReceivedHistoryKenshin type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_sent_kenshin'),
        key: 'sent-kenshin',
        route: '/history/sent-kenshin',
        component: <SentHistoryKenshin type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
    ],
  };

  const menuContentForResearch = {
    type: 'collapse',
    name: intl.get('dashboard_button_research'),
    key: 'research',
    icon: <ScienceIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_research_projects'),
        key: 'projects',
        route: '/research/projects',
        component: <ResearchProjects />,
      },
      {
        name: intl.get('dashboard_button_research_cases'),
        key: 'cases',
        route: '/research/cases',
        component: <ResearchCases />,
      },
      {
        name: intl.get('dashboard_button_research_attachments'),
        key: 'attachments',
        route: '/research/attachments',
        component: <ResearchAttachments />,
      },
    ],
  };

  const menuContentForAssignment = {
    type: 'collapse',
    name: intl.get('dashboard_button_assignment'),
    key: 'assignment',
    icon: <AssignmentIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_assignment_received'),
        key: 'received',
        route: '/assignment/received',
        component: <ReceivedHistory type={ISSUE_LIST_TYPE.ASSIGNMNET} />,
      },
      {
        name: intl.get('dashboard_button_assignment_sent'),
        key: 'sent',
        route: '/assignment/sent',
        component: <SentHistory type={ISSUE_LIST_TYPE.ASSIGNMNET} />,
      },
      {
        // name: intl.get('dashboard_button_assignement_received_kenshin'),
        name: '健診', // TODO: intl
        key: 'received-kenshin',
        route: '/assignment/received-kenshin',
        component: <ReceivedAssignmentKenshin />,
      },
    ],
  };

  const menuContentForContacts = {
    type: 'collapse',
    name: intl.get('dashboard_button_contacts'),
    key: 'contacts',
    icon: <GroupIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_friends'),
        key: 'friends',
        route: '/contacts/friends',
        component: <Friends />,
      },
    ],
  };

  const menuContentForBilling = {
    type: 'collapse',
    name: intl.get('dashboard_button_billing'),
    key: 'billing',
    icon: <Icon fontSize="medium">currency_yen</Icon>,
    collapse: [
      {
        name: intl.get('dashboard_button_billing_received'),
        key: 'received-billing',
        route: '/billing/received-billing',
        component: <BillingForReceivedTasks />,
      },
      {
        name: intl.get('dashboard_button_billing_sent'),
        key: 'sent-billing',
        route: '/billing/sent-billing',
        component: <BillingForSentCases />,
      },
    ],
  };

  const menuContentForPaidWage = {
    type: 'collapse',
    name: intl.get('dashboard_button_paid_wage'),
    key: 'paid-wage',
    icon: <PaidIcon />,
    route: '/paid-wage',
    component: <PaidWage />,
    noCollapse: true,
  };

  const menuContentForMyWage = {
    type: 'collapse',
    name: intl.get('dashboard_button_my_wage'),
    key: 'my-wage',
    icon: <PaidIcon />,
    route: '/my-wage',
    component: <MyWage />,
    noCollapse: true,
  };

  const menuContentForOrganizationSettings = {
    type: 'collapse',
    name: intl.get('dashboard_button_organization_settings'),
    key: 'settings',
    icon: <Icon fontSize="medium">settings</Icon>,
    route: '/settings',
    component: <OrganizationSettings />,
    noCollapse: true,
  };

  const menuContentForOrganizationLogs = {
    type: 'collapse',
    name: intl.get('dashboard_button_organization_logs'),
    key: 'logs',
    icon: <Icon fontSize="medium">assignment</Icon>,
    route: '/logs',
    component: <OrganizationLogs />,
    noCollapse: true,
  };

  const menuContentForManual = {
    type: 'collapse',
    name: intl.get('dashboard_button_manual'),
    key: 'manual',
    icon: <Icon fontSize="medium">lightbulb</Icon>,
    route: '/manual',
    component: <Manual />,
    noCollapse: true,
  };

  const menuContentForServiceTerms = {
    type: 'collapse',
    name: intl.get('dashboard_button_service_terms'),
    key: 'service-terms',
    icon: <Icon fontSize="medium">handshake</Icon>,
    route: '/service-terms',
    component: <ServiceTerms />,
    noCollapse: true,
  };

  const menuOptionsToContent = {
    [MenuOptionsAll.NEW]: menuContentForNew_both,
    // [MenuOptionsAll.NEW_KENSHIN]: menuContentForNewKenshin,
    [MenuOptionsAll.TASKMANAGEMENT]: menuContentForTaskMangement_both,
    // [MenuOptionsAll.TASKMANAGEMENT_KENSHIN]: menuContentForTaskMangementKenshin,
    [MenuOptionsAll.HISTORY]: menuContentForHistory,
    [MenuOptionsAll.ASSIGNMENT]: menuContentForAssignment,
    [MenuOptionsAll.CONTACTS]: menuContentForContacts,
    [MenuOptionsAll.RESEARCH]: menuContentForResearch,
    [MenuOptionsAll.BILLING]: menuContentForBilling,
    [MenuOptionsAll.PAIDWAGE]: menuContentForPaidWage,
    [MenuOptionsAll.MYWAGE]: menuContentForMyWage,
    [MenuOptionsAll.ORGSETTINGS]: menuContentForOrganizationSettings,
    [MenuOptionsAll.ORGLOGS]: menuContentForOrganizationLogs,
    [MenuOptionsAll.MANUAL]: menuContentForManual,
    [MenuOptionsAll.SERVICETERMS]: menuContentForServiceTerms,
  };

  // const getMenuOptionsByUserType = (userType) => { // todo: not used;
  //   // TODO: [Privilege-Clean] Clean
  //   if (userType === 'doctor') {
  //     return MenuOptionsForDoctor;
  //   } else if (userType === 'staff') {
  //     return MenuOptionsForStaff;
  //   } else if (userType === 'manager') {
  //     return MenuOptionsForManager;
  //   }
  //   return MenuOptionsDefault;
  // };

  // const getMenuOptionsByRole = (roles) => {   // todo: not used;
  //   // TODO: [change to by-role]
  //   return MenuOptionsDefault;
  // };

  const getMenuOptionsByPrivilege = () => {
    const privilege = JSON.parse(localData.get(USER_DATA.CURRENT_PRIVILEGE));
    const menuOptions = [];

    // new task
    if (privilege[Privilege.NEW_TASK] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.NEW);
    }

    // task management, aka, kanban
    if (
      privilege[Privilege.ORGANIZATION_TASK_MANAGEMENT] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.TASKMANAGEMENT);
    }

    // task list, including received and sent
    if (privilege[Privilege.ORGANIZATION_TASK_LIST] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.HISTORY);
    }

    // billing
    if (privilege[Privilege.BILLING] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.BILLING);
    }

    // payment
    if (privilege[Privilege.BILLING] === PrivilegeStatus.ON) {
      // TODO: ADD NEW PRIVILEGE FOR PAY
      menuOptions.push(MenuOptionsAll.PAIDWAGE);
    }

    if (
      privilege[Privilege.PERSON_IN_CHARGE_AS_DOCTOR] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.MYWAGE);
    }

    // assignment for doctors
    if (
      privilege[Privilege.PERSON_IN_CHARGE_AS_DOCTOR] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.ASSIGNMENT);
    }

    // organization management
    if (privilege[Privilege.ORGANIZATION_MANAGEMENT] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.ORGSETTINGS);
    }

    // logs
    if (privilege[Privilege.ACTIVITIES_LOG_MANAGEMENT] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.ORGLOGS);
    }

    // default
    // menuOptions.push(MenuOptionsAll.RESEARCH);
    menuOptions.push(MenuOptionsAll.CONTACTS);
    // menuOptions.push(MenuOptionsAll.MANUAL);
    menuOptions.push(MenuOptionsAll.SERVICETERMS);

    return menuOptions;
  };

  const getMenuContents = (menuOptions) => {
    const menuContents = [];
    menuOptions.forEach((option) => {
      menuContents.push(menuOptionsToContent[option]);
    });
    return menuContents;
  };

  // const menuOptions = getMenuOptionsByUserType(userType); // TODO: [Privilege-Clean] Clean
  const menuOptions = getMenuOptionsByPrivilege();
  const menuContents = getMenuContents(menuOptions);

  return menuContents;

  // todo: this is the end of this function and this file;
  // todo: below are all commented out;

  // const userType = userInfo.userType;
  // const staticMenu = [];

  // if (userType === 'doctor') {
  //   const staticMenuContentsForDoctors = [
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_received'),
  //       key: 'received',
  //       icon: <Icon fontSize="medium">mail</Icon>,
  //       collapse: [
  //         {
  //           name: intl.get('dashboard_button_received_history'),
  //           key: 'received-history',
  //           route: '/received/history',
  //           component: <ReceivedHistory />,
  //         },
  //       ],
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_in_charge'),
  //       key: 'inCharge',
  //       icon: <Icon fontSize="medium">send</Icon>,
  //       route: '/in-charge',
  //       component: <SentHistory />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_manual'),
  //       key: 'manual',
  //       icon: <Icon fontSize="medium">lightbulb</Icon>,
  //       route: '/manual',
  //       component: <Manual />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_service_terms'),
  //       key: 'service-terms',
  //       icon: <Icon fontSize="medium">handshake</Icon>,
  //       route: '/service-terms',
  //       component: <ServiceTerms />,
  //       noCollapse: true,
  //     },
  //   ];

  //   return staticMenuContentsForDoctors;
  // } else if (userType === 'staff') {
  //   const staticMenuContentsForStaffs = [
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_new'),
  //       key: 'new',
  //       route: '/new',
  //       icon: <Icon fontSize="medium">create</Icon>,
  //       component: <New userInfo={userInfo} />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_received'),
  //       key: 'received',
  //       icon: <Icon fontSize="medium">mail</Icon>,
  //       collapse: [
  //         {
  //           name: intl.get('dashboard_button_received_history'),
  //           key: 'history',
  //           route: '/received/history',
  //           component: <ReceivedHistory />,
  //         },
  //         {
  //           name: intl.get('dashboard_button_task_management'),
  //           key: 'my-task',
  //           route: '/received/my-task',
  //           component: <Kanban />,
  //         },
  //       ],
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_sent'),
  //       key: 'sent',
  //       icon: <Icon fontSize="medium">send</Icon>,
  //       route: '/sent',
  //       component: <SentHistory />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_billing'),
  //       key: 'billing',
  //       icon: <Icon fontSize="medium">currency_yen</Icon>,
  //       collapse: [
  //         {
  //           name: intl.get('dashboard_button_billing_received'),
  //           key: 'received-billing',
  //           route: '/billing/received-billing',
  //           component: <BillingForReceivedCases />,
  //         },
  //         {
  //           name: intl.get('dashboard_button_billing_sent'),
  //           key: 'sent-billing',
  //           route: '/billing/sent-billing',
  //           component: <BillingForSentCases />,
  //         },
  //       ],
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_manual'),
  //       key: 'manual',
  //       icon: <Icon fontSize="medium">lightbulb</Icon>,
  //       route: '/manual',
  //       component: <Manual />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_service_terms'),
  //       key: 'service-terms',
  //       icon: <Icon fontSize="medium">handshake</Icon>,
  //       route: '/service-terms',
  //       component: <ServiceTerms />,
  //       noCollapse: true,
  //     },
  //   ];

  //   return staticMenuContentsForStaffs;
  // } else if (userType === 'manager') {
  //   const staticMenuContentsForManagers = [
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_new'),
  //       key: 'new',
  //       route: '/new',
  //       icon: <Icon fontSize="medium">create</Icon>,
  //       component: <New />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_received'),
  //       key: 'received',
  //       icon: <Icon fontSize="medium">mail</Icon>,
  //       collapse: [
  //         {
  //           name: intl.get('dashboard_button_received_history'),
  //           key: 'history',
  //           route: '/received/history',
  //           component: <ReceivedHistory />,
  //         },
  //         {
  //           name: intl.get('dashboard_button_task_management'),
  //           key: 'my-task',
  //           route: '/received/my-task',
  //           component: <Kanban />,
  //         },
  //       ],
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_sent'),
  //       key: 'sent',
  //       icon: <Icon fontSize="medium">send</Icon>,
  //       route: '/sent',
  //       component: <SentHistory />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_billing'),
  //       key: 'billing',
  //       icon: <Icon fontSize="medium">currency_yen</Icon>,
  //       collapse: [
  //         {
  //           name: intl.get('dashboard_button_billing_received'),
  //           key: 'received-billing',
  //           route: '/billing/received-billing',
  //           component: <BillingForReceivedCases />,
  //         },
  //         {
  //           name: intl.get('dashboard_button_billing_sent'),
  //           key: 'sent-billing',
  //           route: '/billing/sent-billing',
  //           component: <BillingForSentCases />,
  //         },
  //       ],
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_organization_settings'),
  //       key: 'settings',
  //       icon: <Icon fontSize="medium">settings</Icon>,
  //       route: '/settings',
  //       component: <OrganizationSettings />,
  //       // component: <></>,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_organization_logs'),
  //       key: 'logs',
  //       icon: <Icon fontSize="medium">assignment</Icon>,
  //       route: '/logs',
  //       component: <OrganizationLogs />,
  //       // component: <></>,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_manual'),
  //       key: 'manual',
  //       icon: <Icon fontSize="medium">lightbulb</Icon>,
  //       route: '/manual',
  //       component: <Manual />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: 'collapse',
  //       name: intl.get('dashboard_button_service_terms'),
  //       key: 'service-terms',
  //       icon: <Icon fontSize="medium">handshake</Icon>,
  //       route: '/service-terms',
  //       component: <ServiceTerms />,
  //       noCollapse: true,
  //     },
  //   ];

  //   return staticMenuContentsForManagers;
  // }
  // return null;
  // switch (userType) {
  //   case UserType.DOCTOR:
  //     staticMenu.push(
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_received'),
  //         key: ROUTES.RECEIVED,
  //         icon: <Icon fontSize="medium">mail</Icon>,
  //         collapse: [
  //           {
  //             name: intl.get('dashboard_button_received_history'),
  //             key: 'received-history',
  //             route: '/received/history',
  //             component: <ReceivedHistory />,
  //           },
  //         ],
  //       },
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_in_charge'),
  //         key: ROUTES.IN_CHARGE,
  //         icon: <Icon fontSize="medium">send</Icon>,
  //         route: '/in-charge',
  //         component: <SentHistory />,
  //         noCollapse: true,
  //       }
  //     );
  //     break;
  //   case UserType.STAFF:
  //   case UserType.MANAGER:
  //     staticMenu.push(
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_new'),
  //         key: ROUTES.NEW,
  //         route: '/new',
  //         icon: <Icon fontSize="medium">create</Icon>,
  //         component: <New userInfo={userInfo} />,
  //         noCollapse: true,
  //       },
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_received'),
  //         key: ROUTES.RECEIVED,
  //         icon: <Icon fontSize="medium">mail</Icon>,
  //         collapse: [
  //           {
  //             name: intl.get('dashboard_button_received_history'),
  //             key: ROUTES.HISTORY,
  //             route: '/received/history',
  //             component: <ReceivedHistory />,
  //           },
  //           {
  //             name: intl.get('dashboard_button_task_management'),
  //             key: ROUTES.MY_TASK,
  //             route: '/received/my-task',
  //             component: <Kanban />,
  //           },
  //         ],
  //       },
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_sent'),
  //         key: ROUTES.SENT,
  //         icon: <Icon fontSize="medium">send</Icon>,
  //         route: '/sent',
  //         component: <SentHistory />,
  //         noCollapse: true,
  //       },
  //       {
  //         type: 'collapse',
  //         name: intl.get('dashboard_button_billing'),
  //         key: ROUTES.BILLING,
  //         icon: <Icon fontSize="medium">currency_yen</Icon>,
  //         collapse: [
  //           {
  //             name: intl.get('dashboard_button_billing_received'),
  //             key: ROUTES.RECEIVED_BILLING,
  //             route: '/billing/received-billing',
  //             component: <BillingForReceivedCases />,
  //           },
  //           {
  //             name: intl.get('dashboard_button_billing_sent'),
  //             key: ROUTES.SENT_BILLING,
  //             route: '/billing/sent-billing',
  //             component: <BillingForSentCases />,
  //           },
  //         ],
  //       }
  //     );
  //     if (userType === UserType.MANAGER) {
  //       staticMenu.push(
  //         {
  //           type: 'collapse',
  //           name: intl.get('dashboard_button_organization_settings'),
  //           key: ROUTES.SETTINGS,
  //           icon: <Icon fontSize="medium">settings</Icon>,
  //           route: '/settings',
  //           component: <OrganizationSettings />,
  //           // component: <></>,
  //           noCollapse: true,
  //         },
  //         {
  //           type: 'collapse',
  //           name: intl.get('dashboard_button_organization_logs'),
  //           key: ROUTES.LOGS,
  //           icon: <Icon fontSize="medium">assignment</Icon>,
  //           route: '/logs',
  //           component: <OrganizationLogs />,
  //           // component: <></>,
  //           noCollapse: true,
  //         }
  //       );
  //     }
  //     break;
  //   default:
  //     break;
  // }
  // staticMenu.push(
  //   {
  //     type: 'collapse',
  //     name: intl.get('dashboard_button_manual'),
  //     key: ROUTES.MANUAL,
  //     icon: <Icon fontSize="medium">lightbulb</Icon>,
  //     route: '/manual',
  //     component: <Manual />,
  //     noCollapse: true,
  //   },
  //   {
  //     type: 'collapse',
  //     name: intl.get('dashboard_button_service_terms'),
  //     key: ROUTES.SERVICE_TERMS,
  //     icon: <Icon fontSize="medium">handshake</Icon>,
  //     route: '/service-terms',
  //     component: <ServiceTerms />,
  //     noCollapse: true,
  //   }
  // );
  // return staticMenu;
};
