// import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import KrillChat from 'krill';
import {
  setStoredAuthToken,
  setStoredAccessToken,
  getStoredAuthToken,
} from 'shared/utils/authToken';
import { StatusCodes } from 'http-status-codes';
import AppAWS from 'root/rootAWS';
import AppAzure from 'root/rootAzure';
import { GetAzureB2CAccessToken } from 'shared/utils/azureB2C';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Memo:  More Work needed
// Authorization is applied to "/{proxy+} - ANY " in AWS API Gateway, and ANY includes OPTIONS (Prefilght).
// Therefore Prefight will fail, resulting in a 'Network Error', and in axios response interceptor the
// error.response will be undefined;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      !error.response ||
      error.response.status === 0 ||
      error.response.status === StatusCodes.UNAUTHORIZED
    ) {
      if (process.env.REACT_APP_AUTH_SERVER === 'aws-cognito') {
        const currentSession = await Auth.currentSession();
        setStoredAuthToken(currentSession.idToken.jwtToken);
        setStoredAccessToken(currentSession.accessToken.jwtToken);
        originalRequest.headers.authorization = getStoredAuthToken();
      } else if (process.env.REACT_APP_AUTH_SERVER === 'azure-adb2c') {
        const newToken = await GetAzureB2CAccessToken();
        console.log('Just updated Azure B2C access token;');
        originalRequest.headers.authorization = newToken;
      }
    }

    // TODO: will retry regardless of failure reason; some failure reason should have no retry;
    if (originalRequest.retryCount) {
      originalRequest.retryCount++;
    } else {
      originalRequest.retryCount = 1;
    }
    if (originalRequest.retryCount > 3) {
      return Promise.reject(error);
    }

    await sleep(2 ** originalRequest.retryCount * 1000);
    return axios.request(originalRequest);
  }
);

let App;
switch (process.env.REACT_APP_SIGN_IN_PAGE) {
  case 'aws-custom':
    App = AppAWS;
    break;
  case 'azure-adb2c':
    App = AppAzure;
    break;
  default:
    break;
}

export default App;
