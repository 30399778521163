/* eslint react/prop-types: 0 */
import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

// Material UI Imports
import { Button, useTheme, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';

// Icon Imports
import EditIcon from '@mui/icons-material/Edit';

import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import outlined from 'assets/theme-dark/components/button/outlined';
import DiagnosticResultChips from './DiagnosticResult';

const propTypes = {
  data: PropTypes.array.isRequired,
  updateLocalData: PropTypes.func.isRequired,
  handleOpenViewer: PropTypes.func.isRequired,
  tablePageIndex: PropTypes.number.isRequired,
  tablePageSize: PropTypes.number.isRequired,
};

const Table = (
  { data, handleOpenViewer, tablePageIndex, tablePageSize },
  ref
) => {
  const theme = useTheme();

  const [pagination, setPagination] = useState({
    pageIndex: tablePageIndex,
    pageSize: tablePageSize,
  });

  useImperativeHandle(ref, () => ({
    pagination,
    setPagination,
  }));

  const columns = useMemo(
    () => [
      {
        header: 'From', // TODO: intl
        accessorKey: 'srcOrgName',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Case ID', // TODO: intl
        accessorKey: 'caseIdentifierId',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Patient ID', // TODO: intl
        accessorKey: 'patientId',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },

      {
        header: 'Study Instance UID', // TODO: intl
        accessorKey: 'studyInstanceUID',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Body Part', // TODO: intl
        accessorKey: 'bodypart',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Modality',
        accessorKey: 'modality',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: '診断結果', // TODO: intl
        accessorKey: 'diagnosis',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
        filterVariant: 'select',
        filterSelectOptions: [
          // todo: dacao: useMemo();
          {
            label: '未診断',
            value: 'TO_BE_DIAGNOSED',
          },
          {
            label: '異常なし',
            value: 'DIAGNOSED_HEALTHY',
          },
          {
            label: '異常あり',
            value: 'DIAGNOSED_SICK',
          },
          {
            label: '不明',
            value: 'DIAGNOSED_UNKNOWN',
          },
        ],
        filterFn: (row, id, filterValue) => {
          return row.getValue(id) === filterValue;
        },
        Cell: ({ cell }) => {
          return <DiagnosticResultChips result={cell.getValue()} />;
        },
      },
      {
        header: 'Viewer', // intl
        accessorKey: 'viewerInfo',
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const viewerInfo = cell.getValue();
          const caseId = viewerInfo.caseId;
          const index = viewerInfo.index;
          return (
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white' }}
              onClick={() => {
                handleOpenViewer(caseId, index);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          );
        },
      },
    ],
    []
  );

  // const [rowSelection, setRowSelection] = useState({});
  // const [selectingAll, setSelectingAll] = useState(false);
  // const [columnFilters, setColumnFilters] = useState([]);

  const table = useMaterialReactTable({
    columns,
    data,
    localization: {
      ...MRT_Localization_JA,
      toggleSelectAll: 'このページのみ選択', // TODO: intl
    },
    enableStickyHeader: true,
    enableRowActions: false,
    state: {
      density: 'compact',
      showColumnFilters: true,
      pagination,
    },
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  return (
    <ThemeProvider theme={createTheme(theme, jaJP)}>
      <MaterialReactTable id="AssignmentKenshin" table={table} />
    </ThemeProvider>
  );
};

Table.propTypes = propTypes;

const ForwardedTable = forwardRef(Table);

export default ForwardedTable;
