/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
// react-router components
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import KrillChat from 'krill';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from '@mui/material/Modal';
import DialogContent from '@mui/material/DialogContent';

// components
import Sidenav from 'pages/Dashboard/Sidenav';
import DashboardLayout from 'pages/parts/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'pages/parts/Navbar';
import ServiceTermsPopUpWindow from 'pages/parts/ServiceTermsPopUpWindow';
import UploadMissionsMenu from 'pages/parts/UploadMissions';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { getStaticMenuContents } from './routes';
import PersonalSettings from 'pages/Dashboard/PersonalSettings';
import Home from 'pages/Dashboard/Home';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { injectLocale, queryLocalCode } from 'shared/utils/intl';
import { PageLoader, PageError } from 'components';
import { useMaterialUIController } from 'context';
import { UploadMissionContextControllerProvider } from 'context/NewIssueContext';
import { ConfirmModal } from 'shared/modals/confirmModal';
import Browse from 'pages/Dashboard/Browse';
import useStyles from './styles';

// Images
import brandWhite from 'assets/images/logos/iwg/iwg-white-logo-cropped.png';
import brandDark from 'assets/images/logos/iwg/iwg-dark-logo-cropped.png';
import { getSavedName, setSavedName } from 'shared/utils/name';
import { DOLPHIN_API, LOGOUT_URL } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import { getStoredAuthToken } from 'shared/utils/authToken';

const propTypes = {
  currentUser: PropTypes.object,
};
export default function App({ currentUser }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const classes = useStyles();

  const [showConfirmBeforeLogout, setShowConfirmBeforeLogout] = useState(false);
  const { pathname } = useLocation();

  // const [logged, setLogged] = useState(false); // todo: this is never used; why is it here?

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const [{ data, error }] = useApi.get(DOLPHIN_API.USERS);
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const userInfo = {
    ...data,
  };

  if (userInfo.token) {
    // KrillChat.Config.setStoredUser(userInfo.username, userInfo.token);
    KrillChat.Config.setStoredUser2(
      userInfo.username,
      userInfo.token,
      userInfo.username,
      getStoredAuthToken()
    );
  }

  const organizationNames = [];
  if (userInfo.organizations) {
    for (let index = 0; index < userInfo.organizations.length; index++) {
      organizationNames.push(userInfo.organizations[index].name);
    }
  }

  // store the user info data locally for other usage
  let isDifferentUser = false;
  if (
    !localData.get(USER_DATA.USERNAME) ||
    localData.get(USER_DATA.USERNAME) === 'undefined' ||
    localData.get(USER_DATA.USERNAME) !== userInfo.username ||
    !userInfo.organizations ||
    (userInfo.organizations &&
      !organizationNames.includes(
        localData.get(USER_DATA.CURRENT_ORGANIZATION)
      ))
  ) {
    localData.set(USER_DATA.USERNAME, userInfo.username);
    localData.set(USER_DATA.USER_ID, userInfo.id);
    setSavedName(userInfo.firstName, userInfo.lastName);
    localData.set(USER_DATA.AVATAR_URL, userInfo.avatarUrl);
    localData.set(
      USER_DATA.CURRENT_ORGANIZATION,
      userInfo.organizations ? userInfo.organizations[0].name : null
    );
    localData.set(USER_DATA.USER_TYPE, userInfo.userType); // TODO: [Privilege-Clean] Clean
    localData.set(USER_DATA.PREFFERED_LANGUAGE, userInfo.preferredLanguage);
    localData.set(USER_DATA.TIMEZONE, userInfo.preferredTimezone);
    localData.set(USER_DATA.EMAIL, userInfo.email);

    isDifferentUser = true;
  }
  let currentOrganizationId = Number(
    localData.get(USER_DATA.CURRENT_ORGANIZATION_ID)
  );
  if (!currentOrganizationId || isDifferentUser) {
    currentOrganizationId = userInfo.organizations
      ? userInfo.organizations[0].id
      : -1;
  } else if (userInfo.organizations) {
    const organization = userInfo.organizations.filter(
      (organization) => organization.id === currentOrganizationId
    );
    if (!organization) {
      currentOrganizationId = -1;
    }
  }
  localData.set(
    USER_DATA.CURRENT_ORGANIZATION_ID,
    Number(currentOrganizationId)
  );
  const currentOrganization = userInfo.organizations.filter(
    (organization) => organization.id === currentOrganizationId
  );
  localData.set(
    USER_DATA.IS_OWNER,
    currentOrganization.ownerId === userInfo.id
  );
  const currentEmployment = userInfo.employments.find(
    (employment) => employment.organizationId === currentOrganizationId
  );
  localData.set(
    USER_DATA.CURRENT_PRIVILEGE,
    currentEmployment ? currentEmployment.privilege : {}
  );

  const { savedFirstName, savedLastName } = getSavedName();
  if (
    savedFirstName !== userInfo.firstName ||
    savedLastName !== userInfo.lastName
  ) {
    setSavedName(userInfo.firstName, userInfo.lastName);
  }

  if (
    localData.get(USER_DATA.PREFFERED_LANGUAGE) !== userInfo.preferredLanguage
  ) {
    localData.set(USER_DATA.PREFFERED_LANGUAGE, userInfo.preferredLanguage);
  }

  const routes = getStaticMenuContents(userInfo);

  injectLocale(queryLocalCode(localData.get(USER_DATA.PREFFERED_LANGUAGE)));

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route path={route.route} key={route.key}>
            {route.component}
          </Route>
        );
      }
      return null;
    });

  const handleClickLogout = () => {
    setShowConfirmBeforeLogout(true);
  };

  const LogoutModal = () => {
    const context = KrillChat.Context.useKrillChatContext();
    const signOut = async () => {
      if (
        process.env.REACT_APP_AUTH_SERVER === 'aws-cognito' &&
        process.env.REACT_APP_SIGN_IN_PAGE === 'aws-custom'
      ) {
        try {
          // KrillChat.API.logout(); // TODO: Think more
          await context.logout();
          await Auth.signOut();
          // TODO: Clean localData
          localData.clear();
          window.location.assign(
            process.env.REACT_APP_LOGOUT_REDIRECT_URL || LOGOUT_URL
          );
        } catch (error) {
          console.log('error signing out: ', error);
        }
      }
    };
    return (
      <ConfirmModal
        isOpened={showConfirmBeforeLogout}
        title={intl.get('dashboard_logout_modal_text_confirm_title')}
        bodyText={intl.get('dashboard_logout_modal_text_confirm_hint')}
        onConfirm={signOut}
        onCancel={() => setShowConfirmBeforeLogout(false)}
        confirmButtonText={intl.get('dashboard_logout_modal_button_logout')}
        cancelButtonText={intl.get(
          'dashboard_logout_modal_button_cancel'
        )}></ConfirmModal>
    );
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <KrillChat.Provider>
        <CssBaseline />
        <div
          style={{
            overflowY: 'visible',
            minWidth: '1680px',
            minHeight: '1050px',
            // height: '100vh',
          }}>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="I.W.G Docloud"
            routes={routes}
            userInfo={userInfo}
            handleClickLogout={handleClickLogout}
          />

          <UploadMissionContextControllerProvider>
            <DashboardLayout>
              <DashboardNavbar />
              {data.agreedServiceTerms && (
                <Switch>
                  {getRoutes(routes)}
                  <Route exact path="/personal-settings">
                    <PersonalSettings
                      userInfo={userInfo}
                      currentUser={currentUser}
                    />
                  </Route>
                  <Route exact path="/home">
                    <Home userInfo={userInfo} />
                  </Route>
                  <Route path={`/browse/issues/:issueId`}>
                    <Browse />
                  </Route>
                  <Redirect from="*" to={'/home'} />
                </Switch>
              )}

              <UploadMissionsMenu />

              {/* Irregular so will be left as it is  */}
              {/* TODO: these following two modals will cause runtime warnings;  */}
              <Modal
                open={!data.agreedServiceTerms}
                className={classes.agreeServiceTermsModal}
                aria-labelledby="modal-service-terms"
                aria-describedby="modal-agreed-service-terms">
                <DialogContent>
                  <ServiceTermsPopUpWindow />
                </DialogContent>
              </Modal>

              {/* <ConfirmModal
                isOpened={showConfirmBeforeLogout}
                title={intl.get('dashboard_logout_modal_text_confirm_title')}
                bodyText={intl.get('dashboard_logout_modal_text_confirm_hint')}
                onConfirm={signOut}
                onCancel={() => setShowConfirmBeforeLogout(false)}
                confirmButtonText={intl.get(
                  'dashboard_logout_modal_button_logout'
                )}
                cancelButtonText={intl.get(
                  'dashboard_logout_modal_button_cancel'
                )}></ConfirmModal> */}
              <LogoutModal />
            </DashboardLayout>
          </UploadMissionContextControllerProvider>
          <KrillChat.UI.Widget.View settings={{}} />
        </div>
      </KrillChat.Provider>
    </ThemeProvider>
  );
}

App.propTypes = propTypes;
