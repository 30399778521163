/* eslint react/prop-types: 0 */
import React, { Fragment, useState, useMemo, useEffect } from 'react';
// import { withRouter } from 'react-router';
// import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // getMRT_RowSelectionHandler,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';
//Import Material React Table Translations
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
// import { MRT_Localization_EN } from 'material-react-table/locales/en';
//Material UI Imports
import {
  Box,
  Button,
  // ListItemIcon,
  // MenuItem,
  Typography,
  lighten,
  // darken,
  useTheme,
  createTheme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';
//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
// import { Grid } from '@material-ui/core';
// import Card from '@mui/material/Card';

// self defined stuff
import { Avatar, Select, Icon } from 'components';
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import { Status, User, Username } from './styles';
import DiagnosticResultChips from './DiagnosticResult';
import { filterData, generateRowSelectionObject } from './utils';
// import { displayName } from 'react-quill';
import { getUserFullName } from 'shared/utils/name';

const propTypes = {
  issue: PropTypes.any,
  doctors: PropTypes.any,
  setBatchEditModalIsOpen: PropTypes.func,
  setSelectedCases: PropTypes.func,
  isClient: PropTypes.bool,
  setViewModalOpen: PropTypes.func.isRequired,
  setViewportWebUrls: PropTypes.func.isRequired,
};

const Table = ({
  issue,
  doctors,
  setBatchEditModalIsOpen,
  setSelectedCases,
  isClient,
  setViewModalOpen,
  setViewportWebUrls,
}) => {
  const theme = useTheme();

  // diagnosis and doctor info should be hidden from sender organization;
  // todo: backend is also hiding this info;
  const isHiding =
    isClient &&
    [
      IssueStatusKenshin.DOCTORASSIGNED,
      IssueStatusKenshin.REPORTREADY,
    ].includes(issue.progress);

  const diagnosticResultFilterSelectOptions = [
    // todo: dacao: useMemo();
    {
      label: '未診断',
      value: 'TO_BE_DIAGNOSED',
    },
    {
      label: '異常なし',
      value: 'DIAGNOSED_HEALTHY',
    },
    {
      label: '異常あり',
      value: 'DIAGNOSED_SICK',
    },
    {
      label: '不明',
      value: 'DIAGNOSED_UNKNOWN',
    },
  ];

  const CONSTANT_NO_DOCTOR_ASSIGNED = '未割り当て'; // todo: intl

  const removeDuplicatesById = (arr) => {
    const seenIds = new Set();
    return arr.filter((obj) => {
      if (!seenIds.has(obj.doctorId)) {
        seenIds.add(obj.doctorId);
        return true;
      }
      return false;
    });
  };

  let doctorsFilterOptions;
  if (isClient) {
    const tmpArr = issue.cases.map((item) => ({
      doctorId: item.doctorId,
      doctorFullName: getUserFullName(
        item.doctorFirstName,
        item.doctorLastName
      ),
    }));

    var uniqueDoctorsById = removeDuplicatesById(tmpArr);
    uniqueDoctorsById = uniqueDoctorsById.filter(
      (item) => item.doctorId && item.doctorFullName
    );

    doctorsFilterOptions = uniqueDoctorsById.map((doctor) => ({
      label: doctor.doctorFullName,
      value: doctor.doctorId,
    }));
  } else {
    doctorsFilterOptions = doctors.map((doctor) => ({
      label: getUserFullName(doctor.firstName, doctor.lastName),
      value: doctor.id,
    }));
  }

  doctorsFilterOptions.push({
    label: CONSTANT_NO_DOCTOR_ASSIGNED,
    value: -1,
  });

  // // Light or dark white
  // const baseBackgroundColor =
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(230, 230, 230, 1)' // Darker shade of white for dark mode
  //     : 'rgba(255, 255, 255, 1)'; // Pure white for light mode

  const columns = useMemo(
    () => [
      // {
      //   header: 'ファイル名', // 'File Name'
      //   accessorKey: 'filename',
      //   size: 50,
      //   enableColumnActions: false,
      //   enableColumnFilter: true,
      //   Cell: ({ cell }) => {
      //     const filename = cell.getValue();
      //     console.log('filename: ', filename, typeof filename);
      //     return filename.join(', ');
      //   },
      // },

      // {
      //   header: 'index',
      //   accessorKey: 'index',
      //   size: 50,
      //   enableColumnActions: false,
      //   enableColumnFilter: true,
      //   filterFn: 'between',
      //   enableColumnFilterModes: false,
      //   // columnFilterModeOptions: ['between'],
      // },

      {
        header: '番号',
        accessorKey: 'displayIndex',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
        filterFn: 'between',
        enableColumnFilterModes: false,
      },

      {
        header: '患者番号',
        accessorKey: 'patientId',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
        enableColumnFilterModes: false,
      },
      {
        header: 'Study ID',
        accessorKey: 'studyInstanceUID',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
        enableColumnFilterModes: false,
      },
      {
        header: 'Body Part',
        accessorKey: 'bodypart',
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: true,
        enableColumnFilterModes: false,
      },
      {
        header: 'Modality',
        accessorKey: 'modality',
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: true,
        enableColumnFilterModes: false,
      },
      {
        header: '担当医師',
        accessorKey: 'doctorId',
        size: 50,
        // enableClickToCopy: true,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableColumnFilter: isHiding ? false : true,
        // filterFn: 'fuzzy',
        filterVariant: 'select',
        filterSelectOptions: doctorsFilterOptions,
        Cell: ({ cell }) => {
          const doctorId = cell.getValue();
          const doctorOption = doctorsFilterOptions.find(
            (d) => d.value === doctorId
          );
          if (isHiding) {
            return '進行中'; // todo: intl;
          } else {
            return doctorOption.label;
          }
        },
      },
      {
        header: '診断結果', // （未診断、不明、異常あり、異常なし）
        accessorKey: 'diagnosis',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: isHiding ? false : true,
        filterVariant: 'select',
        enableColumnFilterModes: false,
        filterSelectOptions: diagnosticResultFilterSelectOptions,
        filterFn: (row, id, filterValue) => {
          return row.getValue(id) === filterValue;
        },
        //custom conditional format and styling
        Cell: ({ cell }) => {
          if (isHiding) {
            return '進行中'; // todo: intl;
          } else {
            return <DiagnosticResultChips result={cell.getValue()} />;
          }
        },
      },
      {
        header: '表示',
        accessorKey: 'webUrl',
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        // //custom conditional format and styling
        Cell: ({ cell }) => {
          const webUrl = cell.getValue();
          return (
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white' }} // make the text color to be white; refine code;
              onClick={async () => {
                setViewportWebUrls(webUrl);
                setViewModalOpen(true);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          );
        },
      },
    ],
    []
  );

  const allData = issue.cases.map((item, index) => ({
    index: index,
    displayIndex: index + 1,
    ...item,
    doctorId: item.doctorId == null ? -1 : item.doctorId,
  }));

  const [rowSelection, setRowSelection] = useState({});
  // const [selectingAll, setSelectingAll] = useState(false);
  // const [selectingSome, setSelectingSome] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState(allData); // This is the data shown in the table; after going through filters;

  useEffect(() => {
    const filteredData = filterData(allData, columnFilters);

    // Convert the indexes in filteredData to a Set for efficient lookup
    const dataIndexes = new Set(filteredData.map((item) => item.index));
    // Iterate over the keys of rowSelection
    Object.keys(rowSelection).forEach((key) => {
      if (!dataIndexes.has(key)) delete rowSelection[key];
    });
    setRowSelection({ ...rowSelection });

    setData([...filteredData]);
  }, [issue]);

  useEffect(() => {
    const filteredData = filterData(allData, columnFilters);
    // Convert the indexes in filteredData to a Set for efficient lookup
    const dataIndexes = new Set(filteredData.map((item) => item.index));
    // Iterate over the keys of rowSelection
    Object.keys(rowSelection).forEach((key) => {
      const index = parseInt(key, 10); // Must convert key to number
      if (!dataIndexes.has(index)) delete rowSelection[index];
    });
    setRowSelection({ ...rowSelection });

    setData([...filteredData]);
  }, [columnFilters]);

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization_JA,
      toggleSelectAll: 'このページのみ選択',
    },

    enableColumnFilterModes: true,
    // layoutMode: 'grid',
    enableStickyHeader: true,
    // enableRowActions: false,
    enableRowSelection: true,
    getRowId: (row) => row.index,
    enableSelectAll: true,
    selectAllMode: 'page', // 'all'
    showColumnFilters: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      density: 'compact',
      showColumnFilters: true,
    },

    // manualFiltering: true, //turn off client-side filtering
    onColumnFiltersChange: setColumnFilters, //hoist internal columnFilters state to your state

    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',

    // UI styles config
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
    // muiSelectAllCheckboxProps: { color: 'secondary' },
    muiSelectCheckboxProps: { color: 'secondary' },
    muiTableContainerProps: { sx: { maxHeight: '60vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontSize: '15px',
      },
    },

    //clicking anywhere on the row will select it
    // muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
    //   onClick: (event) =>
    //     getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
    //   sx: { cursor: 'pointer' },
    // }),

    muiTableBodyRowProps: {
      sx: {
        // height: '10px',
        // fontSize: '14px',
        border: 1,
        // py: '10px',
        // my: '10px',
        // py: 10,
        // my: 10,
      },
    },

    muiTableBodyCellProps: {
      size: 'small',
      sx: {
        fontSize: '14px',
        // '& .MuiTableCell-sizeSmall': {
        //   fontSize: '12px',
        // },
        //use the `&` syntax to target hover state
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
    muiFilterTextFieldProps: {
      sx: { width: '100%', p: '1px 0 1px' },
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      // 'mrt-row-numbers': {
      //   size: 20,
      //   grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      // },
    },

    // muiTableBodyProps: {
    //   sx: (theme) => ({
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]) > td': {
    //       backgroundColor: darken(baseBackgroundColor, 0.1),
    //     },
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]):hover > td':
    //       {
    //         backgroundColor: darken(baseBackgroundColor, 0.2),
    //       },
    //   }),
    // },
    // mrtTheme: (theme) => ({
    //   baseBackgroundColor: baseBackgroundColor,
    //   // draggingBorderColor: theme.palette.secondary.main,
    // }),

    renderTopToolbar: ({ table }) => {
      // const handleSelectAllButtonClick = () => {
      //   if (selectingAll) {
      //     table.getState().rowSelection = {};
      //     setRowSelection({});
      //     setSelectingAll(false);
      //   } else {
      //     const rowSelection = generateRowSelectionObject(data);
      //     table.getState().rowSelection = rowSelection;
      //     setRowSelection(rowSelection);
      //     setSelectingAll(true);
      //   }
      // };
      const handleSelectAllButtonClick = () => {
        if (Object.keys(rowSelection).length > 0) {
          table.getState().rowSelection = {};
          setRowSelection({});
          // setSelectingSome(false);
        } else {
          const rowSelection = generateRowSelectionObject(data);
          table.getState().rowSelection = rowSelection;
          setRowSelection(rowSelection);
          // setSelectingSome(true);
        }
      };

      const handleBatchEditClick = () => {
        // console.log(` === rowSelection: `, rowSelection);
        // console.log(` === table.getState().rowSelection: `, table.getState().rowSelection);
        // console.log(' === table.getSelectedRowModel().rows: ', table.getSelectedRowModel().rows)
        // const selectedIndexes = table
        // .getSelectedRowModel()
        // .rows.map((item) => item.index);
        const selectedIndexes = Object.keys(rowSelection).map((key) =>
          parseInt(key)
        );
        const selectedCases = selectedIndexes
          .map((index) => allData[index])
          .filter(Boolean);
        setSelectedCases(selectedCases);
        setBatchEditModalIsOpen(true);
      };

      const handleExportCsv = () => {
        const csvContent =
          'data:text/csv;charset=utf-8,' +
          '\uFEFF' +
          'CaseID, Modality, Bodypart, Diagnosis, DiagnosticNote\n' +
          issue.cases
            .map((case_) => {
              const caseId = '';
              const modality = case_.modality;
              const bodypart = case_.bodypart;
              const diagnosis = case_.diagnosis;
              const diagnosticNote = case_.diagnosticNote;
              return (
                caseId +
                ',' +
                modality +
                ',' +
                bodypart +
                ',' +
                diagnosis +
                ',' +
                diagnosticNote
              );
            })
            .join('\n');

        const encodeUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodeUri);
        link.setAttribute('download', `all-diagnosis.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* <MRT_GlobalFilterTextField table={table} /> */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Typography>{`表示されている症例数: ${data.length} 件`}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color="info"
                disabled={data.length === 0}
                onClick={handleSelectAllButtonClick}
                variant="contained">
                {Object.keys(rowSelection).length > 0
                  ? '選択をクリア'
                  : 'すべて選択'}
              </Button>
              {isClient === false && (
                <Button
                  color="info"
                  disabled={Object.keys(rowSelection).length === 0}
                  onClick={handleBatchEditClick}
                  variant="contained"
                  startIcon={<EditIcon />}>
                  割り当て
                </Button>
              )}
              <Button
                color="info"
                // disabled={!table.getIsSomeRowsSelected()}
                onClick={handleExportCsv}
                variant="contained"
                startIcon={<DownloadIcon />}>
                CSV
              </Button>
              {/* // todo: intl */}
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, jaJP)}>
      <MaterialReactTable
        id="KanbanKenshinIssueDetailsCasesTable"
        table={table}
      />
    </ThemeProvider>
  );
};

Table.propTypes = propTypes;

export default Table;
