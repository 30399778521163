import axios from 'axios';
import { getStoredAuthToken } from './authToken';
import { objectToQueryString } from './url';
import localData from 'shared/utils/localData';
import api2 from 'shared/utils/api2'; // todo: temporary solution; must fix later;

const REQUEST_TYPES = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
  PUT: 'put',
};

const defaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: (variables) => ({
    'Content-Type': 'application/json',
    authorization: getStoredAuthToken(),
    ...variables.headers,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const api = (method, url, variables) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(variables),
      params: variables.params,
      data: variables.data,
      paramsSerializer: objectToQueryString,
      onDownloadProgress: variables.onDownloadProgress,
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
        // if (error.response) {
        //   if (error.response.data.error.code === 'INVALID_TOKEN') {
        //     reject(error);
        //   } else {
        //     reject(error.response.data.error);
        //   }
        // } else {
        //   reject(error);
        // }
      }
    );
  });
};

const optimisticUpdate = async (
  url,
  { updatedFields, currentFields, setLocalData }
) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: updatedFields,
    });
  } catch (error) {
    console.log(`optimisticUpdate error: ${error}`);
    setLocalData(currentFields);
  }
};

const optimisticBatchUpdateIssues = async (
  url,
  { issues, updatedFields, setLocalData }
) => {
  try {
    for (const issue of issues) {
      setLocalData(issue.id, updatedFields);
    }
    const issueIds = issues.map((issue) => issue.id);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: { fields: updatedFields, issueIds: issueIds },
    });
  } catch (error) {
    console.log(`optimisticBatchUpdateIssues error: ${error}`);
    for (const issue of issues) {
      setLocalData(issue.id, issue);
    }
  }
};

const dolphinApi = {
  get: (...args) => api(REQUEST_TYPES.GET, ...args),
  post: (...args) => api(REQUEST_TYPES.POST, ...args),
  put: (...args) => api(REQUEST_TYPES.PUT, ...args),
  patch: (...args) => api(REQUEST_TYPES.PATCH, ...args),
  delete: (...args) => api(REQUEST_TYPES.DELETE, ...args),
  optimisticUpdate,
  optimisticBatchUpdateIssues,
};

// export default dolphinApi;
export default api2; // todo: temporary solution; must fix later;
