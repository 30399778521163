import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

// TODO Object to shape
const propTypes = {
  invoiceRemarks: PropTypes.object.isRequired,
};

const InvoiceRemarks = ({ invoiceRemarks }) => {
  return (
    <Fragment>
      <View style={styles.invoiceRemarks}>
        <Text style={styles.invoiceRemarksLeft}>
          {intl.get('billing_invoice_text_remarks')}
        </Text>
        <Text style={styles.invoiceRemarksRight}>{invoiceRemarks.text}</Text>
      </View>
    </Fragment>
  );
};
InvoiceRemarks.propTypes = propTypes;
export default InvoiceRemarks;
