import axios from 'axios';

// todo: change this naming to be more general (for aws and azure) after major merge
export const axiosForS3PresignedUrl = axios.create();
delete axiosForS3PresignedUrl.defaults.headers.common['authorization'];
delete axios.defaults.headers.put['Content-Type'];

if (process.env.REACT_APP_CLOUD_PROVIDER === 'azure') {
  // Add the 'x-ms-blob-type' header with a value of 'BlockBlob'
  axiosForS3PresignedUrl.defaults.headers.common['x-ms-blob-type'] =
    'BlockBlob';
}
