import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon, Button } from 'components';
import { SectionTitle } from '../Styles';
import { User, Username, Quota } from './Styles';
import { withRouter } from 'react-router-dom';

import { UserContextMenu } from 'components/Menu';

import {
  IssueState,
  IssueStatus,
  getIssueStatusForClient,
} from 'shared/constants/issues';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { LANGUAGE, UserType } from 'shared/constants/users';
import { UserProptypes } from 'shared/propTypes/userType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

const propTypes = {
  childrenIssues: PropTypes.arrayOf(PropTypes.object),
  setNewChildIssueModalOpen: PropTypes.func.isRequired,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const ProjectBoardIssueDetailsChildrenIssues = ({
  childrenIssues,
  setNewChildIssueModalOpen,
  match,
  history,
}) => {
  const handleAddSubTask = () => {
    setNewChildIssueModalOpen(true);
  };
  return (
    <Fragment>
      <SectionTitle>
        {intl.get('issue_details_text_children_issues')}
      </SectionTitle>
      {childrenIssues &&
        childrenIssues.map((issue, index) => {
          return (
            <div key={index}>
              <Button
                variant="success"
                sx={{ justifyContent: 'start' }}
                onClick={() => {
                  history.push(`/history/sent/issues/${issue.id}`);
                }}>{`${issue.title} (${getIssueStatusForClient(
                issue.status
              )})`}</Button>
            </div>
          );
        })}
      <Button
        variant="primary"
        icon="issues"
        iconSize={20}
        sx={{ justifyContent: 'start' }}
        onClick={handleAddSubTask}>
        {intl.get('issue_details_button_add_child_issue')}
      </Button>
    </Fragment>
  );
};

ProjectBoardIssueDetailsChildrenIssues.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetailsChildrenIssues);
