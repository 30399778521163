import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  Grid,
  Tooltip,
  IconButton,
  Popover,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
import MoneyIcon from '@mui/icons-material/Money';
import CloseIcon from '@mui/icons-material/Close';

import { updateBillForReceivedTasks } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardBillingForReceivedTasksDetailedTableCustomToolbarSelectFeeAdjustment =
  ({ selectedRows, data, setData }) => {
    const classes = useStyles();

    const [newFee, setNewFee] = useState();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const anchorEl = useRef(null);

    const handleIconButtonClick = (event) => {
      anchorEl.current = event.currentTarget;
      setPopoverOpen(true);
    };

    const handlePopoverClose = () => {
      setPopoverOpen(false);
    };

    const handleChange = (event) => {
      setNewFee(Number(event.target.value));
    };

    const handleApply = () => {
      const dataIndice = selectedRows.data.map((row) => {
        return row.dataIndex;
      });

      const newData = data;
      dataIndice.forEach((dataIndex) => {
        const billId = data[dataIndex][5].billId;
        newData[dataIndex][4].currentFee = newFee;
        updateBillForReceivedTasks(billId, { newFee: newFee });
      });
      setData([...newData]);
    };

    return (
      <Fragment>
        <Tooltip
          className={classes.tooltip}
          title={intl.get(
            'billing_for_received_cases_toolbar_select_tooltip_fee_adjustment'
          )}>
          <IconButton
            className={classes.iconButton}
            onClick={(event) => {
              handleIconButtonClick(event);
            }}>
            <MoneyIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Popover
          elevation={2}
          open={popoverOpen}
          // TransitionProps={{ onExited: {} }}
          onClose={handlePopoverClose}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <IconButton
            aria-label="Close"
            onClick={handlePopoverClose}
            className={classes.closeIconButton}>
            <CloseIcon />
          </IconButton>
          <Grid container className={classes.popoverContentRoot}>
            <Grid
              container
              item
              className={classes.popoverContent}
              xs={12}
              spacing={1}>
              <Grid item xs={9} className={classes.input}>
                <TextField
                  label={intl.get(
                    'billing_for_received_cases_toolbar_select_input_label_new_wage'
                  )}
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                    },
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleApply}>
                  {intl.get('paid_wage_toolbar_select_button_adjust_wage')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </Fragment>
    );
  };

DashboardBillingForReceivedTasksDetailedTableCustomToolbarSelectFeeAdjustment.propTypes =
  propTypes;

export default DashboardBillingForReceivedTasksDetailedTableCustomToolbarSelectFeeAdjustment;
