import React, { useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CornerstoneViewerport from 'react-cornerstone-viewport';
import { withRouter } from 'react-router';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

// TODO
const propTypes = {
  webUrls: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};

const DashboardKanbanKenshinIssueDetailsViewport = ({ webUrls, close }) => {
  const classes = useStyles();

  // TODO Might need translate or make it into a constant
  const tools = [
    {
      name: 'Wwwc',
      mode: 'active',
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: 'Zoom',
      mode: 'active',
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: 'Pan',
      mode: 'active',
      modeOptions: { mouseButtonMask: 4 },
    },
    {
      name: 'StackScrollMouseWheel',
      mode: 'active',
    },
    {
      name: 'PanMultiTouch',
      mode: 'active',
    },
    {
      name: 'ZoomTouchPinch',
      mode: 'active',
    },
    {
      name: 'StackScrollMultiTouch',
      mode: 'active',
    },
  ];

  const imageIds = webUrls.map((webUrl) => {
    return `dicomweb${webUrl.substring(5)}`;
  });

  return (
    <Grid
      sx={{
        background: 'black',
        borderBottom: 1,
        borderColor: 'divider',
        height: '85vh',
      }}>
      <Grid
        container
        className={classes.actionGrid}
        columns={{ xs: 12 }}
        justifyContent="flex-end">
        <div style={{ background: 'black' }}>
          <IconButton onClick={close}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </div>
      </Grid>
      <Grid className={classes.viewportGrid} columns={{ xs: 12 }}>
        <CornerstoneViewerport
          // imageIds={dicomFilePaths[keys[selectedIndex]].filePaths}
          imageIds={imageIds}
          tools={tools}
        />
      </Grid>
    </Grid>
  );
};

DashboardKanbanKenshinIssueDetailsViewport.propTypes = propTypes;

export default withRouter(DashboardKanbanKenshinIssueDetailsViewport);
