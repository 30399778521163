import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
// MUI
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ConstructionIcon from '@mui/icons-material/Construction';
import ErrorIcon from '@mui/icons-material/Error';

// self-made
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDProgress from 'components/MDProgress';
import CloseIcon from '@mui/icons-material/Close';
import { formatBytes, calculateRemainingTime } from 'shared/utils/misc';
import { getBadge, getMissionTypeDisplayString } from './util';
import { MISSION, STATUS } from 'shared/constants/uploadAttachments';

function UploadMissionCard({
  mission,
  setAbortUploadWarningModalIsOpen,
  setMissionIDToBeAborted,
  setMissionToBeRemoved,
}) {
  // const [dispatchUploadMissions] = useUploadMissionsContextController();

  const progress = mission.uploadingProgress;
  const remainingTime = calculateRemainingTime(
    mission.batchTotalBytes,
    mission.uploadedBytes,
    mission.uploadingSpeed
  );
  const missionStatus = mission.status;

  const handleAbortButtonClick = (e) => {
    e.stopPropagation();
    const id =
      mission.type === MISSION.ISSUE
        ? mission.issueIdentifierId
        : mission.missionId;
    setMissionIDToBeAborted(id);
    setAbortUploadWarningModalIsOpen(true);
  };

  const handleDeleteButtonClick = (e) => {
    e.stopPropagation();
    const id =
      mission.type === MISSION.ISSUE
        ? mission.issueIdentifierId
        : mission.missionId;
    setMissionToBeRemoved(id);
  };

  return (
    <Card variant="outlined" sx={{ width: '100%', p: 1 }}>
      <Grid container justify="flex-end">
        <Grid xs={6} item>
          {getBadge(missionStatus, 'sm')}
        </Grid>

        {missionStatus === STATUS.UPLOADING && (
          <Grid xs={6} item display="flex" justifyContent="flex-end">
            <MDButton
              size="small"
              width="50px"
              variant="contained"
              color="error"
              sx={{ height: 8 }}
              onClick={handleAbortButtonClick}>
              {intl.get('upload_mission_menu_item_abort')}
            </MDButton>
          </Grid>
        )}

        {[
          STATUS.UPLOAD_ABORTED,
          STATUS.UPLOAD_FAILED,
          STATUS.PROCESS_FAILED,
          STATUS.PROCESS_COMPLETE,
        ].includes(missionStatus) && (
          <Grid xs={6} item display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="delete"
              size="small"
              variant="empty"
              onClick={handleDeleteButtonClick}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <MDBox mt={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="body2" color="text" fontWeight="bold">
            {`Type: `}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="text"
            style={{ whiteSpace: 'normal' }}
            sx={{ width: '70%' }}>
            {getMissionTypeDisplayString(mission.type)}
          </MDTypography>
        </Stack>
        {mission.type === MISSION.ISSUE && (
          <Stack direction="row" spacing={1} alignItems="center">
            <MDTypography variant="body2" color="text" fontWeight="bold">
              {`${intl.get('upload_mission_menu_item_sent_to')}:`}
            </MDTypography>
            <MDTypography
              variant="body2"
              color="text"
              style={{ whiteSpace: 'normal' }}
              sx={{ width: '70%' }}>
              {`${mission.formState.dstOrgName}`}
            </MDTypography>
          </Stack>
        )}

        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="body2" color="text" fontWeight="bold">
            {`${intl.get('upload_mission_menu_item_issue_title')}:`}
          </MDTypography>
          <MDTypography variant="body2" color="text">
            {`${mission.issueIdentifierId}`}
          </MDTypography>
        </Stack>
      </MDBox>

      {missionStatus === STATUS.UPLOADING ? (
        <MDBox mb={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <MDTypography variant="body2" color="text" fontWeight="bold">
              {`${intl.get('upload_mission_menu_item_uploading_file')}:`}
            </MDTypography>
            <MDTypography
              variant="body2"
              color="text"
              style={{ whiteSpace: 'normal' }}
              sx={{ width: '70%' }}>
              {`${mission.uploadingFileName}`}
            </MDTypography>
          </Stack>

          <MDBox display="flex" alignItems="center">
            <MDBox mt={0.5} width="100%">
              <MDProgress variant="gradient" value={progress} color="info" />
            </MDBox>
            <MDTypography variant="body3" ml={1}>
              {`${Math.round(progress)}%`}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <MDTypography variant="body3">
              {`${intl.get(
                'upload_mission_menu_item_upload_speed'
              )}: ${formatBytes(mission.uploadingSpeed)}/Sec`}
            </MDTypography>
            <MDTypography variant="body3" mr={1}>
              {`${intl.get(
                'upload_mission_menu_item_upload_time_remaining'
              )}: ${remainingTime}`}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}

      {missionStatus === STATUS.PROCESSING ? (
        <MDBox my={1} display="flex" alignItems="center">
          <HourglassTopIcon color="info" fontSize="small" sx={{ mx: 1 }} />
          <MDTypography variant="body2" fontWeight="bold" color="info">
            {intl.get('upload_mission_menu_item_message_processing')}
          </MDTypography>
        </MDBox>
      ) : (
        <></>
      )}

      {missionStatus === STATUS.UPLOAD_FAILED ? (
        <MDBox mb={1} display="flex" alignItems="center">
          <ErrorIcon color="error" fontSize="small" sx={{ mx: 1 }} />
          <MDTypography variant="body2" fontWeight="bold" color="error">
            {intl.get('upload_mission_menu_item_message_upload_failed')}
          </MDTypography>
        </MDBox>
      ) : (
        <></>
      )}

      {missionStatus === STATUS.PROCESS_FAILED ? (
        <MDBox mb={1} display="flex" alignItems="center">
          <ConstructionIcon color="error" fontSize="small" sx={{ mx: 1 }} />
          <MDTypography variant="body2" fontWeight="bold" color="error">
            {/* TODO No Japanese here */}
            {`Process Failed. Please try again.`}
          </MDTypography>
        </MDBox>
      ) : (
        <></>
      )}
    </Card>
  );
}

// Typechecking props for the UploadMissionCard
UploadMissionCard.propTypes = {
  mission: PropTypes.shape({
    type: PropTypes.string,
    batchTotalBytes: PropTypes.number,
    // checkboxForm: PropTypes.object,
    checkboxForm: PropTypes.array,
    formState: PropTypes.object,
    issueCreatedAt: PropTypes.string,
    issueId: PropTypes.number,
    issueIdentifierId: PropTypes.string,
    missionId: PropTypes.string,
    status: PropTypes.oneOf([
      STATUS.INIT,
      STATUS.PROCESS_COMPLETE,
      STATUS.UPLOADING,
      STATUS.PROCESSING,
      STATUS.UPLOAD_FAILED,
      STATUS.PROCESS_FAILED,
    ]),
    structuredAttachments: PropTypes.array,
    timestamp: PropTypes.number,
    uploadedBytes: PropTypes.number,
    uploadingFileName: PropTypes.string,
    uploadingProgress: PropTypes.number,
    uploadingSpeed: PropTypes.number,
  }),
  setAbortUploadWarningModalIsOpen: PropTypes.func,
  setMissionIDToBeAborted: PropTypes.func,
  setMissionToBeRemoved: PropTypes.func,
};

export default UploadMissionCard;
