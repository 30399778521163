import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Modal } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import useStyles from './styles';
import { useMaterialUIController } from 'context';

import CustomSearchRender from 'components/CustomSearchRender';
import { TABLE_TYPE } from '../constants';
import { generateUserFullName } from 'shared/utils/name';
import { getIssuePriority } from 'shared/constants/issues';

const propTypes = {
  paidWageData: PropTypes.array.isRequired,
  paidWageDataKenshin: PropTypes.array.isRequired,
  setSelectedDoctorName: PropTypes.func.isRequired,
  setSelectedDoctorId: PropTypes.func.isRequired,
  setTableType: PropTypes.func.isRequired,
};

const DashboardPaidWageSummaryTable = ({
  paidWageData,
  paidWageDataKenshin,
  setSelectedDoctorName,
  setSelectedDoctorId,
  setTableType,
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  const { darkmode } = controller;

  const getSummaryTableData = (paidWageData, paidWageDataKenshin) => {
    const map1 = new Map();
    paidWageData.forEach((wage, index) => {
      const doctorId = wage.user.id;
      const totalWage = map1.get(doctorId);
      if (!totalWage) {
        const newTotalWage = {
          doctorName: generateUserFullName(
            wage.user.firstName,
            wage.user.lastName
          ),
          doctorId: doctorId,
          wageAmount: wage.currentWage ? wage.currentWage : wage.originalWage,
        };
        map1.set(doctorId, newTotalWage);
      } else {
        const newTotalWage = {
          doctorName: totalWage.doctorName,
          doctorId: doctorId,
          wageAmount: wage.currentWage
            ? totalWage.wageAmount + wage.currentWage
            : totalWage.wageAmount + wage.originalWage,
        };
        map1.set(doctorId, newTotalWage);
      }
    });

    paidWageDataKenshin.forEach((wage, index) => {
      const doctorId = wage.doctorId;
      const totalWage = map1.get(doctorId);
      if (!totalWage) {
        const newTotalWage = {
          doctorName: generateUserFullName(
            wage.doctorFirstName,
            wage.doctorLastName
          ),
          doctorId: doctorId,
          wageAmount: wage.currentWage ? wage.currentWage : wage.originalWage,
        };
        map1.set(doctorId, newTotalWage);
      } else {
        const newTotalWage = {
          doctorName: totalWage.doctorName,
          doctorId: doctorId,
          wageAmount: wage.currentWage
            ? totalWage.wageAmount + wage.currentWage
            : totalWage.wageAmount + wage.originalWage,
        };
        map1.set(doctorId, newTotalWage);
      }
    });

    const data = [];

    map1.forEach((value) => {
      const { doctorName, doctorId, wageAmount } = value;
      data.push([
        doctorName,
        wageAmount,
        {
          doctorName: doctorName,
          doctorId: doctorId,
        },
        {
          doctorName: doctorName,
          doctorId: doctorId,
        },
      ]);
    });
    return data;
  };

  const data = getSummaryTableData(paidWageData, paidWageDataKenshin);

  const exportToCSV = (doctorId, doctorName) => {
    const selectedPaidWageData = paidWageData.filter(
      (paidWage) => paidWage.user.id === doctorId
    );

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Doctor,Issue ID,Emergency,Check Item,Created At,Done At,Original Wage,Current Wage\n' +
      selectedPaidWageData
        .map((wage) => {
          const doctorName = generateUserFullName(
            wage.user.firstName,
            wage.user.lastName
          );
          const issueTitle = wage.issueTitle;
          // const emergencyText = wage.emergencyText;
          const emergencyText = getIssuePriority(wage.issuePriority);
          const checkItems = wage.issueCheckItems.join(';');
          const createdAt = wage.issueCreatedAt; // TODO: format time
          const doneAt = wage.createdAt;
          const originalWage = wage.originalWage;
          const currentWage =
            wage.currentWage === null ? wage.originalWage : wage.currentWage;
          return (
            doctorName +
            ',' +
            issueTitle +
            ',' +
            emergencyText +
            ',' +
            checkItems +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalWage +
            ',' +
            currentWage
          );
        })
        .join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `paid_wage-${doctorName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToCSVKenshin = (doctorId, doctorName) => {
    const selectedWageData = paidWageDataKenshin.filter(
      (wage) => wage.doctorId === doctorId
    );
    console.log(selectedWageData);

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Doctor, Case ID, Created At, Done At, Original Wage, Current Wage\n' +
      selectedWageData
        .map((wage) => {
          const doctorName = wage.doctorName;
          const caseIdentifierId = wage.caseIdentifierId;
          const createdAt = wage.caseCreatedAt;
          const doneAt = wage.createdAt;
          const originalWage = wage.originalWage;
          const currentWage =
            wage.currentWage === null ? wage.originalWage : wage.currentWage;
          return (
            doctorName +
            ',' +
            caseIdentifierId +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalWage +
            ',' +
            currentWage
          );
        })
        .join('\n');

    const encodeUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `paid_wage-to-${doctorName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: 'doctor_name',
      label: intl.get('paid_wage_label_column_doctor_name'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total_paid_wage',
      label: intl.get('paid_wage_label_column_total_paid_wage'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const totalPaidWage = value;
          return (
            <div>
              <p>￥{totalPaidWage}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'details',
      label: intl.get('paid_wage_label_column_details'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { doctorName, doctorId } = value;
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDoctorName(doctorName);
                  setSelectedDoctorId(doctorId);
                  setTableType(TABLE_TYPE.DETAILED);
                }}>
                {/* {intl.get('paid_wage_button_go_to_details')} */}
                {'精密検査'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDoctorName(doctorName);
                  setSelectedDoctorId(doctorId);
                  setTableType(TABLE_TYPE.DETAILED_KENSHIN);
                }}>
                {'健診'}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'download',
      label: intl.get('paid_wage_label_column_download'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { doctorId, doctorName } = value;
          return (
            <div>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log('click download pdf');
                }}>
                {intl.get('paid_wage_button_download_pdf')}
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSV(doctorId, doctorName);
                }}>
                {/* {intl.get('paid_wage_button_download_csv')} */}
                {'精密検査'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSVKenshin(doctorId, doctorName);
                }}>
                {'健診'}
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRow: 'none',
    filterType: 'textField',
    tableBodyMaxHeight: '950px',
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 25, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('paid_wage_text_paid_wage')}
        />
      );
    },
    tableLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          title={intl.get('paid_wage_text_paid_wage')}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </Fragment>
  );
};

DashboardPaidWageSummaryTable.propTypes = propTypes;

export default DashboardPaidWageSummaryTable;
