/**
 * Iteratively filters an array of data objects based on a sequence of filter conditions.
 * Each filter condition is applied in order, with each subsequent filter being applied
 * to the result of the previous filter, potentially reducing the dataset size with each step.
 *
 * @param {Object[]} data - The initial array of data objects to be filtered.
 * @param {Object[]} filters - An array of filter conditions, where each condition
 *                             specifies the `id` (property name) to filter on and
 *                             the `value` to match against.
 * @returns {Object[]} A new array of data objects that meet all the filter conditions.
 *                     If a data object's property value includes the filter value
 *                     for every filter condition, it is included in the returned array.
 *
 * @example:
 * Filters data for entries where the diagnostic result is 'DIAGNOSED_HEALTHY',
 * the modality is 'DX', and the doctor in charge includes 'yamamoto'.
 * filterData(allData, [
 *   { id: "diagnosticResult", value: "DIAGNOSED_HEALTHY" },
 *   { id: "modality", value: "DX" },
 *   { id: "doctorInCharge", value: "yamamoto" }
 * ]);
 */
export const filterData = (data, filters) => {
  return filters.reduce((filteredData, filter) => {
    return filteredData.filter((item) => {
      if (filter.id === 'index') {
        // compare numbers
        const itemValue = item[filter.id];
        const filterValue = filter.value;

        // If filterValue is [undefined, "5"]
        if (filterValue[0] === undefined && filterValue[1] !== undefined) {
          return itemValue <= Number(filterValue[1]);
        }

        // If filterValue is ["5", undefined]
        if (filterValue[0] !== undefined && filterValue[1] === undefined) {
          return Number(filterValue[0]) <= itemValue;
        }

        // If filterValue is like ["1", "5"]
        if (filterValue[0] !== undefined && filterValue[1] !== undefined) {
          return (
            Number(filterValue[0]) <= itemValue &&
            itemValue <= Number(filterValue[1])
          );
        }
      } else if (filter.id === 'doctorId') {
        const itemValue = item[filter.id];
        const filterValue = filter.value;
        return itemValue === filterValue;
      } else {
        // compare strings
        // console.log('filter: ', filter);
        const itemValue = String(item[filter.id]).toLowerCase();
        const filterValue = filter.value.toLowerCase();
        return itemValue.includes(filterValue);
      }
    });
  }, data);
};

/**
 * Creates an object with keys based on the 'index' attribute of each item in the input array.
 * Each key is mapped to `true`.
 *
 * @param {Object[]} data - The array of objects to process.
 * @returns {Object} An object with keys from the 'index' attribute of each item, each set to true.
 */
export const generateRowSelectionObject = (data) => {
  return data.reduce((acc, { index }) => ({ ...acc, [index]: true }), {});
};
