import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { PageLoader, PageError } from 'components';

import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { DoctorHomeDashboard } from './Doctor';
import { StaffOrManagerHomeDashboard } from './StaffOrManager';
import GeneralHomepage from './General';
import { USER_DATA, UserType } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import {
  isCurrentUserPicAsDoctor,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';

const DashboardHome = () => {
  const userType = localData.get(USER_DATA.USER_TYPE); // TODO: [Privileg] Clean
  const isPicAsDoctor = isCurrentUserPicAsDoctor();
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );

  const userFullName = getUserFullName();

  const [{ data, error }] = useApi.get(DOLPHIN_API.TODOS, {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { numNewInCharge, numNewAssigned, numNewReceived, numNewSendback } =
    data;

  return (
    <Card sx={{ margin: 3, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {intl.get('home_text_welcome_back')}
          </MDTypography>
          <MDTypography variant="h3">{userFullName}</MDTypography>
        </MDBox>
        <br />
        <br />
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDBox
            px={3}
            mb={5}
            width="80%"
            display="flex"
            justifyContent="space-around"
            alignItems="center">
            <GeneralHomepage
              isTaskManager={isCurrentUserTaskManager()}
              numNewReceived={numNewReceived}
              numNewSendback={numNewSendback}
              isPicAsDoctor={isCurrentUserPicAsDoctor()}
              numNewInCharge={numNewInCharge}
              numNewAssigned={numNewAssigned}
            />
            {/* {isPicAsDoctor === true ? ( // TODO: [Privilege] Make another home page for different privilege
              <DoctorHomeDashboard
                numberNewInCharge={numberNewInCharge}
                numNewAssigned={numNewAssigned}
              />
            ) : (
              <StaffOrManagerHomeDashboard
                numNewReceived={numNewReceived}
                numNewSendback={numNewSendback}
              />
            )} */}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default withRouter(DashboardHome);
