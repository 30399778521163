import intl from 'react-intl-universal';
// export const tagIntlValues = {
//   CT1: '冠動脈＋心エコー',
//   CT2: '冠動脈',
//   CT3: '大動脈',
//   CT4: '胸部',
//   CT5: '腹部',
//   CT6: '肺',
//   CT7: '造影',
//   CT8: '非造影',
//   CT9: '腎動脈',
//   CT10: 'その他',
//   MRI1: '心臓',
//   MRI2: '非造影',
//   MRI3: '造影',
//   MRI4: '一任',
//   MRI5: '要',
//   MRI6: '不要',
//   MRI7: '一任',
//   MRI8: '脳 (MRAを含む)',
//   MRI9: '大動脈',
//   MRI10: '胸部',
//   MRI11: '腹部',
//   MRI12: '腎動脈',
//   MRI13: '下肢動脈・静脈',
//   MRI14: 'その他',
//   PhyExam1: '心エコー',
//   PhyExam2: '頸動脈エコー',
//   PhyExam3: 'ABI',
//   PhyExam4: 'ホルター心電図',
//   MRICor: '冠動脈MRAによる狭窄評価',
// };

export const tagIntlValues = {
  CT1: intl.get('case_tag_value_ct_1'),
  CT2: intl.get('case_tag_value_ct_2'),
  CT3: intl.get('case_tag_value_ct_3'),
  CT4: intl.get('case_tag_value_ct_4'),
  CT5: intl.get('case_tag_value_ct_5'),
  CT6: intl.get('case_tag_value_ct_6'),
  CT7: intl.get('case_tag_value_ct_7'),
  CT8: intl.get('case_tag_value_ct_8'),
  CT9: intl.get('case_tag_value_ct_9'),
  CT10: intl.get('case_tag_value_ct_10'),
  MRI1: intl.get('case_tag_value_mri_1'),
  MRI2: intl.get('case_tag_value_mri_2'),
  MRI3: intl.get('case_tag_value_mri_3'),
  MRI4: intl.get('case_tag_value_mri_4'),
  MRI5: intl.get('case_tag_value_mri_5'),
  MRI6: intl.get('case_tag_value_mri_6'),
  MRI7: intl.get('case_tag_value_mri_7'),
  MRI8: intl.get('case_tag_value_mri_8'),
  MRI9: intl.get('case_tag_value_mri_9'),
  MRI10: intl.get('case_tag_value_mri_10'),
  MRI11: intl.get('case_tag_vaule_mri_11'),
  MRI12: intl.get('case_tag_value_mri_12'),
  MRI13: intl.get('case_tag_value_mri_13'),
  MRI14: intl.get('case_tag_value_mri_14'),
  PhyExam1: intl.get('case_tag_value_phy_exam_1'),
  PhyExam2: intl.get('case_tag_value_phy_exam_2'),
  PhyExam3: intl.get('case_tag_value_phy_exam_3'),
  PhyExam4: intl.get('case_tag_value_phy_exam_4'),
  MRICor: intl.get('case_tag_value_mri_cor'),
};

// These dictionary will contain the relationships for the tags to work
export const tagRelationships = {
  CT3: 'CTGroup1',
  CT4: 'CTGroup1',
  CT5: 'CTGroup1',
  CT6: 'CTGroup2',
  CT7: 'CTGroup2',
  CT8: 'CTGroup2',
  MRI1: 'MRIGroup1',
  MRI2: 'MRIGroup1',
  MRI3: 'MRIGroup1',
  MRI4: 'MRIGroup1',
  MRI5: 'MRIGroup2',
  MRI6: 'MRIGroup2',
  MRI7: 'MRIGroup2',
  MRI9: 'MRIGroup3',
  MRI10: 'MRIGroup3',
  MRI11: 'MRIGroup3',
};
