import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon, Button } from 'components';
import { SectionTitle } from '../Styles';
import { User, Username, Quota } from './Styles';
import { withRouter } from 'react-router-dom';

import { UserContextMenu } from 'components/Menu';

import {
  IssueState,
  IssueStatus,
  getIssueStatusForClient,
} from 'shared/constants/issues';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { LANGUAGE, UserType } from 'shared/constants/users';
import { UserProptypes } from 'shared/propTypes/userType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

const propTypes = {
  parentIssue: PropTypes.object,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const ProjectBoardIssueDetailsParentIssue = ({
  parentIssue,
  match,
  history,
}) => {
  return (
    <Fragment>
      <SectionTitle>{intl.get('issue_details_text_parent_issue')}</SectionTitle>
      <div>
        <Button
          variant="success"
          sx={{ justifyContent: 'start' }}
          onClick={() => {
            history.push(
              `/task-management/task-management-regular/issues/${parentIssue.id}`
            );
          }}>{`${parentIssue.title} (${getIssueStatusForClient(
          parentIssue.status
        )})`}</Button>
      </div>
    </Fragment>
  );
};

ProjectBoardIssueDetailsParentIssue.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetailsParentIssue);
