import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

import { color, font, mixin } from 'shared/utils/styles';

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 18px 0;
`;

export const Title = styled.div`
  padding: 20px 0 6px;
  ${font.size(15)}
  ${font.medium}
`;

export const Content = styled.div`
  display: flex;
  padding: 0 30px 60px;
`;

export const Left = styled.div`
  width: 100%;
  padding-right: 50px;
`;

export const Actions = styled.div`
  display: flex;
  padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;

export const SwitchArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
