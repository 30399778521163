import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Grid, Tab, Tabs } from '@material-ui/core';
import ClientSettings from './ClientSettings';
import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import { OrganizationProptypes } from 'shared/propTypes/organizationType';

const propTypes = {
  organizationInfo: PropTypes.shape(OrganizationProptypes),
};

const DashboardOrganizationSettingsClientManagement = ({
  organizationInfo,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const tabStyle = {
    color: darkMode ? 'white' : 'grey',
    maxWidth: '100%',
  };
  const [value, setValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const quotaSrcOrgs = [...organizationInfo.quotaSrcOrgs].sort((a, b) => {
    return a.srcOrgName < b.srcOrgName ? -1 : 1;
  });
  const priceSrcOrgs = [...organizationInfo.priceSrcOrgs];

  // TODO: sort quotaSrcOrgs

  return (
    <Grid item xs={12}>
      <MDBox sx={{ borderBottom: 1, borderColor: 'divider', width: '45vw' }}>
        <Tabs
          style={tabStyle}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto">
          {quotaSrcOrgs.map((quotaOrg, index) => {
            return (
              <Tab
                label={quotaOrg.srcOrg.name}
                {...a11yProps(index)}
                key={index}
              />
            );
          })}
        </Tabs>
      </MDBox>
      {quotaSrcOrgs.length > 0 && (
        <MDBox sx={{ p: 3 }}>
          <ClientSettings
            quotaSrcOrg={quotaSrcOrgs[value]}
            priceSrcOrgs={priceSrcOrgs.filter(
              (price) => price.srcOrgId === quotaSrcOrgs[value].srcOrgId
            )}
          />
        </MDBox>
      )}
    </Grid>
  );
};

DashboardOrganizationSettingsClientManagement.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsClientManagement);
