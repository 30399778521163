import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useStyles } from './styles';

// TODO Object to shape
const propTypes = {
  setModalInvoiceInfoOpen: PropTypes.func.isRequired,
  setModalInvoiceOpen: PropTypes.func.isRequired,
  setInvoiceIssueDate: PropTypes.func.isRequired,
  setInvoiceDueDate: PropTypes.func.isRequired,
  setInvoiceRemarksContent: PropTypes.func.isRequired,
};

const DashboardBillingInvoiceInfo = ({
  setModalInvoiceInfoOpen,
  setModalInvoiceOpen,
  setInvoiceIssueDate,
  setInvoiceDueDate,
  setInvoiceRemarksContent,
}) => {
  const classes = useStyles();

  const [issueDate, setIssueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [remarksContent, setRemarksContent] = useState('');

  const handleIssueClick = () => {
    setInvoiceIssueDate(issueDate);
    setInvoiceDueDate(dueDate);
    setInvoiceRemarksContent(remarksContent);
    setModalInvoiceInfoOpen(false);
    setModalInvoiceOpen(true);
  };

  const handleCancelClick = () => {
    setModalInvoiceInfoOpen(false);
  };

  return (
    <Fragment>
      <Paper className={classes.paper} elevation={10}>
        <Grid columns={{ xs: 12 }} container className={classes.grid}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={intl.get('billing_invoice_info_label_issue_date')}
              value={issueDate}
              onChange={(newValue) => {
                setIssueDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid columns={{ xs: 12 }} container className={classes.grid}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={intl.get('billing_invoice_info_label_due_date')}
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid columns={{ xs: 12 }} container className={classes.grid}>
          <TextField
            variant="outlined"
            multiline
            size="small"
            maxRows={10}
            label={intl.get('billing_invoice_info_label_remarks')}
            value={remarksContent}
            onChange={(event) => {
              setRemarksContent(event.target.value);
            }}
          />
        </Grid>
        <Grid columns={{ xs: 12 }} container>
          <Grid xs={6} className={classes.buttonLeft}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              onClick={handleIssueClick}>
              {intl.get('billing_invoice_info_button_issue')}
            </Button>
          </Grid>
          <Grid xs={6} className={classes.buttonRight}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              onClick={handleCancelClick}>
              {intl.get('billing_invoice_info_button_cancel')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

DashboardBillingInvoiceInfo.propTypes = propTypes;

export default withRouter(DashboardBillingInvoiceInfo);
