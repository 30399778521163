import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Badge from '@mui/material/Badge';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';

import KrillChat from 'krill';

const propTypes = {
  tableType: PropTypes.string,
  setTableType: PropTypes.func,
  recvFriendApplicationList: PropTypes.array,
  sentFriendApplicationList: PropTypes.array,
};

const DashboardContactsFriendsCustomizedTitle = ({
  tableType,
  setTableType,
  recvFriendApplicationList,
  sentFriendApplicationList,
}) => {
  const newCountForReceived = recvFriendApplicationList.filter(
    (application) => application.handleResult === 0
  ).length;
  const newCountForSent = 0; // TODO: Make a better way to calculate
  return (
    <div>
      <ButtonGroup>
        <Button
          // color={tableType === 'friends' ? 'primary' : 'success'}
          variant={tableType === 'friends' ? 'contained' : 'outlined'}
          startIcon={<GroupIcon />}
          onClick={() => {
            setTableType('friends');
          }}>
          {intl.get('friends_button_friend_list')}
        </Button>
        <Button
          // color={tableType === 'receivedApplication' ? 'primary' : 'success'}
          variant={
            tableType === 'receivedApplication' ? 'contained' : 'outlined'
          }
          startIcon={
            <Badge badgeContent={newCountForReceived} color="success">
              <EmailIcon />
            </Badge>
          }
          onClick={() => {
            // setNewCountForReceived(0);
            setTableType('receivedApplication');
          }}>
          {intl.get('friends_button_received_application')}
        </Button>
        <Button
          // color={tableType === 'sentApplication' ? 'primary' : 'success'}
          variant={tableType === 'sentApplication' ? 'contained' : 'outlined'}
          startIcon={
            <Badge badgeContent={newCountForSent} color="success">
              <SendIcon />
            </Badge>
          }
          onClick={() => {
            // setNewCountForSent(0);
            setTableType('sentApplication');
          }}>
          {intl.get('friends_button_sent_application')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

DashboardContactsFriendsCustomizedTitle.propTypes = propTypes;

export default DashboardContactsFriendsCustomizedTitle;
