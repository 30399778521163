import React, { Fragment, useState } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import MDButton from 'components/MDButton';
import pxToRem from 'assets/theme/functions/pxToRem';
import MDTypography from 'components/MDTypography';

import { useMaterialUIController } from 'context';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormLabel } from '@mui/material';
import { Button } from 'components';

import Autocomplete from '@mui/material/Autocomplete';
import { Actions, FirstAction } from './Styles';

import { OrganizationProptypes } from 'shared/propTypes/organizationType';
import TagInputs from './TagInputs';

const propTypes = {
  organizationInfo: PropTypes.shape(OrganizationProptypes),
  newTemplateJson: PropTypes.object.isRequired,
  setNewTemplateJson: PropTypes.func.isRequired,
};

export const NewTag = ({
  organizationInfo,
  newTemplateJson,
  setNewTemplateJson,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [formData, setFormData] = useState({
    label: '',
    org: '',
    note: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const handleInputChange = (event, newInputValue) => {
    setFormData({ ...formData, org: newInputValue });
  };

  const isObjectEmpty = (objectName) => {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };

  const organizations = organizationInfo.quotaSrcOrgs.map(
    (obj) => obj.dstOrgName
  );

  const createNewTempJson = (formData) => {
    let template = {
      id: 0,
      total_cnt: 0,
      label: formData.label,
      org: formData.org,
      type: 'text',
      value: null,
      children_type: 'text',
      children: [],
      note: formData.note,
      price: 0,
      checked: false,
    };
    setNewTemplateJson(template);
  };

  const updateTempJson = (formData, template) => {
    template.label = formData.label;
    template.note = formData.note;
    template.org = formData.org;
    setNewTemplateJson(template);
  };

  const handleCancelTemplateBtnClick = () => {
    setNewTemplateJson({});
  };

  const handleAddSubtagBtnClick = (count, formJson) => {
    createSettingSubJson(count, formJson);
  };

  const handleAddTagBtnClick = (count, children) => {
    createSettingJson(count, children);
  };

  const handleDeleteTagBtnClick = (tags, tag) => {
    let idx = tags.indexOf(tag);
    tags.splice(idx, 1);
    setNewTemplateJson({ ...newTemplateJson });
  };

  const createSettingSubJson = (count, formJson) => {
    count += 1;
    newTemplateJson.total_cnt = count;
    let newTag = {
      id: count,
      label: '',
      type: 'new',
      value: null,
      children_type: 'new',
      children: [],
      note: '',
      price: 0,
      checked: false,
    };
    formJson.children.push(newTag);
    setNewTemplateJson({ ...newTemplateJson });
  };

  const createSettingJson = (count, children) => {
    count += 1;
    newTemplateJson.total_cnt = count;
    let newTag = {
      id: count,
      label: '',
      type: 'new',
      value: null,
      children_type: 'new',
      children: [],
      note: '',
      price: 0,
      checked: false,
    };
    children.push(newTag);
    setNewTemplateJson({ ...newTemplateJson });
  };

  const renderFormJsonRoot = (formJson) => {
    return (
      <>
        <Grid item xs={12}>
          タグ設定
        </Grid>
        {recursiveRenderFormJsonChildren(formJson, 0, 0)}
      </>
    );
  };

  const recursiveRenderFormJsonChildren = (formJson, level, count) => {
    return (
      <>
        <Grid item container xs={12} spacing={2}>
          {renderNestedTags(
            formJson.children,
            formJson.children_type,
            formJson.note,
            0
          )}
        </Grid>
        <Grid xs={12} item>
          <FirstAction>
            <Button
              variant="primary"
              onClick={() =>
                handleAddSubtagBtnClick(newTemplateJson.total_cnt, formJson)
              }>
              Add Tag
            </Button>
            <Button variant="danger" onClick={handleCancelTemplateBtnClick}>
              Cancel Template
            </Button>
          </FirstAction>
        </Grid>
      </>
    );
  };

  const renderNestedTags = (tags, type, note, level) => {
    if (tags && tags.length > 0) {
      switch (type) {
        case 'text':
          return (
            <>
              {tags.map((tag) => {
                return (
                  <Grid
                    xs={12}
                    item
                    container
                    key={tag.id}
                    sx={{ marginTop: '10px' }}>
                    {level > 0 && <Grid xs={1} item></Grid>}
                    <Grid xs={11} item container key={tag.id}>
                      {tag.type === 'text' && level === 0 && (
                        <MDTypography varient="h0">{tag.label}</MDTypography>
                      )}
                      {tag.type === 'text' && level > 0 && (
                        <MDTypography sx={{ fontSize: '0.8em' }}>
                          {tag.label}
                        </MDTypography>
                      )}
                      {tag.type === 'input' && (
                        <MDTypography varient="h1">
                          {tag.label}
                          <TextField
                            id={tag.id}
                            variant="standard"
                            value={tag.value}
                          />
                        </MDTypography>
                      )}
                      {tag.type === 'new' && (
                        <Grid xs={12} item container spacing={2}>
                          <TagInputs
                            newTemplateJson={newTemplateJson}
                            setNewTemplateJson={setNewTemplateJson}
                            tag={tag}
                          />
                        </Grid>
                      )}
                      {tag.type !== 'new' && (
                        <Actions>
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleAddSubtagBtnClick(
                                newTemplateJson.total_cnt,
                                tag
                              )
                            }>
                            Add Subtag
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteTagBtnClick(tags, tag)}>
                            Cancel Tag
                          </Button>
                        </Actions>
                      )}
                      {tag.children &&
                        renderNestedTags(
                          tag.children,
                          tag.children_type,
                          tag.note,
                          level + 1
                        )}
                    </Grid>
                  </Grid>
                );
              })}
            </>
          );
        case 'single':
          return (
            <Grid item container xs={12}>
              {level > 0 && <Grid xs={1} item></Grid>}
              <Grid xs={11} item container spacing={2}>
                <Grid item container xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel id="test"></FormLabel>
                    <RadioGroup
                      aria-labelledby="test"
                      name="radio-buttons-group">
                      {tags.map((tag) => {
                        return (
                          <Grid
                            xs={12}
                            item
                            container
                            key={tag.id}
                            sx={{ marginTop: '10px' }}>
                            {tag.type !== 'new' && (
                              <Fragment key={tag.id}>
                                <FormControlLabel
                                  value={tag.label}
                                  control={<Radio checked={tag.checked} />}
                                  label={tag.label}
                                />
                                {tag.type === 'input' && (
                                  <TextField
                                    id={tag.id}
                                    variant="standard"
                                    value={tag.value}
                                  />
                                )}
                                <Actions>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleAddSubtagBtnClick(
                                        newTemplateJson.total_cnt,
                                        tag
                                      )
                                    }>
                                    Add Subtag
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteTagBtnClick(tags, tag)
                                    }>
                                    Cancel Tag
                                  </Button>
                                </Actions>
                                {tag.children &&
                                  renderNestedTags(
                                    tag.children,
                                    tag.children_type,
                                    tag.note,
                                    level + 1
                                  )}
                              </Fragment>
                            )}
                          </Grid>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {tags[tags.length - 1].type === 'new' && (
                  <TagInputs
                    newTemplateJson={newTemplateJson}
                    setNewTemplateJson={setNewTemplateJson}
                    tag={tags[tags.length - 1]}
                  />
                )}
              </Grid>
            </Grid>
          );
        case 'multi':
          return (
            <Grid item container xs={12}>
              {level > 0 && <Grid xs={1} item></Grid>}
              <Grid xs={11} item container spacing={2}>
                <Grid item container xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {tags.map((tag) => {
                        return (
                          <>
                            {tag.type !== 'new' && (
                              <Grid xs={12} item container alignItems="center">
                                <FormControlLabel
                                  style={{
                                    color: darkMode ? 'white' : 'black',
                                  }}
                                  control={
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon
                                          style={{ fill: '#66ccff' }}
                                        />
                                      }
                                      checked={tag.checked}
                                    />
                                  }
                                  label={tag.label}
                                />
                                {tag.type === 'input' && (
                                  <TextField
                                    id={tag.id}
                                    variant="standard"
                                    value={tag.value}
                                  />
                                )}
                                <Actions>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleAddSubtagBtnClick(
                                        newTemplateJson.total_cnt,
                                        tag
                                      )
                                    }>
                                    Add Subtag
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteTagBtnClick(tags, tag)
                                    }>
                                    Cancel Tag
                                  </Button>
                                </Actions>
                                {tag.children &&
                                  renderNestedTags(
                                    tag.children,
                                    tag.children_type,
                                    tag.note,
                                    level + 1
                                  )}
                              </Grid>
                            )}
                          </>
                        );
                      })}
                    </FormGroup>
                    {note && (
                      <MDTypography variant="body2">{note}</MDTypography>
                    )}
                  </FormControl>
                </Grid>
                {tags[tags.length - 1].type === 'new' && (
                  <TagInputs
                    newTemplateJson={newTemplateJson}
                    setNewTemplateJson={setNewTemplateJson}
                    tag={tags[tags.length - 1]}
                  />
                )}
              </Grid>
            </Grid>
          );
        default:
          console.log('invalid type!'); // Keep this
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        テンプレート設定
      </Grid>
      <Grid xs={3} item>
        <FormControl fullWidth>
          <TextField
            fullWidth
            required
            variant="standard"
            name="label"
            label="Template Name"
            onChange={handleChange}
            value={formData.label}
          />
        </FormControl>
      </Grid>
      <Grid xs={3} item>
        <FormControl fullWidth>
          <Autocomplete
            name="org"
            sx={{ marginTop: '4px' }}
            options={organizations}
            inputValue={formData.org}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField {...params} label="Orgnization" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid xs={4} item>
        <FormControl fullWidth>
          <TextField
            fullWidth
            variant="standard"
            name="note"
            label="Note"
            onChange={handleChange}
            value={formData.note}
          />
        </FormControl>
      </Grid>
      <Grid xs={2} item>
        {isObjectEmpty(newTemplateJson) && (
          <MDButton
            variant="gradient"
            color="info"
            style={{
              color: 'white',
              fontSize: pxToRem(18),
              marginTop: '5px',
              marginBottom: '45px',
              width: '100%',
            }}
            onClick={() => createNewTempJson({ ...formData })}>
            Add
          </MDButton>
        )}
        {!isObjectEmpty(newTemplateJson) && (
          <MDButton
            variant="gradient"
            color="info"
            style={{
              color: 'white',
              fontSize: pxToRem(18),
              marginTop: '5px',
              marginBottom: '45px',
              width: '100%',
            }}
            onClick={() => updateTempJson({ ...formData }, newTemplateJson)}>
            Update
          </MDButton>
        )}
      </Grid>
      {!isObjectEmpty(newTemplateJson) && renderFormJsonRoot(newTemplateJson)}
    </Grid>
  );
};

NewTag.propTypes = propTypes;
