import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';

import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { PageError, PageLoader } from 'components';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const DashboardBrowse = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentOrganizationId = localData.get('currentOrganizationId');
  const issueId = match.params.issueId;

  const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
    `/api/issues/${issueId}`,
    {
      params: { organizationId: currentOrganizationId },
    }
  );

  if (error && error.status === 404) history.push('/home');
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { issue } = data;
  const srcOrgId = issue.srcOrgId.toString();
  const dstOrgId = issue.dstOrgId.toString();
  const isSender = srcOrgId === currentOrganizationId;
  const isReceiver = dstOrgId === currentOrganizationId;

  if (isSender) {
    history.push(`/history/sent/issues/${issueId}`);
  } else if (isReceiver) {
    history.push(`/history/received/issues/${issueId}`);
  }

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <div>You Do Not Have Access to This Issue!</div>
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardBrowse.propTypes = propTypes;

export default withRouter(DashboardBrowse);
