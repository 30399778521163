/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// react-router components
import { useLocation, useHistory } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import EmailIcon from '@mui/icons-material/Email';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import Breadcrumbs from 'pages/parts/Breadcrumbs';
import NotificationItem from 'pages/parts/Items/NotificationItem';

// self developed api
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import {
  IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
} from 'shared/constants/issues';
import { NotificationType } from 'shared/constants/notifications';
import intl from 'react-intl-universal';

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton } from './styles';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setDarkMode } from 'context';
import { DOLPHIN_API } from 'shared/constants/apis';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';

//! Need to use intl
function DashboardNavbar({ absolute, light, isMini }) {
  // states
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [notifications, setNotifications] = useState();
  const [numNewNotifications, setNumNewNotifications] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const route = useLocation().pathname.split('/').slice(1);
  const history = useHistory();

  // increment value of `refresh` per specified time period (default is 60 seconds)
  const PERIOD_MS = process.env.REACT_APP_NOTIFICATION_FETCH_PERIOD_MS || 60000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (process.env.REACT_APP_AUTH_SERVER === 'aws-cognito') {
        setRefresh((refresh) => refresh + 1);
      }
    }, PERIOD_MS);
    return () => clearInterval(interval); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
    };
    api.get(DOLPHIN_API.NOTIFICATIONS, apiVariables).then(async (data) => {
      const notifications = data.notifications;
      setNotifications(notifications);
      setNumNewNotifications(
        notifications.filter((notification) => notification.clicked === false)
          .length
      );
    });
  }, [refresh]);

  const handleOpenNotificationMenu = (event) =>
    setOpenNotificationMenu(event.currentTarget);
  const handleCloseNotificationMenu = () => setOpenNotificationMenu(false);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const handleMenuItemClick = (event, index) => {
    const ids =
      index === -1
        ? notifications.map((notification, index) => notification.id)
        : [notifications[index].id];

    const variables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
      data: {
        username: localData.get(USER_DATA.USERNAME),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID), // todo: delete if not used;
        type: 'MARK_CLICKED',
        ids: ids,
      },
    };
    api.put(DOLPHIN_API.NOTIFICATIONS, variables).then(async (data) => {
      // if succeed, update local
      if (data.message === 'ok') {
        const newNotifications = [...notifications];
        if (index === -1) {
          newNotifications.forEach((notification) => {
            notification.clicked = true;
          });
        } else {
          newNotifications[index].clicked = true;
        }
        setNotifications([...newNotifications]);
        setNumNewNotifications(
          newNotifications.filter((item) => item.clicked === false).length
        );
      }
    });
    if (index !== -1) {
      history.push({ pathname: notifications[index].link });
      setOpenNotificationMenu(false);
    }
  };

  const generateNotificationText = (notificationType, notificationContents) => {
    let text = '';

    if (localData.get(USER_DATA.PREFFERED_LANGUAGE) === LANGUAGE.ENGLISH) {
      switch (notificationType) {
        case NotificationType.NEW_ISSUE: {
          text = `${notificationContents[0]} sent a new case ${notificationContents[1]} to you.`;
          break;
        }
        case NotificationType.NEW_COMMENT: {
          text = `${notificationContents[0]} ${notificationContents[1]} ${
            notificationContents[2] === 'REPORT'
              ? 'submitted a report'
              : 'made a comment'
          } in ${notificationContents[3]}`;
          break;
        }
        case NotificationType.ASSIGN_DOCTOR_IN_CHARGE: {
          text = `You are assigned a new case: ${notificationContents[0]}.`;
          break;
        }
        case NotificationType.ASSIGN_DOCTOR: {
          text = `You are assigned a new case: ${notificationContents[0]}.`;
          break;
        }
        case NotificationType.RELEASE_DOCTOR: {
          text = `You are released from duty of case: ${notificationContents[0]}.`;
          break;
        }
        case NotificationType.STATE_CHANGE: {
          text = `There is a problem in ${notificationContents[0]}`;
          break;
        }
        case NotificationType.STATUS_CHANGE: {
          if (notificationContents[1] === IssueStatus.CHECKREADY) {
            text = `${
              notificationContents[0]
            }'s status changed into ${getIssueStatusForClient(
              IssueStatus.REPORTREADY
            )}`;
          } else if (notificationContents[1] === IssueStatus.REPORTREADY) {
            text = `${
              notificationContents[0]
            }'s status changed into ${getIssueStatus(IssueStatus.REPORTREADY)}`;
          } else if (notificationContents[1] === IssueStatus.SENDBACK) {
            text = `${
              notificationContents[0]
            }'s status changed into ${getIssueStatusForClient(
              IssueStatus.REPORTREADY
            )}`;
          } else if (notificationContents[1] === IssueStatus.DONE) {
            text = `${
              notificationContents[0]
            }'s status changed into ${getIssueStatus(IssueStatus.DONE)}`;
          }
          break;
        }
        case NotificationType.BILLING_QUESTION: {
          text = `There is a problem with ${notificationContents[0]}'s billing`;
          break;
        }
        default: {
          break;
        }
      }
    } else if (localData.get('userPreferredLanguage') === 'ja-JP') {
      switch (notificationType) {
        case NotificationType.NEW_ISSUE: {
          text = `${notificationContents[0]}から新しい依頼が届きました：${notificationContents[1]}`;
          break;
        }
        case NotificationType.NEW_COMMENT: {
          text = `${notificationContents[1]} ${notificationContents[0]}が${
            notificationContents[3]
          }に${
            notificationContents[2] === 'REPORT'
              ? 'レポートを投稿しました'
              : 'コメントしました'
          }`;
          break;
        }
        case NotificationType.ASSIGN_DOCTOR_IN_CHARGE: {
          text = `${notificationContents[0]}があなたに割り当てられました`;
          break;
        }
        case NotificationType.ASSIGN_DOCTOR: {
          text = `${notificationContents[0]}があなたに割り当てられました`;
          break;
        }
        case NotificationType.RELEASE_DOCTOR: {
          text = `あなたの割り当てがリムーブされました: ${notificationContents[0]}`;
          break;
        }
        case NotificationType.STATE_CHANGE: {
          text = `${notificationContents[0]}のステータスが「要確認」に変更されました`;
          break;
        }
        case NotificationType.STATUS_CHANGE: {
          if (notificationContents[1] === IssueStatus.CHECKREADY) {
            text = `以下のご依頼の受付をお知らせいたします：${notificationContents[0]}`;
          } else if (notificationContents[1] === IssueStatus.REPORTREADY) {
            text = `${notificationContents[0]}のレポートをご確認ください`;
          } else if (notificationContents[1] === IssueStatus.SENDBACK) {
            text = `以下のご依頼の返信をお知らせいたします：${notificationContents[0]}`;
          } else if (notificationContents[1] === IssueStatus.DONE) {
            text = `以下のご依頼のレポート受領をお知らせいたします：${notificationContents[0]}`;
          }
          break;
        }
        case NotificationType.BILLING_QUESTION: {
          text = `${notificationContents[0]}の請求に問題が発生しました。`;
          break;
        }
        default: {
          break;
        }
      }
    }
    return text;
  };

  // TODO: not working; need to come back;
  const textColor = (darkMode, clicked) => {
    let colorValue;
    if (darkMode) {
      colorValue = `${clicked ? 'text' : 'light'}`;
    } else {
      colorValue = clicked ? 'text' : 'info';
    }
    return colorValue;
  };

  const generateNotificationsList = (notifications) => {
    const notificationsList = notifications.map((notification, index) => {
      const indexAlt = index + Date.now();
      return (
        <NotificationItem
          key={indexAlt}
          darkMode={darkMode}
          textColor={textColor}
          icon={notification.clicked ? <MarkAsUnreadIcon /> : <EmailIcon />}
          clicked={notification.clicked}
          text={generateNotificationText(
            notification.type,
            JSON.parse(notification.contents)
          )}
          index={index}
          handleMenuItemClick={handleMenuItemClick}
        />
      );
    });

    if (notificationsList.length > 0) {
      notificationsList.push(
        <NotificationItem
          key={notificationsList.length + 1}
          icon={<MarkAsUnreadIcon />}
          clicked={false}
          text={intl.get('notification_list_button_mark_all_as_read')}
          index={-1}
          handleMenuItemClick={handleMenuItemClick}
        />
      );
    } else {
      notificationsList.push(
        <NotificationItem
          key={notificationsList.length + 1}
          clicked={true}
          text={intl.get('notification_list_text_no_new_notifications')}
          index={-2}
        />
      );
    }
    return notificationsList;
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openNotificationMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openNotificationMenu)}
      onClose={handleCloseNotificationMenu}
      sx={{ mt: 2 }}
      PaperProps={{
        style: {
          width: 500,
          maxHeight: 600,
        },
      }}>
      {notifications && generateNotificationsList(notifications)}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: `${darkMode ? 'white' : 'black'} !important`,
  });

  return (
    <AppBar
      position="static"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      } // defines the color/style of the navbar
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" route={route} />
        </MDBox>
        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox color={light ? 'white' : 'inherit'}>
            {/* <IconButton
              size="medium"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleDarkMode}>
              <DarkModeIcon sx={iconsStyle} />
            </IconButton> */}

            <IconButton
              size="medium"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenNotificationMenu}>
              <MDBadge
                badgeContent={numNewNotifications}
                color="error"
                size="xs"
                circular>
                <Icon sx={iconsStyle}>notifications</Icon>
              </MDBadge>
            </IconButton>

            {renderMenu()}
          </MDBox>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
