import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { EventType } from '@azure/msal-browser';
// import { msalConfig } from 'configs/azureAuthConfig';
import { msalInstance } from 'shared/utils/azureB2C';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals'; // to be used later
import CornerstoneService from './services/CornerstoneService';
import { initLocale, LANGUAGE_CODE } from 'shared/utils/intl';
import { MaterialUIControllerProvider } from './context';
import { browserStorage } from 'shared/utils/storage';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
    storage: browserStorage,
    authenticationFlowType: 'CUSTOM_AUTH', // 'CUSTOM_AUTH' | 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH'
  },
});

CornerstoneService.initCornerstone();

// Init intl to the default language Japanese.
initLocale(LANGUAGE_CODE.JAPANESE);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      {process.env.REACT_APP_SIGN_IN_PAGE === 'azure-adb2c' ? (
        <App instance={msalInstance} />
      ) : (
        <App />
      )}
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// To be used later
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
