import moment from 'moment-timezone';

export const formatDate = (date, format = 'MMMM D, YYYY') =>
  date ? moment(date).format(format) : date;

export const formatDateTime = (date, format = 'MMMM D, YYYY, h:mm A') =>
  date ? moment(date).format(format) : date;

export const formatDateTimeForAPI = (date) =>
  date ? moment(date).utc().format() : date;

export const formatDateTimeConversational = (date) =>
  date ? moment(date).fromNow() : date;

/**
 * Get the first date of the same month with the input date
 * @param {Date} date
 */

export const getFirstDateOfTheSameMonth = (date) => {
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

  // Set the time to 0
  firstDate.setHours(0, 0, 0, 0);

  return firstDate;
};

/**
 *  Get the date object of first day of previous month relative to the specified current date;
 *  @param {Date} currentDate current date object
 *
 */
export const getFirstDayPreviousMonth = (currentDate) => {
  const firstDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const firstDayPreviousMonth = new Date(
    firstDayCurrentMonth.getFullYear(),
    firstDayCurrentMonth.getMonth() - 1,
    1
  );
  // Setting the time to the moment of 00:00:00;
  firstDayPreviousMonth.setHours(0, 0, 0, 0);

  return firstDayPreviousMonth;
};

/**
 * Converts a UTC date string to a readable Japanese date format.
 *
 * @param {string} dateString - The UTC date string to be converted, e.g., '2024-01-10T10:29:17.054Z'.
 * @returns {string} The date formatted in a readable Japanese format, e.g., '2024年01月10日 19時29分17秒'.
 */
export const readableJapaneseDateFormat = (dateString) => {
  const dateInTokyo = moment(dateString).tz('Asia/Tokyo');
  // const formattedDate = dateInTokyo.format('YYYY-MM-DD HH時mm分');
  const formattedDate = dateInTokyo.format('YYYY年MM月DD日 HH時mm分');
  return formattedDate;
};
