import { Fragment } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from './Chip';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import localData from 'shared/utils/localData';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { IconButton } from '@mui/material';
import api from 'shared/utils/api';
import { axiosForS3PresignedUrl } from 'shared/utils/axiosInstance';
// import { InvalidFileExtensions, InvalidFileTypeNames } from 'shared/constants/invalidFileTypes';
import { Tags } from 'shared/constants/tags';
import { formatBytes } from 'shared/utils/misc';
import { FILE_CHUNK_SIZE, uploadParts } from './S3MultipartUpload';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import {
  setUploadingFileNameContext,
  setUploadingProgressContext,
  setUploadedBytesContext,
  setUploadControllerContext,
} from 'context/NewIssueContext';
import parse from 'html-react-parser';

import {
  getStructuredFiles,
  flattenStructuredAttachmentsObject,
} from 'shared/utils/fileHandling';
import { FILE_TYPE } from 'shared/constants/file';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: '1rem !important',
    fontWeight: '300 !important',
  },
}));

// Icons
// import FileImage from 'assets/images/icons/file.png';
//<a href="[https://www.flaticon.es/iconos-gratis/documento](https://www.flaticon.es/iconos-gratis/documento)" title="documento iconos">Documento iconos creados por Tomas Knop - Flaticon</a>
// import FolderImage from 'assets/images/icons/folder.png';
//<a href="[https://www.flaticon.es/iconos-gratis/carpeta](https://www.flaticon.es/iconos-gratis/carpeta)" title="carpeta iconos">Carpeta iconos creados por Freepik - Flaticon</a>

const consoleLogApiError = (error) => {
  if (error.response) {
    // The server responded with a status code out of the range of 2xx;
    console.log(`error.response: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received;
    // `error.request` is an XMLHttpRequest instance in the browser;
    console.log(
      `request made, no response received; error.request: ${JSON.stringify(
        error.request
      )}`
    );
    throw new Error('Network Failure');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Unknown Error', error.message);
  }
};

export const getGenderOptions = () => {
  return [
    { label: intl.get('new_option_patient_gender_female'), value: 'F' },
    { label: intl.get('new_option_patient_gender_male'), value: 'M' },
    { label: intl.get('new_option_patient_gender_others'), value: 'X' },
  ];
};

export const getGenderValue = (genderValue) => {
  if (genderValue === 'F') {
    return intl.get('new_option_patient_gender_female');
  } else if (genderValue === 'M') {
    return intl.get('new_option_patient_gender_male');
  } else if (genderValue === 'X') {
    return intl.get('new_option_patient_gender_others');
  }
};

export const getEmergencyOptions = () => {
  return [
    { label: intl.get('new_option_emergency_no_emergency'), value: 2 },
    { label: '即日', value: 4 },
    { label: '至急', value: 5 },
    // { label: intl.get('new_option_emergency_in_1_hour'), value: 5 },
    // { label: intl.get('new_option_emergency_in_3_hours'), value: 4 },
    // { label: intl.get('new_option_emergency_in_24_hours'), value: 3 },
  ];
};

export const getDstOrgOptions = (dstOrgs) => {
  return dstOrgs.map((dstOrg) => {
    if (localData.get('userPreferredLanguage') === 'en-US') {
      return {
        label: `${dstOrg.name} [${dstOrg.used}/${dstOrg.quota} used]`,
        value: dstOrg.id,
      };
    } else {
      //  if (localData.get('userPreferredLanguage') === 'ja-JP')
      return {
        // label: `${dstOrg.name} [残り ${dstOrg.quota - dstOrg.used} 件送信可能(${
        //   dstOrg.quota
        // } 件まで)]`,
        label: `${dstOrg.name}`,
        value: dstOrg.id,
      };
    }
  });
};

export const getCVICSuperUserSourceOrgs = (CvicSuperUserOrgs) => {
  return Object.values(CvicSuperUserOrgs).map((org) => ({
    label: `${org.srcOrgName}`,
    id: org.srcOrgId,
  }));
  // return CvicSuperUserOrgs.map((org) => ({label: `${org.name}`, id: org.id}));
};

export const getCVICSuperUserDestinationOrgs = (
  CvicSuperUserOrgs,
  srcOrgId
) => {
  const dstOrgs = Object.values(CvicSuperUserOrgs[srcOrgId].destinations).map(
    (org) => ({ label: `${org.name}`, id: org.id })
  );
  return dstOrgs;
};

export const getDstOrgObjectById = (orgId, dstOrgs) => {
  const res = dstOrgs.filter((org) => org.id.toString() === orgId.toString());
  if (res.length > 0) {
    return res[0];
  }
};

const makeTagStringWithSubTags = (checkboxForm, tagKey, subTagKeys) => {
  const Tags = {
    CT1: intl.get('case_tag_value_ct_1'),
    CT2: intl.get('case_tag_value_ct_2'),
    CT3: intl.get('case_tag_value_ct_3'),
    CT4: intl.get('case_tag_value_ct_4'),
    CT5: intl.get('case_tag_value_ct_5'),
    CT6: intl.get('case_tag_value_ct_6'),
    CT7: intl.get('case_tag_value_ct_7'),
    CT8: intl.get('case_tag_value_ct_8'),
    CT9: intl.get('case_tag_value_ct_9'),
    CT10: intl.get('case_tag_value_ct_10'),
    MRI1: intl.get('case_tag_value_mri_1'),
    MRI2: intl.get('case_tag_value_mri_2'),
    MRI3: intl.get('case_tag_value_mri_3'),
    MRI4: intl.get('case_tag_value_mri_4'),
    MRI5: intl.get('case_tag_value_mri_5'),
    MRI6: intl.get('case_tag_value_mri_6'),
    MRI7: intl.get('case_tag_value_mri_7'),
    MRI8: intl.get('case_tag_value_mri_8'),
    MRI9: intl.get('case_tag_value_mri_9'),
    MRI10: intl.get('case_tag_value_mri_10'),
    MRI11: intl.get('case_tag_vaule_mri_11'),
    MRI12: intl.get('case_tag_value_mri_12'),
    MRI13: intl.get('case_tag_value_mri_13'),
    MRI14: intl.get('case_tag_value_mri_14'),
    PhyExam1: intl.get('case_tag_value_phy_exam_1'),
    PhyExam2: intl.get('case_tag_value_phy_exam_2'),
    PhyExam3: intl.get('case_tag_value_phy_exam_3'),
    PhyExam4: intl.get('case_tag_value_phy_exam_4'),
    MRICor: intl.get('case_tag_value_mri_cor'),
  };
  var res = Tags[tagKey];
  const { option1, option2, option3 } = subTagKeys;
  const subTagNames = [];
  if (option1 && checkboxForm[option1]) subTagNames.push(Tags[option1]);
  if (option2 && checkboxForm[option2]) subTagNames.push(Tags[option2]);
  if (option3 && checkboxForm[option3]) subTagNames.push(Tags[option3]);
  if (subTagNames.length > 0) {
    res += `(${subTagNames.join(', ')})`;
  }
  return res;
};

export const makeTagString = (tagKey, checkboxForm) => {
  var res = '';
  if (tagKey === 'CT3') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'CT4',
      option2: 'CT5',
    });
  } else if (tagKey === 'CT4' || tagKey === 'CT5') {
    return;
  } else if (tagKey === 'CT6') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'CT7',
      option2: 'CT8',
    });
  } else if (tagKey === 'CT7' || tagKey === 'CT8') {
    return;
  } else if (tagKey === 'MRI9') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'MRI10',
      option2: 'MRI11',
    });
  } else if (tagKey === 'MRI10' || tagKey === 'MRI11') {
    return;
  } else if (tagKey === 'MRI1') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'MRI2',
      option2: 'MRI3',
      option3: 'MRI4',
    });
  } else if (tagKey === 'MRI2' || tagKey === 'MRI3' || tagKey === 'MRI4') {
    return;
  } else {
    const Tags = {
      CT1: intl.get('case_tag_value_ct_1'),
      CT2: intl.get('case_tag_value_ct_2'),
      CT3: intl.get('case_tag_value_ct_3'),
      CT4: intl.get('case_tag_value_ct_4'),
      CT5: intl.get('case_tag_value_ct_5'),
      CT6: intl.get('case_tag_value_ct_6'),
      CT7: intl.get('case_tag_value_ct_7'),
      CT8: intl.get('case_tag_value_ct_8'),
      CT9: intl.get('case_tag_value_ct_9'),
      CT10: intl.get('case_tag_value_ct_10'),
      MRI1: intl.get('case_tag_value_mri_1'),
      MRI2: intl.get('case_tag_value_mri_2'),
      MRI3: intl.get('case_tag_value_mri_3'),
      MRI4: intl.get('case_tag_value_mri_4'),
      MRI5: intl.get('case_tag_value_mri_5'),
      MRI6: intl.get('case_tag_value_mri_6'),
      MRI7: intl.get('case_tag_value_mri_7'),
      MRI8: intl.get('case_tag_value_mri_8'),
      MRI9: intl.get('case_tag_value_mri_9'),
      MRI10: intl.get('case_tag_value_mri_10'),
      MRI11: intl.get('case_tag_vaule_mri_11'),
      MRI12: intl.get('case_tag_value_mri_12'),
      MRI13: intl.get('case_tag_value_mri_13'),
      MRI14: intl.get('case_tag_value_mri_14'),
      PhyExam1: intl.get('case_tag_value_phy_exam_1'),
      PhyExam2: intl.get('case_tag_value_phy_exam_2'),
      PhyExam3: intl.get('case_tag_value_phy_exam_3'),
      PhyExam4: intl.get('case_tag_value_phy_exam_4'),
      MRICor: intl.get('case_tag_value_mri_cor'),
    };
    res = Tags[tagKey];
  }
  return res;
};

export const listTags = (checkboxForm, darkMode) => {
  const tags = {
    CT: [],
    MRI: [],
    PhyExam: [],
  };

  for (const [key, value] of Object.entries(checkboxForm)) {
    if (value) {
      const res = makeTagString(key, checkboxForm);
      if (res) {
        if (key.startsWith('CT'))
          tags.CT.push(
            <Chip
              key={res}
              label={res}
              labelcolor={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
        if (key.startsWith('MRI'))
          tags.MRI.push(
            <Chip
              key={res}
              label={res}
              labelcolor={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
        if (key.startsWith('PhyExam'))
          tags['PhyExam'].push(
            <Chip
              key={res}
              label={res}
              labelcolor={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
      }
    }
  }
  const createTagType = (tags) => {
    const tagType = [];
    for (const [key, value] of Object.entries(tags)) {
      tagType.push(
        <Grid xs={12} item key={key}>
          <span
            style={{
              fontStyle: 'italic',
              fontSize: '14px',
              letterSpacing: '0.25px',
            }}>
            {'\u2022'} {key}:{' '}
          </span>
          {value}
        </Grid>
      );
    }
    return tagType;
  };

  return (
    <Fragment>
      <Grid xs={12} item>
        <MDTypography style={{ fontWeight: 'bold' }}>
          {intl.get('new_modal_confirm_text_tags')}
        </MDTypography>
      </Grid>
      {createTagType(tags)}
    </Fragment>
  );
};

export const showSelectedTagsNew = (checkboxForm, tagListJson, darkMode) => {
  const tagList = [];
  checkboxForm.forEach((checked, index) => {
    if (checked) {
      tagList.push(
        <Chip
          key={index}
          label={tagListJson.tags[index].labelText}
          labelcolor={darkMode ? 'white' : 'black'}
          variant="outlined"
          style={{ marginRight: '4px' }}
        />
      );
    }
  });
  // return tagList;
  return (
    <Fragment>
      <Grid xs={12} item>
        <MDTypography style={{ fontWeight: 'bold' }}>
          {intl.get('new_modal_confirm_text_tags')}
        </MDTypography>
      </Grid>
      {tagList}
    </Fragment>
  );
};

export const generateConfirmWindowContents = (
  formState,
  attachmentsFromParent,
  structuredAttachments,
  checkboxForm,
  darkMode,
  selectedjson,
  tagListJson
) => {
  let fullGenderName;
  switch (formState.patientGender) {
    case 'F':
      fullGenderName = intl.get('new_option_patient_gender_female');
      break;
    case 'M':
      fullGenderName = intl.get('new_option_patient_gender_male');
      break;
    case 'X':
      fullGenderName = intl.get('new_option_patient_gender_others');
      break;
    default:
      break;
  }

  let emergrncyName;
  switch (formState.emergency) {
    case 2:
      emergrncyName = intl.get('new_option_emergency_no_emergency');
      break;
    case 5:
      emergrncyName = intl.get('new_option_emergency_in_1_hour');
      break;
    case 4:
      emergrncyName = intl.get('new_option_emergency_in_3_hours');
      break;
    case 3:
      emergrncyName = intl.get('new_option_emergency_in_24_hours');
      break;
    default:
      break;
  }

  const showSelectedTags = (selectedjson, darkMode) => {
    const tagList = [];
    selectedjson.forEach(function (item, index) {
      tagList.push(
        <Chip
          key={index}
          label={tagObj2Str(item)}
          labelcolor={darkMode ? 'white' : 'black'}
          variant="outlined"
          style={{ marginRight: '4px' }}
        />
      );
    });
    return tagList;
  };

  const tagObj2Str = (item) => {
    let rslt = '';
    for (let i = 0; i < item.length; i++) {
      if (i < 1) {
        rslt += item[i].label;
      } else {
        rslt += '-' + item[i].label;
      }
      if (item[i].value) {
        rslt += ':' + item[i].value;
      }
    }

    return rslt;
  };

  const getAttachmentsFromParentList = () =>
    attachmentsFromParent.map((item, index) => {
      return (
        <Grid container spacing={3} key={index} style={{ padding: 10 }}>
          <Grid item>
            <Icon fontSize="large" color="secondary">
              {item.structureJson.type === 'directory'
                ? 'folder'
                : 'attachment'}
            </Icon>
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={2}>
              <MDTypography variant="body2">
                {intl.get('new_label_file_name')}: {item.structureJson.name}
              </MDTypography>
              <MDTypography variant="body2">
                {intl.get('new_label_file_size')}:{' '}
                {formatBytes(item.structureJson.size)}{' '}
              </MDTypography>
            </Stack>
          </Grid>
        </Grid>
      );
    });

  const getFilesList = () =>
    structuredAttachments.map((item, index) => {
      return (
        <Grid container spacing={3} key={index} style={{ padding: 10 }}>
          <Grid item>
            <Icon fontSize="large" color="secondary">
              {item.type === 'directory' ? 'folder' : 'attachment'}
            </Icon>
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={2}>
              <MDTypography variant="body2">
                {intl.get('new_label_file_name')}: {item.name}
              </MDTypography>
              <MDTypography variant="body2">
                {intl.get('new_label_file_size')}: {formatBytes(item.size)}{' '}
              </MDTypography>
            </Stack>
          </Grid>
        </Grid>
      );
    });

  const formData = {
    new_label_send_to: formState.dstOrgName,
    new_label_emergency: emergrncyName,
    new_label_patient_name: formState.patientName,
    new_label_patient_gender: fullGenderName,
    new_label_department: formState.department,
    new_label_doctor_in_charge: formState.doctorsInCharge.join(', '),
  };

  const createFormData = (formInfo) => {
    const formValues = [];
    for (const [key, value] of Object.entries(formInfo)) {
      formValues.push(
        <Grid xs={6} item key={key}>
          <MDTypography>
            <span style={{ fontWeight: 'bold' }}>{intl.get(key)}: </span>
            <span>{value}</span>
          </MDTypography>
        </Grid>
      );
    }
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_label'}>
        <MDTypography>
          <span style={{ fontWeight: 'bold' }}>
            {intl.get('new_label_case_description')}:
          </span>
        </MDTypography>
      </Grid>
    );
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_data'}>
        <MDTypography variant="body2" mt={-2}>
          {parse(formState.description)}
        </MDTypography>
      </Grid>
    );
    return formValues;
  };

  const listSelectedTags = () => {
    const tagSystem = process.env.REACT_APP_TAG_SYSTEM;
    if (tagSystem === 'customized') {
      return showSelectedTags(selectedjson, darkMode);
    } else if (tagSystem === 'fixed') {
      return listTags(checkboxForm, darkMode);
    } else {
      return showSelectedTagsNew(checkboxForm, tagListJson, darkMode);
    }
  };

  return (
    <Grid
      spacing={3}
      container
      style={{
        marginTop: '15px',
        overflowY: 'auto',
        overflowx: 'hidden',
        maxHeight: '40vh',
      }}>
      {createFormData(formData)}
      {/* {listTags(checkboxForm, darkMode)} */}
      {/* {showSelectedTags(selectedjson, darkMode)} */}
      {listSelectedTags()}
      {getAttachmentsFromParentList()}
      {getFilesList()}
    </Grid>
  );
};

export const generateFileList = (structuredAttachments, handleFileDelete) => {
  return structuredAttachments
    .filter((file) => file.type === FILE_TYPE.FILE)
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={handleFileDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <MDTypography variant="body2">
              {item.name} - {formatBytes(item.size)}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    ));
};

export const generateDirectoryList = (
  structuredAttachments,
  handleFileDelete,
  darkMode
) => {
  return structuredAttachments
    .filter((directory) => directory.type === FILE_TYPE.DIRECTORY)
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <IconButton onClick={handleFileDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {item.type === 'file' ? (
              <MDTypography variant="body2">
                {item.name} - {formatBytes(item.size)}
              </MDTypography>
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#202940' : 'white',
                }}
                sx={{
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(item, index)}
              </TreeView>
            )}
          </Grid>
        </Grid>
      </MDBox>
    ));
};

export const generateFileListForAttachmentsFromParent = (
  attachmentsFromParent,
  handleAttachmentDelete,
  darkMode
) => {
  return attachmentsFromParent
    .filter((attachment) => attachment.structureJson.type === FILE_TYPE.FILE)
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={handleAttachmentDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <MDTypography variant="body2">
              {item.structureJson.name} - {formatBytes(item.structureJson.size)}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    ));
};

export const generateDirectoryListForAttachmentsFromParent = (
  attachmentsFromParent,
  handleAttachmentDelete,
  darkMode
) => {
  return attachmentsFromParent
    .filter(
      (attachment) => attachment.structureJson.type === FILE_TYPE.DIRECTORY
    )
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <IconButton onClick={handleAttachmentDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {item.structureJson.type === 'file' ? (
              <MDTypography variant="body2">
                {item.structureJson.name}-{formatBytes(item.structureJson.size)}
              </MDTypography>
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#202940' : 'white',
                }}
                sx={{
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(item.structureJson, index)}
              </TreeView>
            )}
          </Grid>
        </Grid>
      </MDBox>
    ));
};

const generateTreeView = (structureObject, index) => {
  let size = '';
  if (structureObject.size && structureObject.size > 0) {
    size = formatBytes(structureObject.size);
  }
  // const label = `${structureObject.name}  -  ${formatBytes(
  //   structureObject.size
  // )}`;
  const label =
    size.length > 0
      ? `${structureObject.name} - ${size}`
      : `${structureObject.name}`;
  return (
    <StyledTreeItem
      key={index}
      nodeId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}>
      {structureObject.type === FILE_TYPE.DIRECTORY &&
        structureObject.children.map((item, index) =>
          generateTreeView(item, index)
        )}
    </StyledTreeItem>
  );
};

export const initNewIssue = async (
  formState,
  checkboxForm,
  attachmentsFromParent,
  structuredAttachments,
  formjson,
  tagListJson
) => {
  // POST to backend server to create a new Issue Entity;
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );
  const formData = {};
  formData.description = formState.description;
  formData.emergency = formState.emergency;
  formData.patientGender = formState.patientGender;
  formData.patientName = formState.patientName;
  formData.department = formState.department;
  formData.doctorInCharge = formState.doctorInCharge;
  formData.doctorsInCharge = formState.doctorsInCharge;
  formData.doctorIdsFromSrcOrg = formState.doctorIdsFromSrcOrg;
  formData.srcOrgName = formState.srcOrgName;
  formData.srcOrgId = formState.srcOrgId;
  formData.dstOrgName = formState.dstOrgName;
  formData.dstOrgId = formState.dstOrgId;
  formData.numAttachments = structuredAttachments.length;
  formData.formjson = formjson;
  const checkboxFormTags = [];
  const tagsForBillingAndWage = [];
  // for (const [key, value] of Object.entries(checkboxForm)) {
  //   if (value) {
  //     checkboxFormTags.push(key);
  //   }
  // }
  checkboxForm.forEach((checked, index) => {
    const tags = tagListJson.tags;
    if (checked) {
      checkboxFormTags.push(tags[index].labelText);
      tagsForBillingAndWage.push(tags[index]);
    }
  });
  formData.checkboxFormTags = checkboxFormTags;
  formData.tagsForBillingAndWage = tagsForBillingAndWage; // Tags for calculating bills

  // For attachments from parent
  formData.attachmentFromParentIds = attachmentsFromParent.map(
    (item, index) => {
      return item.id;
    }
  );
  formData.parentIssueId = formState.parentIssueId;

  const apiVariables = {
    params: { organizationId: currentOrganizationId },
    data: formData,
  };

  let issueInfo;
  try {
    issueInfo = await api.post(`${DOLPHIN_API.ISSUES}/new`, apiVariables);
  } catch (error) {
    console.log(`POST to /api/issues/new Failed`);
    consoleLogApiError(error);
    throw error;
  }
  return issueInfo;
};

export const startIssuePeriodicStatusCheck = async (issueId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        issueId: issueId,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.ISSUES}/periodic-status-check`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/periodic-status-check failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

/**
 *  Get dateTime string in given format (Japan)
 */
const getTimeStringInFormat = (issueCreatedAt) => {
  const format = 'YYYY-MM-DD-HH-mm';
  const dateTimeString = moment(issueCreatedAt).tz('Asia/Tokyo').format(format);
  return dateTimeString;
};

const notifyServerAboutAttachment = async ({
  issueId,
  issueIdentifierId,
  issueDateTimeString,
  updatedAttachmentItem,
}) => {
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
      data: {
        issueId: issueId,
        issueIdentifierId: issueIdentifierId,
        issueDateTimeString: issueDateTimeString,
        attachmentObj: updatedAttachmentItem,
      },
    };

    // console.log(`POSTMAN: localData.get('currentOrganizationId'): ${JSON.stringify(localData.get('currentOrganizationId'))}`);
    // console.log(`POSTMAN: issueId: ${JSON.stringify(issueId)}`);
    // console.log(`POSTMAN: issueIdentifierId: ${JSON.stringify(issueIdentifierId)}`);
    // console.log(`POSTMAN: issueDateTimeString: ${JSON.stringify(issueDateTimeString)}`);
    // console.log(`POSTMAN: updatedAttachmentItem: ${JSON.stringify(updatedAttachmentItem)}`);

    const res = await api.post(
      `${DOLPHIN_API.ATTACHMENT}/notify-upload-completion`,
      apiVariables
    );
    return res;
  } catch (error) {
    console.log(`POST to /api/attachment/notify-upload-completion Failed`);
    consoleLogApiError(error);
    throw error;
  }
};

export const checkForAttachmentsCompletionOnNewIssue = async (issueId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        issueId: issueId,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.ATTACHMENT}/check-for-attachments-completion-on-new-issue`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/check-for-attachments-completion-on-new-issue failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

export const uploadBatchAttachmentsToS3 = async ({
  missionId,
  issueId,
  issueIdentifierId,
  issueCreatedAt,
  structuredAttachments,
  batchTotalBytes,
  srcOrgId,
  dstOrgId,
  controllerUploadMissions,
  dispatchUploadMissions,
}) => {
  const issueDateTimeString = getTimeStringInFormat(issueCreatedAt);

  const attachments = [];
  // For each attachment: upload and notify;
  for (var i = 0; i < structuredAttachments.length; i++) {
    const AttachmentItem = structuredAttachments[i];

    // 1. Upload attachment into S3 bucket; no need to be non-blocking / async;
    const updatedAttachmentItem = await uploadSingleAttachmentToS3({
      missionId,
      issueIdentifierId,
      issueCreatedAt,
      issueDateTimeString,
      AttachmentItem,
      batchTotalBytes,
      srcOrgId,
      dstOrgId,
      controllerUploadMissions,
      dispatchUploadMissions,
    });

    // 2. Notify backend that it is in s3, ready for processing;
    const res = await notifyServerAboutAttachment({
      issueId,
      issueIdentifierId,
      issueDateTimeString,
      updatedAttachmentItem,
    });
    attachments.push(res);
  }

  return attachments;
};

export const uploadSingleAttachmentToS3 = async ({
  missionId,
  issueIdentifierId,
  issueCreatedAt,
  issueDateTimeString,
  AttachmentItem,
  batchTotalBytes,
  srcOrgId,
  dstOrgId,
  controllerUploadMissions,
  dispatchUploadMissions,
}) => {
  // flatten the attachment object into an array of objects, each representing a single file;
  let arrayOfFileObjects = flattenStructuredAttachmentsObject(AttachmentItem);

  for (var i = 0; i < arrayOfFileObjects.length; i++) {
    const item = arrayOfFileObjects[i];
    setUploadingFileNameContext(dispatchUploadMissions, {
      missionId,
      value: item.fileFullPath,
    });
    const axiosUploadController = new AbortController();
    setUploadControllerContext(dispatchUploadMissions, {
      missionId,
      value: axiosUploadController,
    });
    const objectInfo = await uploadSingleFileToS3({
      missionId,
      issueIdentifierId,
      issueCreatedAt,
      issueDateTimeString,
      batchTotalBytes,
      srcOrgId,
      dstOrgId,
      attachmentFileObj: item,
      controllerUploadMissions,
      dispatchUploadMissions,
      axiosUploadController,
    });

    arrayOfFileObjects[i] = { ...item, ...objectInfo }; // add new info to each object
  }

  const arrayOfUpdatedStructuredAttachment =
    getStructuredFiles(arrayOfFileObjects);
  // TODO:  error-handling here: accessing an array
  const updatedStructuredAttachment = arrayOfUpdatedStructuredAttachment[0];
  return updatedStructuredAttachment;
};

/**
 *  Upload the file to S3 bucket through pre-signed URL
 */
export const uploadSingleFileToS3 = async ({
  missionId,
  issueIdentifierId,
  issueCreatedAt,
  issueDateTimeString,
  batchTotalBytes,
  srcOrgId,
  dstOrgId,
  attachmentFileObj, // an object of one single file
  controllerUploadMissions,
  dispatchUploadMissions,
  axiosUploadController,
}) => {
  const { file, fileFullPath } = attachmentFileObj;
  let bucketName;
  let objectKey;
  try {
    // use S3 Multipart Upload if file is larger than threshold;
    const multipart =
      attachmentFileObj.size > FILE_CHUNK_SIZE
        ? Math.ceil(attachmentFileObj.size / FILE_CHUNK_SIZE)
        : false;

    const apiVariables = {
      params: {
        multipart: multipart,
        organizationId: localData.get('currentOrganizationId'),
        srcOrgId: srcOrgId,
        dstOrgId: dstOrgId,
        filename: fileFullPath,
        issueIdentifierId,
        issueCreatedAt,
        issueDateTimeString,
      },
    };
    const res = await api.get(
      '/api/issues/url-for-uploading-file',
      apiVariables
    );
    const { presignedS3Url, uploadId } = res;
    bucketName = res.bucketName;
    objectKey = res.objectKey;

    if (multipart && process.env.REACT_APP_CLOUD_PROVIDER === 'aws') {
      // Multipart Upload
      const parts = await uploadParts({
        file,
        presignedS3Url,
        batchTotalBytes,
        missionId,
        controllerUploadMissions,
        dispatchUploadMissions,
        axiosUploadController,
      });

      const apiVariables = {
        params: { organizationId: localData.get('currentOrganizationId') },
        data: {
          bucketname: bucketName,
          objectname: objectKey,
          uploadId: uploadId,
          parts: parts,
        },
      };

      await api.post('/api/issues/complete-multipart-upload', apiVariables);
    } else {
      const uploadedBytesBase =
        controllerUploadMissions.missions[missionId].uploadedBytes;

      await axiosForS3PresignedUrl({
        method: 'PUT',
        url: presignedS3Url,
        data: file,
        signal: axiosUploadController.signal,
        onUploadProgress: (data) => {
          const gap = data.total - data.loaded;
          if (gap > 0 && Math.random() > 0.9) return;
          const newUploadedBytes = uploadedBytesBase + data.loaded;
          const uploadingProgress = Math.round(
            100 * (newUploadedBytes / batchTotalBytes)
          );

          setUploadedBytesContext(dispatchUploadMissions, {
            missionId,
            value: newUploadedBytes,
          });
          // setUploadingSpeedContext(dispatchUploadMissions, {missionId, value:bytesPerSecond});
          setUploadingProgressContext(dispatchUploadMissions, {
            missionId,
            value: uploadingProgress,
          });
        },
      });
    }
  } catch (error) {
    // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
    console.log('ERROR when uploading file to S3 bucket: ', error);
    consoleLogApiError(error);
    throw error;
  }

  return { Bucket: bucketName, Key: objectKey };
};

// DO NOT DELETE
// const handleSingleFileOriginal = async (f) => {
//   let item;
//   // unzip and extract dicom tag information
//   const dataSet = await extractAndParseDicom(f);

//   if (dataSet != null ){  // this is a zip file containing dicom file(s)
//     // update original formState
//     const newFormState = { ...formState };
//     newFormState.zipFileName = f.name;
//     newFormState.file = f;
//     const attributes = {
//       patientName: 'x00100010',
//       patientGender: 'x00100040',
//       patientID: 'x00100020',
//       patientBirthdate: 'x00100030',
//       accessionNumber: 'x00080050',
//       // studyID: 'x00200010',
//       // StudyUID: 'x0020000d',
//       studyID: 'x0020000d',
//       studyDate: "x00080020",
//       studyTime: "x00080030",
//       // studyDescription: 'x00081030',
//       bodyPart: 'x00180015',
//       modality: 'x00080060',
//     };

//     Object.entries(attributes).map(([key, attr]) => {
//       var element = dataSet.elements[attr];
//       var text = "";
//       if (element !== undefined) {
//         var str = dataSet.string(attr);
//         if (str !== undefined) {
//           text = str;
//         }
//       }
//       if (text !== "") newFormState[key] = text;
//     })

//     setFormState({...newFormState});
//     setGenderValue(getGenderValue(newFormState.patientGender));

//     item = {
//       type: 'zippedDicoms',
//       modality: newFormState.modality,
//       bodyPart: newFormState.bodyPart,
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   } else {
//     item = {
//       type: 'other',
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   }

//   return item
// };
