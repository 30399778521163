import intl from 'react-intl-universal';

export const analyzeRouteForBreadcrumbs = (route) => {
  let translatedRoute = [];

  if (route[0] === 'home')
    translatedRoute.push(intl.get('dashboard_button_home'));

  if (route[0] === 'personal-settings')
    translatedRoute.push(intl.get('dashboard_button_personal_settings'));

  // if (route[0] === 'new')
  //   translatedRoute.push(intl.get('dashboard_button_new'));

  // if (route[0] === 'task-management')
  //   translatedRoute.push(intl.get('dashboard_button_task_management'));

  // if (route[0] === 'history') {
  //   translatedRoute.push(intl.get('dashboard_button_received'));
  //   if (route[1] === 'received')
  //     translatedRoute.push(intl.get('dashboard_button_received_history'));
  //   if (route[1] === 'sent')
  //     translatedRoute.push(intl.get('dashboard_button_sent'));
  // }

  if (route[0] === 'new') {
    translatedRoute.push(intl.get('dashboard_button_new'));
    if (route[1] === 'new-regular')
      translatedRoute.push(intl.get('dashboard_button_new'));
    if (route[1] === 'new-kenshin')
      translatedRoute.push(intl.get('dashboard_button_new_kenshin'));
  }

  if (route[0] === 'task-management') {
    translatedRoute.push(intl.get('dashboard_button_task_management'));
    if (route[1] === 'task-management-regular')
      translatedRoute.push(intl.get('dashboard_button_task_management'));
    if (route[1] === 'task-management-kenshin')
      translatedRoute.push(
        intl.get('dashboard_button_task_management_kenshin')
      );
  }

  if (route[0] === 'history') {
    translatedRoute.push(intl.get('dashboard_button_received'));
    if (route[1] === 'received')
      translatedRoute.push(intl.get('dashboard_button_received_history'));
    if (route[1] === 'sent')
      translatedRoute.push(intl.get('dashboard_button_sent'));
    if (route[1] === 'received-kenshin')
      translatedRoute.push(
        intl.get('dashboard_button_history_received_kenshin')
      );
    if (route[1] === 'sent-kenshin')
      translatedRoute.push(intl.get('dashboard_button_history_sent_kenshin'));
  }

  if (route[0] === 'assignment') {
    translatedRoute.push(intl.get('dashboard_button_assignment'));
    if (route[1] === 'received')
      translatedRoute.push(intl.get('dashboard_button_assignment_received'));
    if (route[1] === 'sent')
      translatedRoute.push(intl.get('dashboard_button_assignment_sent'));
  }

  if (route[0] === 'research') {
    translatedRoute.push(intl.get('dashboard_button_research'));
    if (route[1] === 'new')
      translatedRoute.push(intl.get('dashboard_button_research_new'));
    if (route[1] === 'projects')
      translatedRoute.push(intl.get('dashboard_button_research_projects'));
    if (route[1] === 'cases')
      translatedRoute.push(intl.get('dashboard_button_research_cases'));
    if (route[1] === 'attachments')
      translatedRoute.push(intl.get('dashboard_button_research_attachments'));
  }

  if (route[0] === 'contacts') {
    translatedRoute.push(intl.get('dashboard_button_contacts'));
    if (route[1] === 'friends')
      translatedRoute.push(intl.get('dashboard_button_friends'));
    if (route[1] === 'groups')
      translatedRoute.push(intl.get('dashboard_button_groups'));
  }

  if (route[0] === 'billing') {
    translatedRoute.push(intl.get('dashboard_button_billing'));
    if (route[1] === 'received')
      translatedRoute.push(intl.get('dashboard_button_billing_received'));
    if (route[1] === 'sent')
      translatedRoute.push(intl.get('dashboard_button_billing_sent'));
  }

  if (route[0] === 'paid-wage') {
    translatedRoute.push(intl.get('dashboard_button_paid_wage'));
  }

  if (route[0] === 'my-wage') {
    translatedRoute.push(intl.get('dashboard_button_my_wage'));
  }

  if (route[0] === 'settings')
    translatedRoute.push(intl.get('dashboard_button_organization_settings'));

  if (route[0] === 'logs')
    translatedRoute.push(intl.get('dashboard_button_organization_logs'));

  if (route[0] === 'manual')
    translatedRoute.push(intl.get('dashboard_button_manual'));

  if (route[0] === 'service-terms')
    translatedRoute.push(intl.get('dashboard_button_service_terms'));

  return translatedRoute;
};
// export const analyzeRouteForBreadcrumbs = (route) => {
//   const translatedRoute = [];
//   route.forEach((route) => {
//     if (route in ROUTE_TITLE) {
//       translatedRoute.push(intl.get(ROUTE_TITLE[route]));
//     }
//   });
// return translatedRoute;
// };
