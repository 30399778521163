import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import api from 'shared/utils/api';
// import {
//   moveItemWithinArray,
//   insertItemIntoArray,
// } from 'shared/utils/javascript';
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import localData from 'shared/utils/localData';

import List from './List';
import { Lists } from './Styles';
import { HandleIssueDrop } from './utils';
import { ProjectProptypes } from 'shared/propTypes/projectType';
import { FilterProptypes } from 'shared/propTypes/filterType';
import { USER_COMMENT } from 'shared/constants/comment';
import { KENSHIN_API } from 'shared/constants/apis';

const MODAL_INFO = {
  MOVE_FORWARD_WITH_WARNING: 'moveForwardWithWarning',
  INVALID_DESTINATION: 'invalidDestination',
};

const propTypes = {
  project: PropTypes.shape(ProjectProptypes).isRequired,
  filters: PropTypes.shape(FilterProptypes).isRequired,
  fetchProject: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  setAlertModalOpen: PropTypes.func.isRequired,
  setAlertModalInfo: PropTypes.func.isRequired,
  setConfirmModalOpen: PropTypes.func.isRequired,
  setConfirmModalUpdateData: PropTypes.func.isRequired,
  setConfirmModalInfo: PropTypes.func.isRequired,
  updateLocalProjectWithMoreDoneIssues: PropTypes.func.isRequired,
  dummyCount: PropTypes.number,
};

const ProjectBoardLists = ({
  project,
  fetchProject,
  filters,
  updateLocalProjectIssues,
  setAlertModalOpen,
  setAlertModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
  setConfirmModalInfo,
  updateLocalProjectWithMoreDoneIssues,
  dummyCount,
}) => {
  const currentUserId = localData.get(USER_COMMENT.USER_ID);
  const match = useRouteMatch();
  const history = useHistory();

  const updateIssue = (issueId, destination, project, listPosition) => {
    api.optimisticUpdate(
      `${KENSHIN_API.ISSUES}/single/${issueId}`,
      {
        updatedFields: {
          progress: destination.droppableId,
          listPosition: listPosition,
        },
        currentFields: project.issues.find(({ id }) => id === issueId),
        setLocalData: (fields) => updateLocalProjectIssues(issueId, fields),
      },
      {
        params: {
          organizationId: localData.get('currentOrganizationId'),
          username: localData.get('username'),
          userId: currentUserId,
        },
        baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      }
    );
  };

  const goToIssueDetails = (issueId) => {
    history.push({
      pathname: `${match.url}/issues/${issueId}`,
      state: { trigger: 'byDrop' },
    });
  };

  const showInvalidDestinationAlert = () => {
    // invalid destination
    setAlertModalInfo(MODAL_INFO.INVALID_DESTINATION);
    setAlertModalOpen(true);
  };

  const handleDrop = ({ draggableId, destination, source }) => {
    HandleIssueDrop(
      draggableId,
      destination,
      source,
      updateIssue,
      project,
      goToIssueDetails,
      showInvalidDestinationAlert,
      setConfirmModalInfo,
      setConfirmModalOpen,
      setConfirmModalUpdateData
    );
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Lists>
        {Object.values(IssueStatusKenshin).map((progress, index) => (
          <List
            key={index}
            progress={progress}
            project={project}
            fetchProject={fetchProject}
            filters={filters}
            currentUserId={currentUserId}
            updateLocalProjectWithMoreDoneIssues={
              updateLocalProjectWithMoreDoneIssues
            }
            dummyCount={dummyCount}
          />
        ))}
      </Lists>
    </DragDropContext>
  );
};

ProjectBoardLists.propTypes = propTypes;

export default ProjectBoardLists;
