import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { Typography } from '@material-ui/core';
import { IssueState } from 'shared/constants/issues';

const propTypes = {
  // issue: PropTypes.shape(IssuePropTypes), // todo: check this way of typing for issues;
  selectedIssues: PropTypes.array.isRequired,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const BatchEditModalIssueState = ({
  selectedIssues,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  const firstIssue = selectedIssues[0]; // todo: this is only stop-gap solution;

  const handleBugButtonClick = (formerState) => {
    let state;
    if (formerState === IssueState.NORMAL) {
      state = IssueState.WARNING;
    } else if (formerState === IssueState.WARNING) {
      state = IssueState.NORMAL;
    }
    setConfirmModalUpdateData({ state });
    setConfirmModalInfo({ issueState: state });
    setConfirmModalOpen(true);
  };

  return (
    <Fragment>
      <FormGroup sx={{ mr: -1 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{
              color: firstIssue.state === IssueState.NORMAL ? 'black' : 'grey',
            }}>
            {intl.get('shared_value_state_normal')}
          </Typography>

          <Switch
            checked={firstIssue.state === IssueState.WARNING}
            onChange={() => handleBugButtonClick(firstIssue.state)}
          />
          <Typography
            style={{
              color: firstIssue.state === IssueState.WARNING ? 'black' : 'grey',
            }}>
            {intl.get('shared_value_state_warning')}
          </Typography>
        </Stack>
      </FormGroup>
    </Fragment>
  );
};

BatchEditModalIssueState.propTypes = propTypes;

export default BatchEditModalIssueState;
