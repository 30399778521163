import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Button } from 'components';
import api from 'shared/utils/api';
import pxToRem from 'assets/theme/functions/pxToRem';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import intl from 'react-intl-universal';
import { useLatest } from 'ahooks';
import {
  Privilege,
  PrivilegeStatus,
  SwitchLabel,
  SwitchLabelForOwner,
  SwitchLabelToPrivilege,
  SwitchLableToTranslationKey,
} from 'shared/constants/privilege';

import {
  TopActions,
  Content,
  useStyles,
  Left,
  Title,
  Actions,
  Row,
  SwitchArea,
} from './styles';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import localData from 'shared/utils/localData';

const propTypes = {
  privilegeDetails: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  updatePrivilege: PropTypes.func.isRequired,
};

const DashboardOrganizationSettingsUserManagementPrivilege = ({
  privilegeDetails,
  close,
  updatePrivilege,
}) => {
  const classes = useStyles();

  const { user, employment } = privilegeDetails;
  const isOwner = localData.get(USER_DATA.IS_OWNER); // TODO: change to xxx

  const [privilege, setPrivilege] = useState(JSON.parse(employment.privilege));
  // const [privilege, setPrivilege] = useState(employment.privilege);
  const latestPrivilege = useLatest(privilege);
  const employmentId = employment.id;
  const switchLabels = isOwner
    ? Object.keys(SwitchLabelForOwner)
    : Object.keys(SwitchLabel);
  // const switchLabels = [];

  const handleChangePrivilegeStatus = (switchLabel) => {
    const newPrivilege = latestPrivilege.current;
    const selectedPrivilegeKeys = SwitchLabelToPrivilege[switchLabel];
    newPrivilege[switchLabel] =
      latestPrivilege.current[switchLabel] === PrivilegeStatus.ON
        ? PrivilegeStatus.OFF
        : PrivilegeStatus.ON;
    selectedPrivilegeKeys.forEach((key) => {
      newPrivilege[key] = newPrivilege[switchLabel];
    });
    setPrivilege({ ...newPrivilege });
  };

  const handleUpdateClick = () => {
    updatePrivilege(employmentId, latestPrivilege.current);
  };

  return (
    <Fragment>
      <TopActions>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            fontWeight: 'bold',
            marginTop: '5px',
            marginLeft: '14px',
            textAlign: 'right',
            alignSelf: 'stretch',
          }}>
          {`${user.firstName + ' ' + user.lastName}`}
        </Typography>
      </TopActions>
      <Content>
        <Left>
          <Title>Privilege Details</Title>
          {switchLabels.map((label) => {
            return (
              <Row key={label}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{
                    fontWeight: 'bold',
                    marginTop: '5px',
                    marginLeft: '14px',
                    textAlign: 'left',
                    alignSelf: 'stretch',
                  }}>
                  {intl.get(SwitchLableToTranslationKey[label])}
                </Typography>
                <SwitchArea>
                  <Typography>OFF</Typography>
                  <Switch
                    checked={
                      privilege[label] === PrivilegeStatus.ON ? true : false
                    }
                    onChange={() => {
                      handleChangePrivilegeStatus(label);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>ON</Typography>
                </SwitchArea>
              </Row>
            );
          })}

          <Actions>
            <Button variant="primary" onClick={() => handleUpdateClick(true)}>
              Update
            </Button>
            <Button variant="empty" onClick={close}>
              Cancel
            </Button>
          </Actions>
        </Left>
      </Content>
    </Fragment>
  );
};

DashboardOrganizationSettingsUserManagementPrivilege.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsUserManagementPrivilege);
