import React, { forwardRef, Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  // IssueStatus,
  // getIssueStatus,
  getIssueStatusForClient,
  // IssueSelectableStatus_BatchEditModal,
  // IssueSelectableStatusWithWarning,
  IssueSelectableStatusForClient,
  // IssueSelectableStatusForClientWithWarning,
  // IssueState,
} from 'shared/constants/issues';
import { Select, Icon } from 'components';
import { Status } from './styles';
// import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issues: PropTypes.array.isRequired,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};
const BatchEditModalIssueStatus = forwardRef(
  ({
    issues,
    setConfirmModalInfo,
    setConfirmModalOpen,
    setConfirmModalUpdateData,
  }) => {
    const [firstIssue, setFirstIssue] = useState(issues[0]);
    useEffect(() => {
      setFirstIssue(issues[0]);
    }, [issues]);

    const handleStatusChange = (status) => {
      setConfirmModalUpdateData({ progress: status });
      setConfirmModalInfo({ destination: status });
      setConfirmModalOpen(true);
    };

    return (
      <Fragment>
        <Select
          variant="empty"
          dropdownWidth={180}
          withClearValue={false}
          name="status"
          value={firstIssue.progress}
          options={IssueSelectableStatusForClient[firstIssue.progress].map(
            (status) => ({
              value: status,
              label: getIssueStatusForClient(status),
            })
          )}
          onChange={(status) => handleStatusChange(status)}
          renderValue={({ value: status }) => (
            <Status isValue color={status}>
              <div>{getIssueStatusForClient(status)}</div>
              <Icon type="chevron-down" size={18} />
            </Status>
          )}
          renderOption={({ value: status }) => (
            <Status color={status}>{getIssueStatusForClient(status)}</Status>
          )}
        />
      </Fragment>
    );
  }
);

BatchEditModalIssueStatus.propTypes = propTypes;
BatchEditModalIssueStatus.displayName = 'SentHistoryKenshinBatchEditProgress';

export default BatchEditModalIssueStatus;
