import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  InfoLabel,
  InfoValue,
  InfoValueSmall,
  InfoContent,
  InfoContainer,
  // InfoContainerTags,
} from './styles';
// import { makeTagString } from 'pages/Dashboard/New/utils';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import {
  formatDateTimeConversational,
  readableJapaneseDateFormat,
} from 'shared/utils/dateTime';
import { getUserFullName } from 'shared/utils/name';

import Status from 'pages/Dashboard/Kanban_Kenshin/IssueDetails/Status';
import Description from 'pages/Dashboard/Kanban_Kenshin/IssueDetails/Description';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  doctors: PropTypes.array,
  isClient: PropTypes.bool,
  isTaskManager: PropTypes.bool,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
};

const ProjectBoardIssueDetailsInfo = ({
  issue,
  doctors,
  isClient,
  isTaskManager,
  setConfirmModalInfo,
  setConfirmModalUpdateData,
  setConfirmModalOpen,
}) => {
  // todo: below are tags related; Kenshin has no tags;
  // const [CTTags, setCTTags] = useState([]);
  // const [MRITags, setMRITags] = useState([]);
  // const [PhyExamTags, setPhyExamTags] = useState([]);

  // useEffect(() => {
  //   listTags(issue.tags);
  // }, [issue.tags]);

  // const listTags = (tags) => {
  //   const CTTagsArray = [];
  //   const MRITagsArray = [];
  //   const PhyExamTagsArray = [];

  //   // create the tagsObject to use the imported "makeTagString" function, in the form of {tagKeyName: true, ...};
  //   const tagsObject = {};
  //   for (var i = 0; i < tags.length; i++) {
  //     tagsObject[tags[i]] = true;
  //   }

  //   for (var j = 0; j < tags.length; j++) {
  //     const key = tags[j];
  //     const res = makeTagString(key, tagsObject);
  //     if (res) {
  //       if (key.startsWith('CT')) CTTagsArray.push(res);
  //       if (key.startsWith('MRI')) MRITagsArray.push(res);
  //       if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
  //     }
  //   }

  //   setCTTags(CTTagsArray);
  //   setMRITags(MRITagsArray);
  //   setPhyExamTags(PhyExamTagsArray);
  // };

  // const renderTagsFixed = () => {
  //   return (
  //     <InfoContainerTags>
  //       <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
  //       <InfoValue>
  //         {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
  //       </InfoValue>
  //       <InfoValue>
  //         {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
  //       </InfoValue>
  //       <InfoValue>
  //         {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
  //       </InfoValue>
  //     </InfoContainerTags>
  //   );
  // };

  // const renderTagsCustomized = () => {
  //   return <Fragment></Fragment>;
  // };

  // const renderTagsNew = () => {
  //   return (
  //     <InfoContainerTags>
  //       <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
  //       {issue.tags.map((tagLabel, index) => {
  //         return <InfoValue key={index}>{tagLabel}</InfoValue>;
  //       })}
  //     </InfoContainerTags>
  //   );
  // };

  // const renderTags = () => {
  //   const tagSystem = process.env.REACT_APP_TAG_SYSTEM;
  //   if (tagSystem === 'customized') {
  //     return renderTagsFixed();
  //   } else if (tagSystem === 'fixed') {
  //     return renderTagsCustomized();
  //   } else {
  //     return renderTagsNew();
  //   }
  // };

  // todo: save this for future;
  // const doctorIds = issue.cases.map((item) => item.doctorId).filter(Boolean);
  // const doctorsAssigned = doctors.filter((item) => doctorIds.includes(item.id));
  // const doctorNames = doctorsAssigned.map((doctor) =>
  //   getUserFullName(doctor.firstName, doctor.lastName)
  // );

  return (
    <Fragment>
      <InfoContent>
        <InfoContainer>
          {/* // todo: dacao: use intl.get() */}
          <InfoLabel>{'依頼元'}</InfoLabel>
          <InfoValue>{issue.srcOrgName}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          {/* // todo: dacao: use intl.get() */}
          <InfoLabel>{'受信时间'}</InfoLabel>
          <InfoValueSmall>
            {readableJapaneseDateFormat(issue.createdAt)}
          </InfoValueSmall>
          <InfoValueSmall>
            {formatDateTimeConversational(issue.createdAt)}
          </InfoValueSmall>
        </InfoContainer>

        <InfoContainer>
          <Description issue={issue} />
        </InfoContainer>

        {/* <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_department')}
          </InfoLabel>
          <InfoValue>{issue.department}</InfoValue>
        </InfoContainer> */}
        {/* <InfoContainerTags>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
          <InfoValue>
            {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
          </InfoValue>
        </InfoContainerTags> */}
        {/* {renderTags()} */}

        {/* // todo: save this for future;  */}
        {/* <InfoContainer>
          <InfoLabel>
            {'被分配到病例的医生'}
          </InfoLabel>
          <InfoValue>{doctorNames.join(', ')}</InfoValue>
        </InfoContainer> */}

        {isTaskManager === true && (
          <InfoContainer>
            <Status
              isClient={isClient}
              issue={issue}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
          </InfoContainer>
        )}
      </InfoContent>
    </Fragment>
  );
};

ProjectBoardIssueDetailsInfo.propTypes = propTypes;

export default ProjectBoardIssueDetailsInfo;
