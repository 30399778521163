import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Card from '@mui/material/Card';
import { PageLoader, PageError } from 'components';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { USER_DATA, getUserType } from 'shared/constants/users';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import { generateLogContents } from './utils';

import CustomSearchRender from 'components/CustomSearchRender';
import { getUserFullName } from 'shared/utils/name';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { DOLPHIN_API } from 'shared/constants/apis';

const propTypes = {
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardOrganizationLogs = ({ history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [{ data, error }] = useApi.get(DOLPHIN_API.LOGS, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
    },
  });
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const handleViewLog = (link) => {
    if (link !== '') {
      const splitedLink = link.split('/');
      let jumpLink = link;
      if (splitedLink[1] === 'sent' || splitedLink[1] === 'recevied') {
        const origin = window.location.origin;
        const issueId = splitedLink[splitedLink.length - 1];
        jumpLink = '/browse/issues/' + issueId;
      }
      history.push(jumpLink);
    }
  };

  data.sort((log1) => {
    const createdTime1 = new Date(log1.createdAt);
    const createdTime2 = new Date(log1.createdAt);
    if (createdTime1 > createdTime2) return -1;
    if (createdTime1 < createdTime2) return 1;
    return 0;
  });

  // TODO: [Privilege-Ignore] modify table to show the manage or staff
  const tableData = data.map((log) => {
    const lgoUserFullName = getUserFullName(
      log.user.firstName,
      log.user.lastName
    );
    return [
      log.createdAt,
      lgoUserFullName,
      log.user.userType,
      log.ip,
      { type: log.type, contents: log.contents },
      log.link,
    ];
  });
  const columns = [
    {
      name: 'createdAt',
      label: intl.get('logs_label_column_created_at'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toString();
        },
      },
    },
    {
      name: 'name',
      label: intl.get('logs_label_column_user_real_name'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userType',
      label: intl.get('logs_label_column_user_type'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return getUserType(value);
        },
      },
    },
    {
      name: 'ip',
      label: intl.get('logs_label_column_ip_address'),
      options: {
        filter: false,
      },
    },
    {
      name: 'content',
      label: intl.get('logs_label_column_content'),
      options: {
        sort: false,
        customBodyRender: (value) => {
          return generateLogContents(value.type, value.contents);
        },
      },
    },
    {
      name: 'view',
      label: intl.get('logs_label_column_details'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const link = value;
          const isDisabled = link === '';
          return link !== '' ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                disable={isDisabled.toString()}
                onClick={() => {
                  handleViewLog(link);
                }}>
                {intl.get('logs_button_view')}
              </Button>
            </div>
          ) : (
            <></>
          );
        },
      },
    },
  ];
  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    filterType: 'textField',
    tableBodyMaxHeight: '950px',
    rowsPerPageOptions: [10, 20, 50],
    sortOrder: {
      name: 'createdAt',
      direction: 'asc',
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('logs_text_organization_logs')}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <Card
        sx={{
          margin: 4,
          marginTop: 3,
          overflow: 'visible',
        }}>
        <ThemeProvider theme={getTablesTheme(darkMode)}>
          <MUIDataTable
            title={intl.get('logs_text_organization_logs')}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Card>
    </Fragment>
  );
};

DashboardOrganizationLogs.propTypes = propTypes;

export default withRouter(DashboardOrganizationLogs);
