import React from 'react';
// import { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const chipBaseStyle = {
  margin: '1px',
  // color: 'black',
  // border: '1px solid black',
  // fontWeight: 'bold',
  borderRadius: 5,
  backgroundColor: 'lightgreen',
};

const chipColorStyle = {
  TO_BE_ASSIGNED: {
    ...chipBaseStyle,
    backgroundColor: 'lightgrey',
  },
  TO_BE_DIAGNOSED: {
    ...chipBaseStyle,
    backgroundColor: 'orange',
  },
  BASE: {
    ...chipBaseStyle,
  },
};

const propTypes = {
  issue: PropTypes.any,
};

const CaseStatsChips = ({ issue }) => {
  const caseCountTotal = issue.cases.length;
  const count_assigned = issue.cases.filter((item) => {
    return item.doctorId || item.doctorId === 0;
  }).length;

  const caseCountToBeAssigned = caseCountTotal - count_assigned;

  const caseCountToBeDiagnosed = issue.cases.filter(
    (item) =>
      typeof item.doctorId === 'number' && item.diagnosis === 'TO_BE_DIAGNOSED'
  ).length;

  const getChipStyle = (chipName, count) => {
    if (chipName === 'TO_BE_ASSIGNED' || chipName === 'TO_BE_DIAGNOSED') {
      if (count > 0) {
        return chipColorStyle[chipName];
      }
    }
    return chipColorStyle['BASE'];
  };

  // const getLabel1 = (caseCountToBeAssigned) =>
  //   caseCountToBeAssigned === 0
  //     ? '割当完了'
  //     : `未割当: ${caseCountToBeAssigned}`;

  // const getLabel2 = (caseCountToBeDiagnosed) =>
  //   caseCountToBeDiagnosed === 0
  //     ? '診断完了'
  //     : `未診断: ${caseCountToBeDiagnosed}`;

  return (
    <Stack spacing={1} alignItems="left">
      <Stack direction="row" spacing={0}>
        {issue.progress !== 'NEWTASK' && (
          <Chip
            size="small"
            variant="filled"
            label={`未割当: ${caseCountToBeAssigned}`}
            style={getChipStyle('TO_BE_ASSIGNED', caseCountToBeAssigned)}
          />
        )}
        {issue.progress !== 'NEWTASK' && (
          <Chip
            size="small"
            variant="filled"
            label={`未診断: ${caseCountToBeDiagnosed}`}
            style={getChipStyle('TO_BE_DIAGNOSED', caseCountToBeDiagnosed)}
          />
        )}
        <Chip
          size="small"
          variant="filled"
          label={`合計: ${caseCountTotal}`}
          style={getChipStyle('TOTAL_COUNT', caseCountTotal)}
        />
      </Stack>
    </Stack>
  );
};

CaseStatsChips.propTypes = propTypes;

export default CaseStatsChips;
