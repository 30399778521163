import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Actions, StyledButton } from 'components/ConfirmModal/Styles';
import Grid from '@mui/material/Grid';
// import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

import { Avatar, Select, Icon } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { UserContextMenu } from 'components/Menu';

import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { LANGUAGE } from 'shared/constants/users';
import { User, Username, SectionTitle } from './Styles';

const propTypes = {
  doctors: PropTypes.arrayOf(Object),
  selectedIssues: PropTypes.object,
  batchUpdateIssues: PropTypes.func,
  confirmModalUpdateData: PropTypes.shape({
    status: PropTypes.string,
    state: PropTypes.string,
    userIds: PropTypes.array,
    users: PropTypes.array,
  }),
  setConfirmModalUpdateData: PropTypes.func,
  selectDoctorModalOpen: PropTypes.bool,
  setSelectDoctorModalOpen: PropTypes.func,
};

const SelectDoctorPopUpWindow = ({
  doctors,
  selectedIssues,
  batchUpdateIssues,
  confirmModalUpdateData,
  setConfirmModalUpdateData,
  selectDoctorModalOpen,
  setSelectDoctorModalOpen,
}) => {
  const [updatedFields, setUpdatedFields] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const issue = selectedIssues[0];
  const userOptions = doctors.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  const getUserById = (userId) => doctors.find((user) => user.id === userId);

  const handleAssignDoctor = (userIds) => {
    const users = userIds.map(getUserById);
    const updatedFields = {
      userIds,
      users: users,
    };
    setSelectedUserIds([...userIds]);
    setUpdatedFields(updatedFields);
  };

  const handleYesButtonClick = async () => {
    if (updatedFields.users && updatedFields.users.length > 0) {
      const finalUpdateData = { ...confirmModalUpdateData, ...updatedFields };
      await batchUpdateIssues(finalUpdateData, selectedIssues);
    } else {
      setConfirmModalUpdateData({});
    }
    setSelectDoctorModalOpen(false);
  };

  const handleNoButtonClick = () => {
    setSelectDoctorModalOpen(false);
  };

  return (
    <Fragment>
      <Modal
        disableEscapeKeyDown
        open={selectDoctorModalOpen}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '50vw',
        }}>
        <Card sx={{ maxHeight: '50%', width: '50%', margin: 'auto' }}>
          <MDBox py={3} px={3} mt={3} mx={3}>
            <Grid container justify="center" mb={3}>
              <Grid xs={12} item display="flex" justifyContent="flex-start">
                <MDTypography variant="body2">
                  {`割り当てるために、医師をお選びいただく必要がございます:`}
                </MDTypography>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              item
              container
              alignItems="center"
              justifyContent="center">
              <Stack direction="column" spacing={2}>
                <SectionTitle>
                  {intl.get('issue_details_text_doctor_in_charge')}
                </SectionTitle>
                <Select
                  isMulti
                  variant="empty"
                  dropdownWidth={240}
                  placeholder={intl.get('issue_details_unassigned')}
                  name="assignees"
                  value={selectedUserIds}
                  options={userOptions}
                  onChange={(userIds) => {
                    handleAssignDoctor(userIds);
                  }}
                  renderValue={({ value: userId, removeOptionValue }) =>
                    renderUser(getUserById(userId), true, removeOptionValue)
                  }
                  renderOption={({ value: userId }) =>
                    renderUserWithoutQuota(getUserById(userId), false)
                  }
                />
              </Stack>
            </Grid>

            <MDBox mt={3}>
              <Grid container justify="center" mb={5}>
                <Grid xs={12} item display="flex" justifyContent="flex-start">
                  <MDTypography variant="body2">
                    {`現在選択されているすべての ${selectedIssues.length} 件の依頼に変更を適用いたします。よろしいでしょうか？`}
                  </MDTypography>
                </Grid>
              </Grid>
              <Actions>
                <StyledButton variant="primary" onClick={handleYesButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_yes')}
                </StyledButton>
                <StyledButton hollow onClick={handleNoButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_no')}
                </StyledButton>
              </Actions>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </Fragment>
  );
};

const renderUser = (user, isSelectValue, removeOptionValue) => {
  const preferredLanguage = localData.get('userPreferredLanguage');
  return (
    <UserContextMenu
      username={user.username}
      userShowName={getUserFullName(user.firstName, user.lastName)}>
      <User
        key={user.id}
        isSelectValue={isSelectValue}
        withBottomMargin={!!removeOptionValue}
        onClick={() => removeOptionValue && removeOptionValue()}>
        <Avatar name={user.firstName} size={24} />
        <Username>
          {preferredLanguage === LANGUAGE.ENGLISH
            ? user.firstName + ' ' + user.lastName
            : user.lastName + ' ' + user.firstName}
        </Username>
        {removeOptionValue && <Icon type="close" top={1} />}
      </User>
    </UserContextMenu>
  );
};

const renderUserWithoutQuota = (user, isSelectValue) => {
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={false}
      onClick={() => {}}>
      <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} />
      <Username style={{}}>
        {' '}
        {getUserFullName(user.firstName, user.lastName)}{' '}
      </Username>
    </User>
  );
};

SelectDoctorPopUpWindow.propTypes = propTypes;

export default SelectDoctorPopUpWindow;
