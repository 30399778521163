import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import axios from 'axios';

import api from 'shared/utils/api';
import BodyForm from '../BodyForm';
import ProTip from './ProTip';
import { Create, UserAvatar, Right, FakeTextarea } from './Styles';
import localData from 'shared/utils/localData';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import { getUserFullName } from 'shared/utils/name';
import { USER_COMMENT } from 'shared/constants/comment';
import { IssueStatus } from 'shared/constants/issues';
import { DOLPHIN_API } from 'shared/constants/apis';

const propTypes = {
  researchProjectId: PropTypes.number.isRequired,
  researchCaseId: PropTypes.number.isRequired,
  fetchResearchCase: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  allowReport: PropTypes.bool,
  updateLocalResearchCaseDetails: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // Quill object
    current: PropTypes.object,
  }),
  isCommentFormOpen: PropTypes.bool,
  setIsCommentFormOpen: PropTypes.func,
  handleAddAttachmentButtonClick: PropTypes.func,
};

const DashboardResearchCaseDetailsCommentsCreate = ({
  researchProjectId,
  researchCaseId,
  fetchResearchCase,
  updateLocalResearchCaseDetails,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  isCommentFormOpen,
  setIsCommentFormOpen,
  handleAddAttachmentButtonClick,
}) => {
  const [isCreating, setCreating] = useState(false);

  const defaultFormState = {
    commentBody: '',
    submitType: 'global',
    file: undefined,
    fileName: '',
    progressInfos: { val: [] },
  };
  const [formState, setFormState] = useState({ ...defaultFormState });

  const username = localData.get(USER_COMMENT.USERNAME);
  const userId = localData.get(USER_COMMENT.USER_ID);
  const userType = localData.get(USER_COMMENT.USER_TYPE); // TODO: [Privilege-Clean] Clean

  const handleCommentCreate = async () => {
    try {
      setCreating(true);
      // First, get the S3 bucket PostObject pre-signed URL to upload
      const info = {
        researchCaseId: researchCaseId,
        username: username,
        userId: userId,
        userType: userType, // TODO: [Privilege-Ignore] Seems doesn't matter
        uid: `${generate8DigitsIdentifier()}`,
      };

      const formData = {
        [USER_COMMENT.INFO]: JSON.stringify(info),
        [USER_COMMENT.COMMENT_BODY]: formState.commentBody,
        [USER_COMMENT.SUBMIT_TYPE]: formState.submitType,
        [USER_COMMENT.FILENAME]: formState.fileName,
      };

      const apiVariables = {
        params: {
          organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
          researchProjectId: researchProjectId,
        },
        data: formData,
      };

      const data = await api.post(
        `${DOLPHIN_API.RESEARCH_COMMENTS}/new`,
        apiVariables
      );

      // Now we have the AWS S3 presigned url, we can upload the file directly to S3 bucket;
      if (data.PutObjectURL !== 'none') {
        await axios.put(data.PutObjectURL, formState.file);
      }

      setFormState({ ...defaultFormState });
      // if (data.message === IssueStatus.REPORTREADY) { //TODO:
      //   updateLocalProjectIssues(researchCaseId, { status: IssueStatus.REPORTREADY });
      // }

      await fetchResearchCase();
      setIsCommentFormOpen(false);
      setCreating(false);
    } catch (error) {
      console.error('handleCommentCreate: ', error);
      setIsCommentFormOpen(false);
      setCreating(false);
    }
  };

  return (
    <Create>
      {username && <UserAvatar name={getUserFullName()} />}
      <Right>
        {isCommentFormOpen ? (
          <BodyForm
            isWorking={isCreating}
            formState={formState}
            setFormState={setFormState}
            onSubmit={handleCommentCreate}
            onCancel={() => setIsCommentFormOpen(false)}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            handleAddAttachmentButtonClick={handleAddAttachmentButtonClick}
          />
        ) : (
          <Fragment>
            <FakeTextarea onClick={() => setIsCommentFormOpen(true)}>
              {intl.get('issue_details_placeholder_add_a_comment')}
            </FakeTextarea>
            <ProTip setFormOpen={setIsCommentFormOpen} />
          </Fragment>
        )}
      </Right>
    </Create>
  );
};

DashboardResearchCaseDetailsCommentsCreate.propTypes = propTypes;

export default DashboardResearchCaseDetailsCommentsCreate;
