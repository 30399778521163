import { makeStyles } from '@material-ui/core';

const appBarHeight = 64; // TODO: make it dynamic
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#212936',
    marginTop: -appBarHeight,
    // paddingTop: appBarHeight,
    // background: 'black',
    borderBottom: 1,
    // height: '100%',
    height: '98vh',
  },
  appBarSpacer: theme.mixins.toolbar,
  viewportContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
  toolAreaContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxHeight: '15vh',
    width: '100%',
  },
  caseInfoContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxHeight: '50vh',
    width: '100%',
  },
  feedbackContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxHeight: '50vh',
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    maxHeight: '100%',
    width: '100%',
  },
  paperViewport: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    maxHeight: '100vh',
    width: '100%',
  },
  reportForm: {
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  reportTextField: {
    // margin: theme.spacing(1),
  },
  submitReportButton: {
    marginTop: theme.spacing(1),
  },
  viewportToolbar: {
    backgroundColor: 'white',
    width: '100%',
  },
  viewport: {
    minWidth: '100%',
  },
}));

export default useStyles;
