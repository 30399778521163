import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import axios from 'axios';

import api from 'shared/utils/api';
import BodyForm from '../BodyForm';
import ProTip from './ProTip';
import { Create, UserAvatar, Right, FakeTextarea } from './Styles';
import localData from 'shared/utils/localData';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import { getUserFullName } from 'shared/utils/name';
import { USER_COMMENT } from 'shared/constants/comment';
import { IssueStatus } from 'shared/constants/issues';
import { DOLPHIN_API } from 'shared/constants/apis';

const propTypes = {
  issueId: PropTypes.number.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  allowReport: PropTypes.bool,
  updateLocalProjectIssues: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // Quill object
    current: PropTypes.object,
  }),
  isCommentFormOpen: PropTypes.bool,
  setIsCommentFormOpen: PropTypes.func,
  handleAddAttachmentButtonClick: PropTypes.func,
};

const ProjectBoardIssueDetailsCommentsCreate = ({
  issueId,
  fetchIssue,
  isClient,
  allowReport,
  updateLocalProjectIssues,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  isCommentFormOpen,
  setIsCommentFormOpen,
  handleAddAttachmentButtonClick,
}) => {
  const [isCreating, setCreating] = useState(false);

  const defaultFormState = {
    commentBody: '',
    submitType: 'GLOBAL',
    file: undefined,
    fileName: '',
    progressInfos: { val: [] },
  };
  const [formState, setFormState] = useState({ ...defaultFormState });

  const username = localData.get(USER_COMMENT.USERNAME);
  const userId = localData.get(USER_COMMENT.USER_ID);
  const userType = localData.get(USER_COMMENT.USER_TYPE);

  const handleCommentCreate = async () => {
    try {
      setCreating(true);
      // First, get the S3 bucket PostObject pre-signed URL to upload
      // TODO: [Privilege-Ignore] modify the backend for the userType
      const info = {
        issueId: issueId,
        username: username,
        userId: userId,
        userType: userType,
        uid: `${generate8DigitsIdentifier()}`,
      };
      const formData = {
        [USER_COMMENT.INFO]: JSON.stringify(info),
        [USER_COMMENT.COMMENT_BODY]: formState.commentBody,
        [USER_COMMENT.SUBMIT_TYPE]: formState.submitType,
        [USER_COMMENT.FILENAME]: formState.fileName,
      };

      const apiVariables = {
        params: {
          organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
        },
        data: formData,
      };

      const data = await api.post(`${DOLPHIN_API.COMMENTS}/new`, apiVariables);

      // Now we have the AWS S3 presigned url, we can upload the file directly to S3 bucket;
      if (data.PutObjectURL !== 'none') {
        await axios.put(data.PutObjectURL, formState.file);
      }

      setFormState({ ...defaultFormState });
      if (data.message === IssueStatus.REPORTREADY) {
        updateLocalProjectIssues(issueId, { status: IssueStatus.REPORTREADY });
      }

      await fetchIssue();
      setIsCommentFormOpen(false);
      setCreating(false);
    } catch (error) {
      console.error('handleCommentCreate: ', error);
      setIsCommentFormOpen(false);
      setCreating(false);
    }
  };

  return (
    <Create>
      {username && <UserAvatar name={getUserFullName()} />}
      <Right>
        {isCommentFormOpen ? (
          <BodyForm
            isClient={isClient}
            allowReport={allowReport}
            isWorking={isCreating}
            formState={formState}
            setFormState={setFormState}
            onSubmit={handleCommentCreate}
            onCancel={() => {
              setFormState(defaultFormState);
              setIsCommentFormOpen(false);
            }}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            handleAddAttachmentButtonClick={handleAddAttachmentButtonClick}
          />
        ) : (
          <Fragment>
            <FakeTextarea onClick={() => setIsCommentFormOpen(true)}>
              {intl.get('issue_details_placeholder_add_a_comment')}
            </FakeTextarea>
            <ProTip setFormOpen={setIsCommentFormOpen} />
          </Fragment>
        )}
      </Right>
    </Create>
  );
};

ProjectBoardIssueDetailsCommentsCreate.propTypes = propTypes;

export default ProjectBoardIssueDetailsCommentsCreate;
