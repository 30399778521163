import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KrillChat from 'krill';
import { useLatest } from 'ahooks';

import localData from 'shared/utils/localData';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIssueShareConfirmModalOpen: PropTypes.func.isRequired,
  shareIssueConfirmModalBodyText: PropTypes.any,
  shareIssueConfirmFunc: PropTypes.any,
  shareIssueCancelFunc: PropTypes.any,
};

const ShareIssueButtonMenu = ({
  anchorEl,
  open,
  onClose,
  setIssueShareConfirmModalOpen,
  shareIssueConfirmModalBodyText,
  shareIssueConfirmFunc,
  shareIssueCancelFunc,
}) => {
  const origin = window.location.origin;
  const path = window.location.pathname;
  const pathList = path.split('/');
  const issueId = pathList[pathList.length - 1];
  let shareLink = origin + '/browse/issues/' + issueId;
  if (pathList[1] === 'research') {
    shareLink = window.location.href;
  }
  const context = KrillChat.Context.useKrillChatContext();
  const { conversations } = context;
  const conversationList = conversations.map((conversation) => {
    return {
      id: conversation.conversationID,
      userID: conversation.userID,
      groupID: conversation.groupID,
      showName: conversation.showName,
    };
  });
  const [dstConversation, setDstConversation] = useState(null);
  const latestDstConversation = useLatest(dstConversation);

  useEffect(() => {
    shareIssueConfirmFunc.current = handleConfirm;
    shareIssueCancelFunc.current = handleCancel;
  }, []);

  const handleClickShareLink = async (conversation, link) => {
    shareIssueConfirmModalBodyText.current = conversation.showName;
    setIssueShareConfirmModalOpen(true);
    setDstConversation(conversation);
    // TODO: push a message to snackbar
  };

  const handleConfirm = async () => {
    const conversation = latestDstConversation.current;
    const link = shareLink;
    const res = await KrillChat.API.sendMessage(
      link,
      'text',
      conversation.userID,
      conversation.groupID
    );
    setIssueShareConfirmModalOpen(false);
    onClose();
    // TODO: push a message to snackbar
  };

  const handleCancel = async () => {
    setIssueShareConfirmModalOpen(false);
    onClose();
  };

  return (
    <Menu
      id="share-issue-button-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}>
      {conversationList.map((conversation, index) => {
        return (
          <MenuItem
            key={index}
            onClick={() => {
              handleClickShareLink(conversation, shareLink);
            }}>
            {conversation.showName}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

ShareIssueButtonMenu.propTypes = propTypes;

export default ShareIssueButtonMenu;
