import { useState, Fragment, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
// import Box from '@mui/material/Box';
// import ConstructionIcon from '@mui/icons-material/Construction';
import CloseIcon from '@mui/icons-material/Close';

// self defined
import api from 'shared/utils/api';
// import pxToRem from 'assets/theme/functions/pxToRem';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import { useMaterialUIController } from 'context';
import {
  // isCurrentUserOrganizationManager,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import localData from 'shared/utils/localData';
import { USER_COMMENT } from 'shared/constants/comment';
import { KENSHIN_API } from 'shared/constants/apis';
import { useStyles } from './styles';
import { getSelectedIssuesListTitle, getSelectedIssuesList } from './utils';
import ToggleStatus from './Status';

const propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  issues: PropTypes.array.isRequired,
  selectedIssueIndexes: PropTypes.array.isRequired,
  doctors: PropTypes.array.isRequired,
  updateLocalProjectIssues: PropTypes.func,
  handleOpenIssueDetails: PropTypes.func,
  setBatchEditModalIsOpen: PropTypes.func,
};

const BatchEditModal = ({
  isOpened,
  closeModal,
  issues,
  selectedIssueIndexes,
  // selectedIssues,
  doctors,
  updateLocalProjectIssues,
  handleOpenIssueDetails,
  setBatchEditModalIsOpen,
}) => {
  const classes = useStyles();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });

  const [selectedIssues, setSelectedIssues] = useState([
    ...selectedIssueIndexes.map((index) => issues[index]),
  ]);
  useEffect(() => {
    const tmp = selectedIssueIndexes.map((index) => issues[index]);
    setSelectedIssues([...tmp]);
  }, [issues]);

  const [selectDoctorModalOpen, setSelectDoctorModalOpen] = useState(false);

  const batchUpdateIssues = (updatedFields, issues) => {
    api.optimisticBatchUpdateIssues(
      `${KENSHIN_API.ISSUES_BATCH_UPDATE}`,
      {
        issues,
        updatedFields,
        setLocalData: (issueId, fields) => {
          // this fields is either `updatedFields` or `currentFields` above;
          // this is the local action that is being executed
          updateLocalProjectIssues(issueId, fields);
        },
      },
      {
        params: {
          organizationId: localData.get('currentOrganizationId'),
          username: localData.get('username'),
          userId: localData.get(USER_COMMENT.USER_ID),
        },
        baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      }
    );
  };

  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);
  const isTaskManager = isCurrentUserTaskManager();

  const handleConfirmModalYesButtonClick = async () => {
    await batchUpdateIssues(confirmModalUpdateData, selectedIssues);
    setConfirmModalOpen(false);
  };

  const handleConfirmModalNoButtonClick = () => {
    setConfirmModalOpen(false);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  return (
    <Fragment>
      <Modal
        disableEscapeKeyDown
        open={isOpened}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '50vw',
          zIndex: '2', // 'auto'
        }}>
        <Card
          sx={{
            minHeight: '30%',
            maxHeight: '75%',
            width: '60%',
            margin: 'auto',
          }}>
          <Grid container justify="flex-end">
            <Grid xs={12} item display="flex" justifyContent="flex-end">
              <IconButton
                aria-label="delete"
                size="small"
                variant="empty"
                onClick={closeModal}
                sx={{ paddingRight: 1, paddingTop: 1 }}
                // className={classes.closeIconButton}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>

          <MDBox mt={1} mb={2} ml={4}>
            <MDTypography id="modal-title" variant="h5" component="h2">
              {'すべての選択された依頼を一括編集: '}
            </MDTypography>
          </MDBox>

          <Grid container spacing={2}>
            {/* batch edit tools */}

            <Grid item xs={1}></Grid>

            <Grid item xs={2}>
              <MDTypography variant="body2" style={{ marginTop: '1px' }}>
                {/* {intl.get('new_label_file_name')} */}
                {/* {'編集するにはクリックしてください: '} */}
              </MDTypography>
            </Grid>

            {/* batch change status */}
            <Grid item xs={2}>
              {isTaskManager === true && (
                <ToggleStatus
                  issues={selectedIssues}
                  setConfirmModalInfo={setConfirmModalInfo}
                  setConfirmModalUpdateData={setConfirmModalUpdateData}
                  setConfirmModalOpen={setConfirmModalOpen}
                  setSelectDoctorModalOpen={setSelectDoctorModalOpen}
                />
              )}
            </Grid>
            {/* empty */}
            <Grid item xs={2}></Grid>
          </Grid>

          <MDBox my={1} ml={4}>
            <MDTypography id="modal-title" variant="h5" component="h2">
              {`現在選択されている依頼:  ${selectedIssues.length}  件`}
            </MDTypography>
          </MDBox>

          {getSelectedIssuesListTitle()}

          {/* Horizontal Divider */}
          <div className={classes.horizontalDivider}></div>

          <Card
            sx={{
              boxShadow: 'none',
              // maxHeight: '90%',
              // width: '100%',
              // margin: 'auto',
              margin: '2px 2px 20px 2px',
              overflowY: 'scroll',
            }}>
            {getSelectedIssuesList(
              doctors,
              selectedIssues,
              handleOpenIssueDetails
            )}
          </Card>
        </Card>
      </Modal>

      {confirmModalOpen && (
        <Modal
          disableEscapeKeyDown
          open={confirmModalOpen}
          // onClose={closeConfirmModal}
          aria-labelledby="batch-edit-confirm-modal-title" // todo: ask: what is its purpose?
          aria-describedby="batch-edit-confirm-modal-description" // todo: ask: what is its purpose?
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50vw',
          }}>
          <Card sx={{ maxHeight: '50%', width: '50%', margin: 'auto' }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <Grid container justify="center" mb={5}>
                <Grid xs={12} item display="flex" justifyContent="flex-start">
                  <MDTypography variant="body1">
                    {`現在選択されているすべての ${selectedIssues.length} 件の依頼に変更を適用いたします。よろしいでしょうか？`}
                  </MDTypography>
                </Grid>
              </Grid>
              <Actions>
                <StyledButton
                  variant="primary"
                  // isWorking={isWorking} // todo: should get this working
                  onClick={handleConfirmModalYesButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_yes')}
                </StyledButton>
                <StyledButton hollow onClick={handleConfirmModalNoButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_no')}
                </StyledButton>
              </Actions>
            </MDBox>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};

BatchEditModal.propTypes = propTypes;

export default BatchEditModal;
